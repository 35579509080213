// outsource dependencies
import React, {useEffect, useMemo, useState} from 'react';
// UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export interface ICustomAutocompleteOption {
    key: string | number
    name: string
}

interface ICustomAutocompleteProps {
    input?: any
    options: ICustomAutocompleteOption[]
    onFocus?: () => void
    onChange?: (value: ICustomAutocompleteOption | null) => void
    label: string
    helperText?: string
    error?: boolean
    placeholder: string
}

export default function CustomAutocomplete({
                                               input,
                                               options,
                                               onFocus,
                                               onChange,
                                               label,
                                               helperText,
                                               error
                                           }: ICustomAutocompleteProps) {
    const reduxProps = input || {
        options: [],
        onFocus: () => {
        },
        onChange: (value: ICustomAutocompleteOption | null) => {
            return value
        },
    };
    const handleOptionLabel = (option: ICustomAutocompleteOption) => option.name || '';

    const [localValue, setLocalValue] = useState<ICustomAutocompleteOption | null>(null);

    const currentOption = useMemo(() => {
        const value = options?.find((option: ICustomAutocompleteOption) => String(option.key) === String(input?.value));
        return JSON.stringify(value || '')
    }, [options, input]);

    useEffect(() => {
        const value = JSON.parse(currentOption)
        setLocalValue(value || null)
    }, [currentOption]);

    return (
        <Autocomplete
            options={options || reduxProps.options}
            onFocus={onFocus || reduxProps.onFocus}
            onChange={(event: any, newValue: ICustomAutocompleteOption | null) => {
                setLocalValue(newValue);
                (onChange && onChange(newValue)) || (reduxProps.onChange && reduxProps.onChange(newValue));
            }}
            getOptionLabel={handleOptionLabel}
            getOptionSelected={(option, value) => option.key === value.key}
            renderInput={(params) => <TextField
                {...params}
                label={label}
                helperText={helperText}
                error={error || reduxProps.error}/>}
            value={localValue}
        />
    );
}
