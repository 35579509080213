// outsource dependencies
import React, { useEffect } from 'react';
// UI
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
// local dependencies
import {
  // constants
  DefaultTableOptions,
  // interface
  IFwSoftGroup,
  IFwEcu,
} from 'private-layout/fw-soft-group/reducer';
// UI
import CustomTextField from 'components/input/TextField';
import CustomAutocomplete from 'components/input/Autocomplete';
import Preloader from 'components/Preloader';
import FwSoftGroupsPaginationActions from 'private-layout/fw-soft-group/components/FwSoftGroupsPaginationActions';
// utils
import { useDebounce } from 'utils/hook.utils';
// constants
import { APP_COLOR } from 'constants/style';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  row: {
    maxWidth: 250,
  },
  editButton: {
    marginRight: 8,
  },
  softConfig: {
    padding: 15,
    maxWidth: 350,
    maxHeight: 100,
    margin: '8px 0 8px 1em',
    overflowY: 'auto',
  },
  inputs: {
    display: 'flex',
  },
  inputContainer: {
    padding: 16,
    minWidth: 200,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addIcon: {
    color: APP_COLOR.GREEN,
  },
});
interface IProps {
  totalSoftGroupCount: number;
  fwSoftGroupList?: IFwSoftGroup[] | null;
  fwEcuList?: IFwEcu[] | null;
  //
  expectFwSoftGroupAnswer?: boolean;
  //
  page: number;
  filesPerPage: number;
  filterByFwEcu?: number | null;
  fwSearchVal?: string; // fw_key or config

  // action
  updatePage?: (page: number) => void;
  updateFilesPerPage?: (filesPerPage: number) => void;
  updateFilterByFwEcu?: (filterByFwEcu: number | null) => void;
  updateFwSearchVal?: (fwSearchVal: string) => void;

  updateFwSoftGroupIdEdit?: (fwSoftGroupIdEdit: number | null) => void;
  updateFwSoftGroupIdDelete?: (fwSoftGroupIdDelete: number | null) => void;
  updateFwSoftGroupEdit?: ({
    editedFwEcu,
    editedFwKey,
    editedConfig,
    editedApproved,
  }: {
    editedFwEcu?: number | null;
    editedFwKey?: string;
    editedConfig?: string;
    editedApproved?: boolean;
  }) => void;
  // trigger
  loadSoftGroup?: () => void;
  //
  handleOpenDeleteDialog: () => void;
  handleOpenEditDialog: () => void;
  handleOpenCreateDialog: () => void;
}

export default function FilesTable(props: IProps) {
  const {
    totalSoftGroupCount,
    fwSoftGroupList,
    fwEcuList,
    //
    page,
    filesPerPage,
    filterByFwEcu,
    fwSearchVal,
    //
    expectFwSoftGroupAnswer,
    //
    updateFilesPerPage,
    updatePage,
    updateFilterByFwEcu,
    updateFwSearchVal,
    //
    updateFwSoftGroupIdDelete,
    updateFwSoftGroupIdEdit,
    updateFwSoftGroupEdit,
    //
    loadSoftGroup,
    //
    handleOpenDeleteDialog,
    handleOpenEditDialog,
    handleOpenCreateDialog,
  } = props;
  const classes = useStyles();
  const debouncedFwSearchVal = useDebounce(fwSearchVal, 300);

  useEffect(() => {
    loadSoftGroup && loadSoftGroup();
  }, [debouncedFwSearchVal, loadSoftGroup]);

  const getFwEcuNameById = (fw_ecu_: number) => fwEcuList?.find((fwEcu: IFwEcu) => Number(fwEcu.key) === fw_ecu_)?.name;

  const handleChangePage = (e: unknown, newPage: number) => {
    window.scrollTo(0, 0);
    updatePage && updatePage(newPage);
    loadSoftGroup && loadSoftGroup();
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    window.scrollTo(0, 0);
    updateFilesPerPage && updateFilesPerPage(Number(event.target.value));
    updatePage && updatePage(DefaultTableOptions.DEFAULT_PAGE);
    loadSoftGroup && loadSoftGroup();
  };


  const handleSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFwSearchVal && updateFwSearchVal(event.target.value);
  };
  const handleEdit = (fwSoftGroupItem: IFwSoftGroup) => () => {
    updateFwSoftGroupIdEdit && updateFwSoftGroupIdEdit(fwSoftGroupItem.fw_soft_group_);
    updateFwSoftGroupEdit &&
      updateFwSoftGroupEdit({
        editedFwEcu: fwSoftGroupItem.fw_ecu_,
        editedFwKey: fwSoftGroupItem.fw_key,
        editedConfig: fwSoftGroupItem.config,
        editedApproved: fwSoftGroupItem.approved,
      });
    handleOpenEditDialog();
  };
  const handleDelete = (fw_soft_: number) => () => {
    updateFwSoftGroupIdDelete && updateFwSoftGroupIdDelete(fw_soft_);
    handleOpenDeleteDialog();
  };

  const handleEcus = (e: any) => {
    const flattenValue = e ? e.key : null;
    updateFilterByFwEcu && updateFilterByFwEcu(Number(flattenValue) || null);
    loadSoftGroup && loadSoftGroup();
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <div className={classes.inputs}>
          <div className={classes.inputContainer}>
            {Array.isArray(fwEcuList) && <CustomAutocomplete options={fwEcuList} onChange={handleEcus} label='FW ECU' input={{ value: filterByFwEcu }} placeholder='FW ECU' />}
          </div>
          <div className={classes.inputContainer}>
            <CustomTextField onChange={handleSearchValue} label='Value' placeholder='fw_key, config' value={fwSearchVal} />
          </div>
        </div>
      </div>
      <TableContainer>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>FW ECU</TableCell>
              <TableCell>FW KEY</TableCell>
              <TableCell>Config</TableCell>
              <TableCell>Approved</TableCell>
              <TableCell>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!expectFwSoftGroupAnswer &&
              Array.isArray(fwSoftGroupList) &&
              fwSoftGroupList.map((fwSoftGroupItem: IFwSoftGroup, index) => (
                <TableRow hover key={index}>
                  <TableCell className={classes.row}>{fwSoftGroupItem.fw_soft_group_}</TableCell>
                  <TableCell className={classes.row}>{getFwEcuNameById(fwSoftGroupItem.fw_ecu_)}</TableCell>
                  <TableCell className={classes.row}>{fwSoftGroupItem.fw_key}</TableCell>
                  <TableCell className={classes.row}>
                    <div className={classes.softConfig}>{fwSoftGroupItem.config}</div>
                  </TableCell>
                  <TableCell className={classes.row}>{String(fwSoftGroupItem.approved)}</TableCell>
                  <TableCell className={classes.row}>
                    <IconButton color='primary' onClick={handleEdit(fwSoftGroupItem)} className={classes.editButton}>
                      <EditIcon fontSize='large' />
                    </IconButton>
                    <IconButton color='secondary' onClick={handleDelete(fwSoftGroupItem.fw_soft_group_)}>
                      <DeleteIcon fontSize='large' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {!expectFwSoftGroupAnswer && (
              <TableRow hover>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell className={classes.row}>
                  <IconButton onClick={handleOpenCreateDialog} className={classes.addIcon}>
                    <AddCircleIcon fontSize='large' />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {expectFwSoftGroupAnswer && <Preloader centered />}
      <TablePagination
        rowsPerPageOptions={DefaultTableOptions.FILES_COUNT_OPTIONS}
        component='div'
        count={totalSoftGroupCount}
        rowsPerPage={filesPerPage}
        labelRowsPerPage={<strong>Файлов на странице: </strong>}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={FwSoftGroupsPaginationActions}
      />
    </Paper>
  );
}
