// outsource dependencies
import React from 'react';
// UI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface IProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  label?: string;
  disabled?: boolean;
  style?: any;
  defaultChecked?: boolean
}

function CustomCheckbox(props: IProps) {
  const { onChange, checked, label, disabled, style, defaultChecked } = props;
  return <FormControlLabel control={<Checkbox defaultChecked={defaultChecked} style={style} checked={checked} onChange={onChange} color='primary' />} label={label} disabled={Boolean(disabled)} />;
}

export default CustomCheckbox;
