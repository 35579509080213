// outsource dependencies
import React, { useEffect } from 'react';
// UI
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
// local dependencies
import {
  // constants
  DefaultTableOptions,
  // interface
  IFwSoft,
  IFwEcu,
} from 'private-layout/fw-soft/reducer';
// UI
import CustomTextField from 'components/input/TextField';
import Preloader from 'components/Preloader';
import FwSoftsPaginationActions from 'private-layout/fw-soft/components/FwSoftsPaginationActions';
// utils
import { useDebounce } from 'utils/hook.utils';
// constants
import { APP_COLOR } from 'constants/style';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  row: {
    maxWidth: 250,
  },
  editButton: {
    marginRight: 8,
  },
  softConfig: {
    padding: 15,
    maxWidth: 350,
    maxHeight: 100,
    margin: '8px 0 8px 1em',
    overflowY: 'auto',
  },
  inputs: {
    display: 'flex',
  },
  inputContainer: {
    padding: 16,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addIcon: {
    color: APP_COLOR.GREEN,
  },
});
interface IProps {
  totalSoftCount: number;
  fwSoftList?: IFwSoft[] | null;
  fwEcuList?: IFwEcu[] | null;
  //
  expectFwSoftAnswer?: boolean;
  //
  page: number;
  filesPerPage: number;
  fwSearchGroup?: string;
  fwSearchVal?: string; // soft_full, soft_idx and config

  allListLoaded?: boolean;
  // action
  updatePage?: (page: number) => void;
  updateFilesPerPage?: (filesPerPage: number) => void;
  updateFwSearchGroup?: (fwSearchGroup: string) => void;
  updateFwSearchVal?: (fwSearchVal: string) => void;
  updateFwSoftIdDelete?: (fwSoftIdDelete: number | null) => void;
  updateFwSoftIdEdit?: (fwSoftIdEdit: number | null) => void;
  updateFwSoftEdit?: ({
    editedFwSoftEcuId,
    editedSoftFull,
    editedSoftIdx,
    editedFwSoftGroupId,
    editedConfig,
  }: {
    editedFwSoftEcuId: string | number;
    editedSoftFull: string | number;
    editedSoftIdx: string | number;
    editedFwSoftGroupId: string | number;
    editedConfig: string | number | null;
  }) => void;
  // trigger
  loadSoft?: () => void;
  loadAllSoft?: () => void;
  //
  handleOpenDeleteDialog: () => void;
  handleOpenEditDialog: () => void;
  handleOpenCreateDialog: () => void;
}

export default function FilesTable(props: IProps) {
  const {
    totalSoftCount,
    fwSoftList,
    fwEcuList,
    //
    page,
    filesPerPage,
    fwSearchGroup,
    fwSearchVal,
    allListLoaded,
    //
    expectFwSoftAnswer,
    //
    updateFilesPerPage,
    updatePage,
    updateFwSearchGroup,
    updateFwSearchVal,
    updateFwSoftIdDelete,
    updateFwSoftIdEdit,
    updateFwSoftEdit,
    //
    loadSoft,
    loadAllSoft,
    //
    handleOpenDeleteDialog,
    handleOpenEditDialog,
    handleOpenCreateDialog,
  } = props;
  const classes = useStyles();
  const debouncedFwSearchGroup = useDebounce(fwSearchGroup, 300);
  const debouncedFwSearchVal = useDebounce(fwSearchVal, 300);

  useEffect(() => {
    loadSoft && loadSoft();
  }, [debouncedFwSearchGroup, debouncedFwSearchVal, loadSoft]);

  const getFwEcuNameById = (fw_ecu_: number) => fwEcuList?.find((fwEcu: IFwEcu) => Number(fwEcu.key) === fw_ecu_)?.name;

  const handleChangePage = (e: unknown, newPage: number) => {
    window.scrollTo(0, 0);
    updatePage && updatePage(newPage);
    loadSoft && loadSoft();
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    window.scrollTo(0, 0);
    updateFilesPerPage && updateFilesPerPage(Number(event.target.value));
    updatePage && updatePage(DefaultTableOptions.DEFAULT_PAGE);
    loadSoft && loadSoft();
  };

  const handleLoadAllSoft = () => {
    loadAllSoft && loadAllSoft();
  };
  const handleLoadPartSoft = () => {
    loadSoft && loadSoft();
  };
  const handleFwSearchGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFwSearchGroup && updateFwSearchGroup(event.target.value);
  };
  const handleSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFwSearchVal && updateFwSearchVal(event.target.value);
  };
  const handleEdit = (softItem: IFwSoft) => () => {
    updateFwSoftIdEdit && updateFwSoftIdEdit(softItem.fw_soft_);
    updateFwSoftEdit &&
      updateFwSoftEdit({
        editedFwSoftEcuId: softItem.fw_ecu_,
        editedSoftFull: softItem.soft_full,
        editedSoftIdx: softItem.soft_idx,
        editedFwSoftGroupId: softItem.fw_soft_group_,
        editedConfig: softItem.config,
      });
    handleOpenEditDialog();
  };
  const handleDelete = (fw_soft_: number) => () => {
    updateFwSoftIdDelete && updateFwSoftIdDelete(fw_soft_);
    handleOpenDeleteDialog();
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <div className={classes.inputs}>
          <div className={classes.inputContainer}>
            <CustomTextField onChange={handleFwSearchGroup} label='Soft group' placeholder='Soft group' value={fwSearchGroup} />
          </div>
          <div className={classes.inputContainer}>
            <CustomTextField onChange={handleSearchValue} label='Value' placeholder='soft_full, soft_idx, config' value={fwSearchVal} />
          </div>
        </div>
        <div>
          {!allListLoaded && !expectFwSoftAnswer && (
            <Button variant='contained' onClick={handleLoadAllSoft}>
              Загрузить весь список
            </Button>
          )}
          {allListLoaded && !expectFwSoftAnswer && (
            <Button variant='contained' onClick={handleLoadPartSoft}>
              Загрузить часть списка
            </Button>
          )}
        </div>
      </div>
      <TableContainer>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>FW ECU</TableCell>
              <TableCell>FW soft group</TableCell>
              <TableCell>Soft full</TableCell>
              <TableCell>Soft idx</TableCell>
              <TableCell>FW config</TableCell>
              <TableCell>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!expectFwSoftAnswer &&
              Array.isArray(fwSoftList) &&
              fwSoftList.map((fwSoftItem: IFwSoft, index) => (
                <TableRow hover key={index}>
                  <TableCell className={classes.row}>{fwSoftItem.fw_soft_}</TableCell>
                  <TableCell className={classes.row}>{getFwEcuNameById(fwSoftItem.fw_ecu_)}</TableCell>
                  <TableCell className={classes.row}>{fwSoftItem.fw_key}</TableCell>
                  <TableCell className={classes.row}>{fwSoftItem.soft_full}</TableCell>
                  <TableCell className={classes.row}>{fwSoftItem.soft_idx}</TableCell>
                  <TableCell className={classes.row}>
                    <div className={classes.softConfig}>{fwSoftItem.config}</div>
                  </TableCell>
                  <TableCell className={classes.row}>
                    <IconButton color='primary' onClick={handleEdit(fwSoftItem)}>
                      <EditIcon fontSize='large' />
                    </IconButton>
                    <IconButton color='secondary' onClick={handleDelete(fwSoftItem.fw_soft_)}>
                      <DeleteIcon fontSize='large' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {!expectFwSoftAnswer && (
              <TableRow hover>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell className={classes.row}>
                  <IconButton onClick={handleOpenCreateDialog} className={classes.addIcon}>
                    <AddCircleIcon fontSize='large' />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {expectFwSoftAnswer && <Preloader centered />}
      {!allListLoaded && (
        <TablePagination
          rowsPerPageOptions={DefaultTableOptions.FILES_COUNT_OPTIONS}
          component='div'
          count={totalSoftCount}
          rowsPerPage={filesPerPage}
          labelRowsPerPage={<strong>Файлов на странице: </strong>}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={FwSoftsPaginationActions}
        />
      )}
    </Paper>
  );
}
