import React, {useEffect, useState} from 'react';
import Preloader from "../../components/Preloader";
import Layout from "../../components/layout/Common";
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import currentConfig from "../../utils/config.utils";
import StEcuTable from "./components/StEcuTable";
import { IStEcu } from './types';
import StEcuHeader from "./components/StEcuHeader";
import {ToastContainer} from "react-toastify";

const useStyles = makeStyles({
    container: {
        minHeight: '80vh',
    },
    title: {
        padding: '16px 0 0 16px'
    }
});

export interface IResponse {
    list: IStEcu[],
    count: number
}

function StEcu() {
    const classes = useStyles();
    const [expectAnswer, setExpectAnswer] = useState<boolean>(false);
    const [ecus, setEcus] = useState<IResponse>({
        list: [],
        count: 0
    });

    const changeTableContent = () => {
        setExpectAnswer(true);
        const config = currentConfig();
        const initUrl = new URL(window.location.href);
        const count = initUrl.searchParams.get("count") || '20'
        const search = initUrl.searchParams.get("search") || ''

        fetch(config.apiUrl + 'st-ecu/list', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                count: count,
                search: search,
                page: initUrl.searchParams.get("page") || '1'
            })
        })
            .then(response => response.json())
            .then(data => {
                setEcus(data.response);
                setExpectAnswer(false);
            })
            .catch(err => {
                console.log('error:', err)
            })
    }

    useEffect(() => {
        changeTableContent();
    }, []);


    return (
        <>
            <Layout noHeightConstraint>
                <Card variant='outlined' className={classes.container}>
                    <Typography className={classes.title} variant='h4'>
                        ST ECU
                    </Typography>
                    <StEcuHeader changeTableContent={changeTableContent}/>
                    <CardContent>
                        {expectAnswer && <Preloader pageCentered/>}
                        {!expectAnswer && <StEcuTable changeTableContent={changeTableContent} ecus={ecus}/>}
                    </CardContent>
                </Card>
            </Layout>
            <ToastContainer />
        </>);

}

export default StEcu;