import React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import Layout from 'components/layout/Common';

const useStyles = makeStyles({
    header: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    }
});

const PermissionDenied = () => {
    const classes = useStyles();
    return (
        <Layout noHeightConstraint>
            <Card variant='outlined'>
                <Typography className={classes.header}
                    variant="h6">Не достаточно прав для использовании страници</Typography>
            </Card>
        </Layout>
    )
}

export default PermissionDenied;
