import React, {useEffect, useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import {
    Checkbox,
    Input,
    TableCell,
    TextField
} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import currentConfig from "../../../utils/config.utils";
import {IAction} from "./StActionTable";

interface IProps {
    action: IAction;
    changeTableContent: () => void;
}

const StActionTableRow = (props: IProps) => {
    const {action, changeTableContent} = props;

    const [actionState, setActionState] = useState<IAction>(action);

    const isEditedRow = () => {
        return JSON.stringify(actionState) !== JSON.stringify(action);
    }

    const changeHandleApproved = ( event: React.ChangeEvent<HTMLInputElement>) => {
        setActionState({
            ...actionState,
            approved: event.target.checked
        })
    }

    const changeHandle = (key: 'name' | 'key' | 'link' | 'grabber_key' | 'config' | 'dtc_config', event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setActionState({
            ...actionState,
            [key]: event.target.value
        })
    }

    const onSaveClickHandle = () => {
        if (isEditedRow()) {
            const config = currentConfig();
            fetch(config.apiUrl + `update_st_action/${actionState.st_action_}`, {
                method: 'put',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify({
                    ...actionState,
                })
            })
                .then(response => response.json())
                .then(() => {
                    changeTableContent();
                })
                .catch(err => {
                    console.log('error:', err)
                })

        }
    }

    useEffect(() => {
        setActionState(JSON.parse(JSON.stringify(action)));
    }, [action])


    return (
        <TableRow>
            <TableCell>
                {actionState?.st_action_}
            </TableCell>
            <TableCell>
                <Input value={actionState?.name || ''} onChange={(e) => changeHandle('name', e)}/>
            </TableCell>
            <TableCell>
                <Input value={actionState?.key || ''} onChange={(e) => changeHandle('key', e)}/>
            </TableCell>
            <TableCell>
                <Input value={actionState?.link || ''} onChange={(e) => changeHandle('link', e)}/>
            </TableCell>
            <TableCell>
                <Checkbox checked={actionState?.approved} onChange={(e) => changeHandleApproved(e)}/>
            </TableCell>
            <TableCell>
                <Input value={actionState?.grabber_key || ''} onChange={(e) => changeHandle('grabber_key', e)}/>
            </TableCell>
            <TableCell>
                <Input value={actionState?.config || ''} onChange={(e) => changeHandle('config', e)}/>
            </TableCell>
            <TableCell>
                <TextField multiline={true} onChange={(e) => changeHandle('dtc_config', e)} value={actionState?.dtc_config || ''} fullWidth={true}/>
            </TableCell>
            <TableCell>
                <SaveIcon onClick={onSaveClickHandle} style={isEditedRow() ? {cursor: "pointer"} : {cursor: "auto"}} color={isEditedRow() ? 'primary' : 'disabled'}/>
            </TableCell>

        </TableRow>
    )
}

export default StActionTableRow;
