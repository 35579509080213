import React, {useEffect, useState} from 'react';
import Preloader from "../../components/Preloader";
import Layout from "../../components/layout/Common";
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import currentConfig from "../../utils/config.utils";
import FwDtcConfigTable from "./components/FwDtcConfigTable";
import FwDtcConfigHeader from "./components/FwDtcConfigHeader";

const useStyles = makeStyles({
    container: {
        minHeight: '80vh',
    },
    title: {
        padding: '16px 0 0 16px'
    }
});

const rowsPerPage = 20;

function FwDtcConfig() {
    const classes = useStyles();
    const [expectAnswer, setExpectAnswer] = useState<boolean>(false);
    const [configs, setConfigs] = useState<any>(null);

    const changeTableContent = () => {
        setExpectAnswer(true);
        const config = currentConfig();
        const initUrl = new URL(window.location.href);

        fetch(config.apiUrl + 'fw_dtc_config_list', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                count: rowsPerPage,
                page: initUrl.searchParams.get("page") ? Number(initUrl.searchParams.get("page")) : 1,
                status: {
                    fw_ecu_: initUrl.searchParams.get("fw_ecu_") === null ? "" : initUrl.searchParams.get("fw_ecu_"),
                    st_action_: initUrl.searchParams.get("st_action_") === null ? "" : initUrl.searchParams.get("st_action_"),
                    dtc: initUrl.searchParams.get("dtc") === null ? "" : initUrl.searchParams.get("dtc"),
                }
            })
        })
            .then(response => response.json())
            .then(data => {
                setConfigs(data.response);
                setExpectAnswer(false);
            })
            .catch(err => {
                console.log('error:', err)
            })

    }

    useEffect(() => {
        changeTableContent();
    }, []);


    return (
        <>
            <Layout noHeightConstraint>
                {expectAnswer && <Preloader pageCentered/>}
                {!expectAnswer && (
                    <Card variant='outlined' className={classes.container}>
                        <Typography className={classes.title} variant='h4'>
                            fw_dtc_config
                        </Typography>
                        <FwDtcConfigHeader changeTableContent={changeTableContent}/>
                        <CardContent>
                            <FwDtcConfigTable changeTableContent={changeTableContent} configs={configs}/>
                        </CardContent>
                    </Card>
                )}
            </Layout>
        </>);

}

export default FwDtcConfig;