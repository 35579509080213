// outsource dependencies
import React from 'react';
// UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
// constants
import { APP_COLOR } from 'constants/style';
// local dependencies
import {
  // interface
  IFwSoftGroup,
  IFwEcu,
} from 'private-layout/fw-soft-group/reducer';

const useStyles = makeStyles({
  paperFullWidth: {
    width: 500,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 24px',
    backgroundColor: APP_COLOR.GREY,
    color: APP_COLOR.WHITE,
  },
  close: {
    padding: 6,
    color: APP_COLOR.WHITE,
    cursor: 'pointer',
  },
  titleContent: {
    lineHeight: 2,
  },
  content: {
    display: 'flex',
    padding: 12,
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  continueAction: {
    backgroundColor: APP_COLOR.LIGHT_GREY,
    color: APP_COLOR.WHITE,
    '&:hover': {
      color: APP_COLOR.BLACK,
    },
    transition: '0.6s',
  },
  config: {
    padding: 15,
    maxWidth: 350,
    maxHeight: 300,
    margin: '8px 0 8px 1em',
    overflowX: 'auto',
  },
});

interface IProps {
  open: boolean;
  fwSoftGroupDelete?: IFwSoftGroup | null;
  fwEcuList?: IFwEcu[] | null;
  //
  handleClose: () => void;
  handleAction?: () => void;
}

function DeleteFwSoftGroupDialog(props: IProps) {
  const {
    open,
    fwSoftGroupDelete,
    fwEcuList,
    //
    handleClose,
    handleAction,
  } = props;
  const classes = useStyles();
  const getFwEcuNameById = (fw_ecu_: number) => fwEcuList?.find((fwEcu: IFwEcu) => Number(fwEcu.key) === fw_ecu_)?.name;
  return (
    <Dialog open={open} onClose={handleClose} classes={{ paperFullWidth: classes.paperFullWidth }} fullWidth maxWidth='md'>
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <Typography variant='h6' className={classes.titleContent}>
          Удаление FW soft group
        </Typography>
        <CloseIcon onClick={handleClose} className={classes.close} />
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          {fwSoftGroupDelete && (
            <div>
              <Typography>
                <strong>ID</strong> - {fwSoftGroupDelete.fw_soft_group_}
              </Typography>
              <Typography>
                <strong>FW ECU</strong> - {getFwEcuNameById(fwSoftGroupDelete.fw_ecu_)}
              </Typography>
              <Typography>
                <strong>FW key</strong> - {fwSoftGroupDelete.fw_key}
              </Typography>
              {fwSoftGroupDelete.config && (
                <Typography>
                  <strong>FW config:</strong>
                  <br />
                  <Paper elevation={3} className={classes.config}>
                    {fwSoftGroupDelete.config}
                  </Paper>
                </Typography>
              )}
              <Typography>
                <strong>Approved</strong> - {String(fwSoftGroupDelete.approved)}
              </Typography>
            </div>
          )}
          <div className={classes.actions}>
            <Button onClick={handleAction} className={classes.continueAction}>
              Подтвердить удаление
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteFwSoftGroupDialog;
