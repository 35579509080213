import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Layout from 'components/layout/Common';
import {
  Card,
  Tab,
  Tabs,
} from "@material-ui/core";

import {useParams} from "react-router";
//import currentConfig from "../../utils/config.utils";
import Uploading from "./components/Uploading";
import History from "./components/History";
import {connect} from "react-redux";
import {IUser, selector as app} from "../../reducers";
import {getPermission} from "../../utils/permission.utils";
import Messages from "../ticket/components/Messages";
import Payments from "./components/Payments";
import {toast, ToastContainer} from "react-toastify";
import FileInfo from "../../components/fileInfo/FileInfo";
import currentConfig from "../../utils/config.utils";

const useStyles = makeStyles({
  container: {
    minHeight: '80vh',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif'
  },
  title: {
    padding: '16px 0 0 16px'
  },
  fileInfoContainer: {
    display: "grid",
    gridTemplateColumns: "min-content auto",
    gridColumnGap: "50px",
    marginLeft: "50px",
    marginTop: "20px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  tabContainer: {
    padding: "20px"
  }
});

interface IProps {
  user?: IUser | null;
}

function File({user}: IProps) {
  const {key} = useParams<{key: string}>();

  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const [ticketCount, setTicketCount] = useState(0);
  const [historyCount, setHistoryCount] = useState(0);

  const isSuperAdmin = useMemo(() => {
    return getPermission(user?.role, ["superadmin"])
  }, [user]);

  useEffect(() => {
    if (!key) {
      return
    }
    const config = currentConfig();
    fetch(config.apiUrl + 'admin/files/history', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify({fileKey: key})
    }).then(response => response.json())
      .then(data => {
        setHistoryCount(data.response.filesHistory.length)
      })
      .catch(err => {
        console.log('error:', err)
        toast.error(err);
      })

    fetch(config.apiUrl + 'ticket/loadMessages', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify({
        fileKey: key
      })
    }).then(response => response.json())
      .then(data => {
        setTicketCount(data.response.messages.length);
      });
  }, [key])

  const changeHistoryCount = (count: number) => {
    setHistoryCount(count)
  }
  const changeMessagesCount = (count: number) => {
    setTicketCount(count)
  }

  return (
    <Layout noHeightConstraint>
        <Card variant='outlined' className={classes.container}>

          <FileInfo fileKey={key} onTaskClose={() => {}}/>

          <Tabs
            value={tabIndex}
            onChange={(e, index: number) => {
              setTabIndex(index)
            }}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabContainer}
          >
            <Tab label="Загрузить файл"/>
            <Tab label={`История ${historyCount}`}/>
            <Tab label={`Тикеты  ${ticketCount}`}/>
            {isSuperAdmin && <Tab label="Платежи"/>}
          </Tabs>

          <div className={classes.tabContainer}>
            {tabIndex === 0 && <Uploading changeCount={changeHistoryCount}/>}
            {tabIndex === 1 && <History fileKey={key} isSuperAdmin={isSuperAdmin} changeCount={changeHistoryCount}/>}
            {tabIndex === 2 && <Messages changeCount={changeMessagesCount}/>}
            {tabIndex === 3 && isSuperAdmin && <Payments/>}
          </div>

        </Card>
      <ToastContainer />
    </Layout>
  )
}

export default connect(
  (state) => app(state),
)(File);