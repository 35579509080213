// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, InjectedFormProps } from 'redux-form';
// UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
// constants
import { APP_COLOR } from 'constants/style';
// local dependencies
import {
  selector as fwSoftSelector,
  // actions
  updateFwSoftDataAction,
  resetErrorCreateAction,
  updateErrorCreateAction,
  saveNewSoftAction,
  // interface
  IFwEcu,
  IFwSoftGroup,
  ICreateFormError,
  ICreateForm,
  // constants
  CREATE_FIELD_KEY,
} from 'private-layout/fw-soft/reducer';
// UI
import CustomTextField from 'components/input/TextField';
import CustomAutocomplete, {ICustomAutocompleteOption} from 'components/input/Autocomplete';
import CustomTextareaAutosize from 'components/input/Textarea';
import Preloader from 'components/Preloader';
// services
import ValidationService from 'services/validation.service';

// configurations
export const FORM_NAME = 'createFwSoftForm';

const useStyles = makeStyles({
  paperFullWidth: {
    width: 500,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 24px',
    backgroundColor: APP_COLOR.GREY,
    color: APP_COLOR.WHITE,
  },
  close: {
    padding: 6,
    color: APP_COLOR.WHITE,
    cursor: 'pointer',
  },
  titleContent: {
    lineHeight: 2,
  },
  content: {
    display: 'flex',
    padding: 12,
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  continueAction: {
    backgroundColor: APP_COLOR.LIGHT_GREY,
    color: APP_COLOR.WHITE,
    '&:hover': {
      color: APP_COLOR.BLACK,
    },
    transition: '0.6s',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
});

interface IProps {
  open: boolean;
  fwEcuList?: IFwEcu[] | null;
  fwSoftGroup?: IFwSoftGroup[] | null;

  expectFwSoftAnswer?: boolean;

  newFwSoftEcuId?: string;
  newSoftFull?: string;
  newSoftIdx?: string;
  newFwSoftGroupId?: string;
  newConfig?: string;

  createErrors?: ICreateForm | null;
  // state action
  updateNewFwSoftEcuId?: (newFwSoftEcuId: string) => void;
  updateNewSoftFull?: (newSoftFull: string) => void;
  updateNewSoftIdx?: (newSoftIdx: string) => void;
  updateNewFwSoftGroupId?: (newFwSoftGroupId: string) => void;
  updateNewConfig?: (newConfig: string) => void;

  resetErrorCreate?: (fieldKey: string) => void;
  updateErrorCreate?: (createErrors: ICreateFormError) => void;

  saveNewSoft?: () => void;
  //
  handleClose: () => void;
  // redux
  handleSubmit?: any;
  validate?: any;
}

const CreateFwSoftDialog: React.FC<IProps & InjectedFormProps<{}, IProps>> = (props: IProps) => {
  const {
    open,
    // state
    fwEcuList,
    fwSoftGroup,
    expectFwSoftAnswer,
    newFwSoftEcuId,
    newSoftFull,
    newSoftIdx,
    newFwSoftGroupId,
    newConfig,
    createErrors,
    //
    handleClose,
    // state action
    updateNewFwSoftEcuId,
    updateNewSoftFull,
    updateNewSoftIdx,
    updateNewFwSoftGroupId,
    updateNewConfig,
    resetErrorCreate,
    updateErrorCreate,
    //
    saveNewSoft,
    //
    handleSubmit,
    validate,
  } = props;
  const classes = useStyles();
  const isNewFwSoftEcuId = Boolean(createErrors?.newFwSoftEcuId);
  const isNewSoftFull = Boolean(createErrors?.newSoftFull);
  const isNewSoftIdx = Boolean(createErrors?.newSoftIdx);
  const newFwSoftEcuIdError = typeof createErrors?.newFwSoftEcuId === 'string' ? createErrors.newFwSoftEcuId : '';
  const newSoftFullError = typeof createErrors?.newSoftFull === 'string' ? createErrors.newSoftFull : '';
  const newSoftIdxError = typeof createErrors?.newSoftIdx === 'string' ? createErrors.newSoftIdx : '';

  const isSubmitNotAvailable = createErrors && Boolean(Object.values(createErrors).filter((error) => Boolean(error)).length);

  const handleOnFocus = (fieldKey: string) => () => {
    const isErrorInState = createErrors && Object.keys(createErrors).some((errorKey: string, index) => Object.values(createErrors)[index] && fieldKey === errorKey);
    if (isErrorInState) {
      resetErrorCreate && resetErrorCreate(fieldKey);
    }
  };
  const handleNewSoftEcuId = (value: ICustomAutocompleteOption | null) => {
    const flattenValue = String(value?.key || '');
    updateNewFwSoftEcuId && updateNewFwSoftEcuId(flattenValue);
  };
  const handleNewSoftFull = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNewSoftFull && updateNewSoftFull(e.target.value);
  };
  const handleNewSoftIdx = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNewSoftIdx && updateNewSoftIdx(e.target.value);
  };
  const handleNewFwSoftGroupId = (value: ICustomAutocompleteOption | null) => {
    const flattenValue = String(value?.key || '');
    updateNewFwSoftGroupId && updateNewFwSoftGroupId(flattenValue);
  };
  const handleNewConfig = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNewConfig && updateNewConfig(e.target.value);
  };
  const handleFormSubmit = () => {
    const localErrors =
      validate &&
      validate({
        newFwSoftEcuId,
        newSoftFull,
        newSoftIdx,
        newFwSoftGroupId,
        newConfig,
      });
    if (!localErrors && !isSubmitNotAvailable) {
      saveNewSoft && saveNewSoft();
    }
    if (localErrors) {
      updateErrorCreate && updateErrorCreate(localErrors);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paperFullWidth: classes.paperFullWidth }} fullWidth maxWidth='md'>
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <Typography variant='h6' className={classes.titleContent}>
          Создать FW soft
        </Typography>
        <CloseIcon onClick={handleClose} className={classes.close} />
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <Form onSubmit={handleSubmit(handleFormSubmit)} className={classes.form}>
            {Array.isArray(fwEcuList) && (
                <CustomAutocomplete
                    options={fwEcuList}
                    onChange={handleNewSoftEcuId}
                    error={isNewFwSoftEcuId}
                    onFocus={handleOnFocus(CREATE_FIELD_KEY.newFwSoftEcuId)}
                    helperText={newFwSoftEcuIdError}
                    label='New Fw Soft Ecu'
                    placeholder='New Fw Soft Ecu'/>
            )}
            <Field
              type='text'
              component={CustomTextField}
              name='newSoftFull'
              placeholder='New Soft Full'
              label='New Soft Full'
              onChange={handleNewSoftFull}
              error={isNewSoftFull}
              onFocus={handleOnFocus(CREATE_FIELD_KEY.newSoftFull)}
              helperText={newSoftFullError}
            />
            <Field
              type='text'
              component={CustomTextField}
              name='newSoftIdx'
              placeholder='New Soft Idx'
              label='New Soft Idx'
              onChange={handleNewSoftIdx}
              error={isNewSoftIdx}
              onFocus={handleOnFocus(CREATE_FIELD_KEY.newSoftIdx)}
              helperText={newSoftIdxError}
            />
            {Array.isArray(fwSoftGroup) && (
                <CustomAutocomplete
                    options={fwSoftGroup}
                    onChange={handleNewFwSoftGroupId}
                    label='New Fw Soft Group'
                    placeholder='New Fw Soft Group'/>
            )}

            <Field type='text' component={CustomTextareaAutosize} name='newConfig' placeholder='New Config' label='New Config' onChange={handleNewConfig} />
          </Form>
          <div className={classes.actions}>
            {expectFwSoftAnswer && <Preloader />}
            {!expectFwSoftAnswer && (
              <Button onClick={handleFormSubmit} className={classes.continueAction} disabled={Boolean(isSubmitNotAvailable)}>
                Создать
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect(
  (state) => ({
    newFwSoftEcuId: fwSoftSelector(state).newFwSoftEcuId,
    newSoftFull: fwSoftSelector(state).newSoftFull,
    newSoftIdx: fwSoftSelector(state).newSoftIdx,
    newFwSoftGroupId: fwSoftSelector(state).newFwSoftGroupId,
    newConfig: fwSoftSelector(state).newConfig,
    createErrors: fwSoftSelector(state).createErrors,
    expectFwSoftAnswer: fwSoftSelector(state).expectFwSoftAnswer,
    initialValues: {
      newFwSoftEcuId: fwSoftSelector(state).newFwSoftEcuId,
      newSoftFull: fwSoftSelector(state).newSoftFull,
      newSoftIdx: fwSoftSelector(state).newSoftIdx,
      newFwSoftGroupId: fwSoftSelector(state).newFwSoftGroupId,
      newConfig: fwSoftSelector(state).newConfig,
    },
  }),
  (dispatch) => ({
    updateNewFwSoftEcuId: (newFwSoftEcuId: string) => dispatch(updateFwSoftDataAction({ newFwSoftEcuId })),
    updateNewSoftFull: (newSoftFull: string) => dispatch(updateFwSoftDataAction({ newSoftFull })),
    updateNewSoftIdx: (newSoftIdx: string) => dispatch(updateFwSoftDataAction({ newSoftIdx })),
    updateNewFwSoftGroupId: (newFwSoftGroupId: string) => dispatch(updateFwSoftDataAction({ newFwSoftGroupId })),
    updateNewConfig: (newConfig: string) => dispatch(updateFwSoftDataAction({ newConfig })),

    resetErrorCreate: (fieldKey: string) => dispatch(resetErrorCreateAction(fieldKey)),
    updateErrorCreate: (createErrors: ICreateFormError) => dispatch(updateErrorCreateAction(createErrors)),

    saveNewSoft: () => dispatch(saveNewSoftAction()),
  })
)(
  reduxForm<{}, IProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values: ICreateForm): any => {
      const errors: ICreateFormError = {
        newFwSoftEcuId: null,
        newSoftFull: null,
        newSoftIdx: null,
        newFwSoftGroupId: null,
        newConfig: null,
      };
      const newFwSoftEcuIdInvalid = !values.newFwSoftEcuId ? 'Поле обязательно' : false;
      const newSoftFullInvalid = typeof values.newSoftFull === 'string' ? ValidationService.getStringInvalidReason(values.newSoftFull) : 'Поле обязательно';
      const newSoftIdxInvalid =
        typeof values.newSoftIdx === 'string'
          ? ValidationService.getStringInvalidReason(values.newSoftIdx, { minLength: 1, maxLength: 20, isRequired: true })
          : 'Поле обязательно';
      if (newFwSoftEcuIdInvalid) {
        errors.newFwSoftEcuId = newFwSoftEcuIdInvalid;
      }
      if (newSoftFullInvalid) {
        errors.newSoftFull = newSoftFullInvalid;
      }
      if (newSoftIdxInvalid) {
        errors.newSoftIdx = newSoftIdxInvalid;
      }
      // if at least one error were found
      if (Object.values(errors).filter((error) => Boolean(error)).length) return errors;
      return null;
    },
  })(CreateFwSoftDialog)
);
