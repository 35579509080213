// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, InjectedFormProps } from 'redux-form';
// UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
// constants
import { APP_COLOR } from 'constants/style';
// local dependencies
import {
  selector as fwSoftGroupSelector,
  // actions
  updateFwSoftGroupDataAction,
  resetErrorCreateAction,
  updateErrorCreateAction,
  saveNewSoftGroupAction,
  // interface
  IFwEcu,
  ICreateFormError,
  ICreateForm,
  // constants
  CREATE_FIELD_KEY,
} from 'private-layout/fw-soft-group/reducer';
// UI
import CustomTextField from 'components/input/TextField';
import CustomAutocomplete, {ICustomAutocompleteOption} from 'components/input/Autocomplete';
import CustomTextareaAutosize from 'components/input/Textarea';
import CustomCheckbox from 'components/checkbox/Checkbox';
import Preloader from 'components/Preloader';
// services
import ValidationService from 'services/validation.service';

// configurations
export const FORM_NAME = 'createFwSoftGroupForm';

const useStyles = makeStyles({
  paperFullWidth: {
    width: 500,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 24px',
    backgroundColor: APP_COLOR.GREY,
    color: APP_COLOR.WHITE,
  },
  close: {
    padding: 6,
    color: APP_COLOR.WHITE,
    cursor: 'pointer',
  },
  titleContent: {
    lineHeight: 2,
  },
  content: {
    display: 'flex',
    padding: 12,
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  continueAction: {
    backgroundColor: APP_COLOR.LIGHT_GREY,
    color: APP_COLOR.WHITE,
    '&:hover': {
      color: APP_COLOR.BLACK,
    },
    transition: '0.6s',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  configContainer: {
    width: '100%',
    paddingTop: 8,
  },
});

interface IProps {
  open: boolean;
  fwEcuList?: IFwEcu[] | null;

  expectFwSoftGroupAnswer?: boolean;

  newFwEcu?: string;
  newFwKey?: string;
  newApproved?: string;
  newConfig?: string;

  createErrors?: ICreateForm | null;
  // state action
  updateNewFwEcu?: (newFwEcu: string) => void;
  updateNewFwKey?: (newFwKey: string) => void;
  updateNewApproved?: (newApproved: boolean) => void;
  updateNewConfig?: (newConfig: string) => void;

  resetErrorCreate?: (fieldKey: string) => void;
  updateErrorCreate?: (createErrors: ICreateFormError) => void;

  saveNewSoftGroup?: () => void;
  //
  handleClose: () => void;
  // redux
  handleSubmit?: any;
  validate?: any;
}

const CreateFwSoftGroupDialog: React.FC<IProps & InjectedFormProps<{}, IProps>> = (props: IProps) => {
  const {
    open,
    // state
    fwEcuList,
    expectFwSoftGroupAnswer,
    newFwEcu,
    newFwKey,
    newApproved,
    newConfig,
    createErrors,
    //
    handleClose,
    // state action
    updateNewFwEcu,
    updateNewFwKey,
    updateNewApproved,
    updateNewConfig,
    resetErrorCreate,
    updateErrorCreate,
    //
    saveNewSoftGroup,
    //
    handleSubmit,
    validate,
  } = props;
  const classes = useStyles();
  const isNewFwEcu = Boolean(createErrors?.newFwEcu);
  const isNewFwKey = Boolean(createErrors?.newFwKey);
  const newFwEcuError = typeof createErrors?.newFwEcu === 'string' ? createErrors.newFwEcu : '';
  const newFwKeyError = typeof createErrors?.newFwKey === 'string' ? createErrors.newFwKey : '';

  const isSubmitNotAvailable = createErrors && Boolean(Object.values(createErrors).filter((error) => Boolean(error)).length);

  const handleOnFocus = (fieldKey: string) => () => {
    const isErrorInState = createErrors && Object.keys(createErrors).some((errorKey: string, index) => Object.values(createErrors)[index] && fieldKey === errorKey);
    if (isErrorInState) {
      resetErrorCreate && resetErrorCreate(fieldKey);
    }
  };
  const handleNewSoftEcuId = (value: ICustomAutocompleteOption | null) => {
    const flattenValue = String(value?.key || '');
    updateNewFwEcu && updateNewFwEcu(flattenValue);
  };
  const handleNewFwKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNewFwKey && updateNewFwKey(e.target.value);
  };
  const handleNewApproved = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNewApproved && updateNewApproved(e.target.checked);
  };

  const handleNewConfig = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNewConfig && updateNewConfig(e.target.value);
  };
  const handleFormSubmit = () => {
    const localErrors =
      validate &&
      validate({
        newFwEcu,
        newFwKey,
        newApproved,
        newConfig,
      });
    if (!localErrors && !isSubmitNotAvailable) {
      saveNewSoftGroup && saveNewSoftGroup();
    }
    if (localErrors) {
      updateErrorCreate && updateErrorCreate(localErrors);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paperFullWidth: classes.paperFullWidth }} fullWidth maxWidth='md'>
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <Typography variant='h6' className={classes.titleContent}>
          Создать FW soft group
        </Typography>
        <CloseIcon onClick={handleClose} className={classes.close} />
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <Form onSubmit={handleSubmit(handleFormSubmit)} className={classes.form}>
            {Array.isArray(fwEcuList) && (
                <CustomAutocomplete
                    options={fwEcuList}
                    onChange={handleNewSoftEcuId}
                    error={isNewFwEcu}
                    onFocus={handleOnFocus(CREATE_FIELD_KEY.newFwEcu)}
                    helperText={newFwEcuError}
                    label='New Fw Soft Ecu'
                    placeholder='New Fw Soft Ecu'/>
            )}
            <Field
              type='text'
              component={CustomTextField}
              name='newFwKey'
              placeholder='New Fw Key'
              label='New Fw Key'
              onChange={handleNewFwKey}
              error={isNewFwKey}
              onFocus={handleOnFocus(CREATE_FIELD_KEY.newFwKey)}
              helperText={newFwKeyError}
            />
            <div className={classes.configContainer}>
              <Field type='text' component={CustomTextareaAutosize} name='newConfig' placeholder='New Config' label='New Config' onChange={handleNewConfig} />
            </div>
            <CustomCheckbox label='Approved' checked={Boolean(newApproved)} onChange={handleNewApproved} />
          </Form>
          <div className={classes.actions}>
            {expectFwSoftGroupAnswer && <Preloader />}
            {!expectFwSoftGroupAnswer && (
              <Button onClick={handleFormSubmit} className={classes.continueAction} disabled={Boolean(isSubmitNotAvailable)}>
                Создать
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect(
  (state) => ({
    newFwEcu: fwSoftGroupSelector(state).newFwEcu,
    newFwKey: fwSoftGroupSelector(state).newFwKey,
    newApproved: fwSoftGroupSelector(state).newApproved,
    newConfig: fwSoftGroupSelector(state).newConfig,
    createErrors: fwSoftGroupSelector(state).createErrors,
    expectFwSoftGroupAnswer: fwSoftGroupSelector(state).expectFwSoftGroupAnswer,
    initialValues: {
      newFwEcu: fwSoftGroupSelector(state).newFwEcu,
      newFwKey: fwSoftGroupSelector(state).newFwKey,
      newApproved: fwSoftGroupSelector(state).newApproved,
      newConfig: fwSoftGroupSelector(state).newConfig,
    },
  }),
  (dispatch) => ({
    updateNewFwEcu: (newFwEcu: string) => dispatch(updateFwSoftGroupDataAction({ newFwEcu })),
    updateNewFwKey: (newFwKey: string) => dispatch(updateFwSoftGroupDataAction({ newFwKey })),
    updateNewApproved: (newApproved: boolean) => dispatch(updateFwSoftGroupDataAction({ newApproved })),
    updateNewConfig: (newConfig: string) => dispatch(updateFwSoftGroupDataAction({ newConfig })),

    resetErrorCreate: (fieldKey: string) => dispatch(resetErrorCreateAction(fieldKey)),
    updateErrorCreate: (createErrors: ICreateFormError) => dispatch(updateErrorCreateAction(createErrors)),

    saveNewSoftGroup: () => dispatch(saveNewSoftGroupAction()),
  })
)(
  reduxForm<{}, IProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values: ICreateForm): any => {
      const errors: ICreateFormError = {
        newFwEcu: null,
        newFwKey: null,
        newApproved: null,
        newConfig: null,
      };
      const newFwEcuInvalid = !values.newFwEcu ? 'Поле обязательно' : false;
      const newFwKeyInvalid = typeof values.newFwKey === 'string' ? ValidationService.getStringInvalidReason(values.newFwKey) : 'Поле обязательно';

      if (newFwEcuInvalid) {
        errors.newFwEcu = newFwEcuInvalid;
      }
      if (newFwKeyInvalid) {
        errors.newFwKey = newFwKeyInvalid;
      }

      // if at least one error were found
      if (Object.values(errors).filter((error) => Boolean(error)).length) return errors;
      return null;
    },
  })(CreateFwSoftGroupDialog)
);
