// outsource dependencies
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// UI
import { ToastContainer, toast } from 'react-toastify';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// local dependencies
// state
import {
  selector as clientRequestFilesSelector,
  // actions
  archiveFileAction,
  displayDtcAction,
  updatePageAction,
  updateFilesPerPageAction,
  updateFilesStatusAction,
  updateCurrentFileKeyAction,
  updateUploadedFileAction,
  // upload
  updateUploadFinishedAction,
  updateUploadSuccessAction,
  updateUploadErrorAction,
  // trigger
  uploadFileAction,
  loadFilesAction,
  initializeFilesAction,
  clearStateAction,
  // interface
  IClientFile,
  updateFilesInfoAction,
  updateHiddenOnlyAction,
  updateSortAction,
  updateDirectionAction,
} from 'private-layout/client-request-files/reducer';
// UI
import Layout from 'components/layout/Common';
import FilesTable from 'private-layout/client-request-files/components/FilesTable';
import FilesHeader from 'private-layout/client-request-files/components/FilesHeader';
import FileInfoDialog from 'private-layout/client-request-files/components/FileInfoDialog';
import Preloader from 'components/Preloader';
import BaseDialog from 'components/dialog/Base'

const useStyles = makeStyles({
  container: {
    minHeight: '80vh',
  },
  title: {
    padding: '16px 0 0 16px'
  }
});

interface IProps {
  // state
  totalFilesCount?: number;
  clientFiles?: IClientFile[] | null;

  expectAnswer?: boolean;
  expectFilesAnswer?: boolean;
  expectUploadFileAnswer?: boolean;
  // upload
  uploadFinished?: boolean;
  uploadSuccess?: boolean;
  uploadError?: boolean;

  page?: number;
  filesPerPage?: number;
  currentFileKey?: string | null;
  uploadedFile?: File | null;
  // action
  updatePage?: (page: number) => void;
  updateFilesPerPage?: (filesPerPage: number) => void;
  updateFilesStatus?: (fileStatus: number) => void;
  updateFilesInfo?: (fileInfo: string) => void;
  updateHiddenOnly?: (hiddenOnly: boolean) => void;
  updateSort?: (sort: string) => void;
  updateDirection?: (direction: string) => void;
  displayDtc?: (fileKey: string) => void;
  updateCurrentFileKey?: (currentFileKey: string | null) => void;
  updateUploadedFile?: (uploadedFile: File | null) => void;
  // upload
  updateUploadFinished?: (uploadFinished: boolean) => void;
  updateUploadSuccess?: (uploadSuccess: boolean) => void;
  updateUploadError?: (uploadError: boolean) => void;
  // trigger
  uploadFile?: (notifyClient: boolean, approvePayment: boolean, comment: string) => void;
  loadFiles?: (count: number, page: number) => void;
  initializeFiles?: () => void;
  clearState?: () => void;
  handleArchiveFile?: (fileKey: string) => void;
}

function ClientRequestFiles(props: IProps) {
  const {
    totalFilesCount,
    clientFiles,
    // loading
    expectAnswer,
    expectFilesAnswer,
    expectUploadFileAnswer,
    // upload
    uploadFinished,
    uploadSuccess,
    uploadError,
    // state
    page,
    filesPerPage,
    currentFileKey,
    uploadedFile,
    // state
    updatePage,
    updateFilesPerPage,
    updateFilesStatus,
    displayDtc,
    updateCurrentFileKey,
    updateUploadedFile,
    // upload
    updateUploadFinished,
    updateUploadSuccess,
    updateUploadError,
    // trigger
    uploadFile,
    loadFiles,
    initializeFiles,
    clearState,
    handleArchiveFile,
    updateFilesInfo,
    updateHiddenOnly,
    updateSort,
    updateDirection
  } = props;
  const classes = useStyles();

  const currentFile = Array.isArray(clientFiles) ? clientFiles.find((fileItem: IClientFile) => fileItem.key === currentFileKey) : null;

  const [openFileDialog, setOpenFileDialog] = useState<boolean>(false);
  const [uploadAllowed, setUploadAllowed] = useState<boolean>(false);
  const [previousFileName, setPreviousFileName] = useState<string>('');
  const [localUploadErrorMessage, setLocalUploadErrorMessage] = useState<string | null>(null);
  const [openLocalUploadErrorDialog, setOpenLocalUploadErrorDialog] = useState<boolean>(false);
  const handleUploadAllowed = () => {
    setUploadAllowed(true);
  };
  const handleLocalUploadErrorMessage = (message: string | null) => {
    setLocalUploadErrorMessage(message);
  };
  const handleOpenLocalUploadErrorDialog = () => {
    setOpenLocalUploadErrorDialog(true);
  };
  const handleCloseLocalUploadErrorDialog = () => {
    setOpenLocalUploadErrorDialog(false);
    setLocalUploadErrorMessage(null);
  };
  const handleOpenFileDialog = () => {
    setOpenFileDialog(true);
  };
  const handleUploadSuccessFeedback = () => {
    toast.success('Файл успешно загружен');
  };
  const handleUploadErrorFeedback = () => {
    toast.error('Не удалось обработать файл');
  };
  const handleCloseFileDialog = () => {
    // prevent reopen dialog
    setUploadAllowed(false);
    typeof uploadedFile?.name === 'string' && setPreviousFileName(uploadedFile.name);
    //
    setOpenFileDialog(false);
    // clean store on file upload
    updateCurrentFileKey && updateCurrentFileKey(null);
    updateUploadedFile && updateUploadedFile(null);
    updateUploadSuccess && updateUploadSuccess(false);
    updateUploadError && updateUploadError(false);
    updateUploadFinished && updateUploadFinished(false);
  };
  useEffect(() => {
    if (uploadSuccess) {
      handleUploadSuccessFeedback();
    }
    if (uploadError) {
      handleUploadErrorFeedback();
    }
  }, [uploadSuccess, uploadError]);
  useEffect(() => {
    initializeFiles && initializeFiles();
    return () => {
      clearState && clearState();
    };
  }, [initializeFiles, clearState]);
  return (
    <>
      <Layout noHeightConstraint>
        {expectAnswer && <Preloader pageCentered />}
        {!expectAnswer && (
          <Card variant='outlined' className={classes.container}>
            <Typography className={classes.title} variant='h4'>
              Client request files
            </Typography>
            <FilesHeader
                updateFilesInfo={updateFilesInfo}
                updateFilesStatus={updateFilesStatus}
                updateHiddenOnly={updateHiddenOnly}
                updateSort={updateSort}
                updateDirection={updateDirection}
                loadFiles={loadFiles}
                filesPerPage={Number(filesPerPage)} />
            <CardContent>
              <FilesTable
                page={Number(page)}
                filesPerPage={Number(filesPerPage)}
                totalFilesCount={Number(totalFilesCount)}
                clientFiles={clientFiles}
                updatePage={updatePage}
                updateFilesPerPage={updateFilesPerPage}
                displayDtc={displayDtc}
                expectFilesAnswer={expectFilesAnswer}
                loadFiles={loadFiles}
                handleOpenFileDialog={handleOpenFileDialog}
                updateCurrentFileKey={updateCurrentFileKey}
                updateUploadedFile={updateUploadedFile}
                handleOpenLocalUploadErrorDialog={handleOpenLocalUploadErrorDialog}
                handleLocalUploadErrorMessage={handleLocalUploadErrorMessage}
                uploadAllowed={uploadAllowed}
                handleUploadAllowed={handleUploadAllowed}
                handleArchiveFile={handleArchiveFile}
                previousFileName={previousFileName}
              />
            </CardContent>
          </Card>
        )}
      </Layout>
      {openFileDialog && (
        <FileInfoDialog
          open={openFileDialog}
          handleClose={handleCloseFileDialog}
          currentFile={currentFile}
          uploadedFile={uploadedFile}
          uploadFile={uploadFile}
          expectUploadFileAnswer={expectUploadFileAnswer}
          uploadFinished={uploadFinished}
        />
      )}
      {openLocalUploadErrorDialog && (
        <BaseDialog open={openLocalUploadErrorDialog} title='Ошибка загрузки файла' textContent={String(localUploadErrorMessage)} handleClose={handleCloseLocalUploadErrorDialog} />
      )}
      <ToastContainer />
    </>
  );
}

export default connect(
  (state) => clientRequestFilesSelector(state),
  (dispatch) => ({
    updatePage: (page: number) => dispatch(updatePageAction(page)),
    updateFilesPerPage: (filesPerPage: number) => dispatch(updateFilesPerPageAction(filesPerPage)),
    updateCurrentFileKey: (currentFileKey: string | null) => dispatch(updateCurrentFileKeyAction(currentFileKey)),
    updateUploadedFile: (uploadedFile: File | null) => dispatch(updateUploadedFileAction(uploadedFile)),

    updateUploadFinished: (uploadFinished: boolean) => dispatch(updateUploadFinishedAction(uploadFinished)),
    updateUploadSuccess: (uploadSuccess: boolean) => dispatch(updateUploadSuccessAction(uploadSuccess)),
    updateUploadError: (uploadError: boolean) => dispatch(updateUploadErrorAction(uploadError)),

    updateFilesStatus: (fileStatus: number) => dispatch(updateFilesStatusAction(fileStatus)),
    updateFilesInfo: (fileInfo: string) =>  dispatch(updateFilesInfoAction(fileInfo)),
    updateHiddenOnly: (hiddenOnly: boolean) =>  dispatch(updateHiddenOnlyAction(hiddenOnly)),
    updateSort: (sort: string) =>  dispatch(updateSortAction(sort)),
    updateDirection: (direction: string) =>  dispatch(updateDirectionAction(direction)),

    loadFiles: (count: number, page: number, status?: number[]) => dispatch(loadFilesAction(count, page, status)),
    initializeFiles: () => dispatch(initializeFilesAction()),
    uploadFile: (notifyClient: boolean, approvePayment: boolean, comment: string) => dispatch(uploadFileAction(notifyClient, approvePayment, comment)),
    clearState: () => dispatch(clearStateAction()),

    handleArchiveFile: (fileKey: string) => dispatch(archiveFileAction(fileKey)),

    displayDtc: (fileKey: string) => dispatch(displayDtcAction(fileKey)),
  })
)(ClientRequestFiles);
