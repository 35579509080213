
export const PUBLIC = {
  LOG_IN: '/log-in',
};

export const PRIVATE = {
  FW_ECU: '/fw-ecu',
  FW_SOFT: '/fw-soft',
  FW_SOFT_GROUP: '/fw-soft-group',
  CLIENT_REQUEST_FILES: '/client-request-files',
  CLIENTS: '/clients',
  CLIENT_EDIT: '/clients/edit/:id',
  FW_DTC_CONFIG: '/fw_dtc_config',
  CLIENT_HISTORY_COMPARE : '/client-history-compare/:id',
  ST_ACTION_BRAND_ECU: '/st_action_brand_ecu',
  TICKET: "/ticket/:key",
  FILE: "/file/:key",
  ST_ACTION: '/st_action',
  PRICE_GROUP: '/price_group',
  REMOVE_DTC: '/remove-dtc/:key',
  ST_ECU: '/st_ecu',
  CONTACTS: '/contacts'
};

export const QUERY = {
  FW_ECU: '?fw_ecu=',
  FW_SOFT_GROUP: '?fw_soft_group=',
  ST_ACTION_BRAND_ECU: '?st_ecu_=',
};

export const ROUTE_LIST = [
  {
    route: PRIVATE.FW_ECU,
    title: 'FW ECU',
    permissions: ["superadmin"],
  },
  {
    route: PRIVATE.FW_SOFT,
    title: 'FW SOFT',
    permissions: ["superadmin"],
  },
  {
    route: PRIVATE.FW_SOFT_GROUP,
    title: 'FW SOFT GROUP',
    permissions: ["superadmin"],
  },
  {
    route: PRIVATE.CLIENT_REQUEST_FILES,
    title: 'CLIENT FILES',
    permissions: ["superadmin", "filemanager"],
  },
  {
    route: PRIVATE.CLIENTS,
    title: 'CLIENTS',
    permissions: ["superadmin"],
  },
  {
    route: PRIVATE.FW_DTC_CONFIG,
    title: "FW DTC CONFIG",
    permissions: ["superadmin"],
  },
  {
    route: PRIVATE.ST_ACTION_BRAND_ECU,
    title: "ST ACTION BRAND ECU",
    permissions: ["superadmin"],
  },
  {
    route: PRIVATE.ST_ACTION,
    title: "ST ACTION",
    permissions: ["superadmin"],
  },
  {
    route: PRIVATE.ST_ECU,
    title: "ST ECU",
    permissions: ["superadmin"],
  },
  {
    route: PRIVATE.PRICE_GROUP,
    title: "PRICE GROUP",
    permissions: ["superadmin"],
  },
  {
    route: PRIVATE.CONTACTS,
    title: "Contacts page",
    permissions: ["superadmin"],
  }
];
