// outsource dependencies
import React from 'react';
import { Link } from 'react-router-dom';
// UI
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
// local dependencies
import {
  // constants
  DefaultTableOptions,
  // interface
  IFwEcu,
  IFwEcuBrand,
} from 'private-layout/fw-ecu/reducer';
// constants
import { QUERY, PRIVATE as ROUTE } from 'constants/routes';
import { APP_COLOR } from 'constants/style';
// UI
import Preloader from 'components/Preloader';
import CustomTextField from 'components/input/TextField';
import CustomSelect from 'components/select/Select';
import FwEcusPaginationActions from 'private-layout/fw-ecu/components/FwEcusPaginationActions';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  row: {
    maxWidth: 250,
  },
  addIcon: {
    color: APP_COLOR.GREEN,
  },
  saveIcon: {
    color: APP_COLOR.BLUE,
  },
});
interface IProps {
  totalEcusCount: number;
  fwEcusList?: IFwEcu[] | null;
  fwEcuBrandsList?: IFwEcuBrand[] | null;
  //
  expectFwEcusAnswer?: boolean;
  //
  page: number;
  filesPerPage: number;
  //
  new_fw_name?: string;
  new_fw_ecu_brand_?: string;
  new_nice_name?: string;
  // action
  updatePage?: (page: number) => void;
  updateEcusPerPage?: (ecusPerPage: number) => void;

  updateFwName?: (fw_ecu_: number, fw_name: string) => void;
  updateFwEcuBrand?: (fw_ecu_: number, fw_ecu_brand_: string) => void;
  updateFwNiceName?: (fw_ecu_: number, nice_name: string) => void;

  updateNewFwName?: (new_fw_name?: string) => void;
  updateNewEcuBrand?: (new_fw_ecu_brand_?: string) => void;
  updateNewNiceName?: (new_nice_name?: string) => void;
  // trigger
  loadEcus?: (count: number, page: number) => void;
  updateFwEcuAction?: (fw_ecu_: number) => void;
  addNewFwEcu?: () => void;
}

export default function FilesTable(props: IProps) {
  const {
    totalEcusCount,
    fwEcusList,
    fwEcuBrandsList,
    //
    page,
    filesPerPage,
    //
    expectFwEcusAnswer,
    //
    new_fw_name,
    new_fw_ecu_brand_,
    new_nice_name,
    //
    updatePage,
    updateEcusPerPage,
    //
    updateFwName,
    updateFwEcuBrand,
    updateFwNiceName,
    //
    updateNewFwName,
    updateNewEcuBrand,
    updateNewNiceName,
    //
    loadEcus,
    updateFwEcuAction,
    addNewFwEcu,
    //
  } = props;
  const classes = useStyles();

  const handleChangePage = (e: unknown, newPage: number) => {
    window.scrollTo(0, 0);
    loadEcus && loadEcus(filesPerPage, newPage);
    updatePage && updatePage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    window.scrollTo(0, 0);
    loadEcus && loadEcus(Number(event.target.value), DefaultTableOptions.DEFAULT_PAGE);
    updateEcusPerPage && updateEcusPerPage(Number(event.target.value));
    updatePage && updatePage(DefaultTableOptions.DEFAULT_PAGE);
  };
  const handleFwName = (e: React.ChangeEvent<HTMLInputElement>, fw_ecu_: number) => {
    updateFwName && updateFwName(fw_ecu_, e.target.value);
  };
  const handleFwEcuBrand = (e: React.ChangeEvent<HTMLSelectElement>, fw_ecu_: number) => {
    updateFwEcuBrand && updateFwEcuBrand(fw_ecu_, e.target.value);
  };
  const handleNiceName = (e: React.ChangeEvent<HTMLInputElement>, fw_ecu_: number) => {
    updateFwNiceName && updateFwNiceName(fw_ecu_, e.target.value);
  };
  const handleSaveUpdate = (fw_ecu_: number) => () => {
    updateFwEcuAction && updateFwEcuAction(fw_ecu_);
  };
  
  const handleNewFwName = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNewFwName && updateNewFwName(e.target.value);
  };
  const handleNewFwEcuBrand = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateNewEcuBrand && updateNewEcuBrand(e.target.value);
  };
  const handleNewNiceName = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNewNiceName && updateNewNiceName(e.target.value);
  };
  const allowAddNew = new_fw_name && new_fw_ecu_brand_ && new_nice_name;
  const handleSaveNewFwEcu = () => {
    addNewFwEcu && addNewFwEcu();
  };


  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>FW soft count</TableCell>
              <TableCell>FW name</TableCell>
              <TableCell>FW ecu brand</TableCell>
              <TableCell>Nice name</TableCell>
              <TableCell>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!expectFwEcusAnswer &&
              Array.isArray(fwEcusList) &&
              fwEcusList.map((fwEcuItem: IFwEcu, index) => (
                <TableRow hover key={index}>
                  <TableCell className={classes.row}>
                    <Link to={`${ROUTE.FW_SOFT_GROUP}${QUERY.FW_ECU}${fwEcuItem.fw_ecu_}`}>{fwEcuItem.fw_ecu_}</Link>
                  </TableCell>
                  <TableCell className={classes.row}>
                    <Link to={`${ROUTE.FW_SOFT}${QUERY.FW_ECU}${fwEcuItem.fw_ecu_}`}>{fwEcuItem.fw_soft_count}</Link>
                  </TableCell>
                  <TableCell className={classes.row}>
                    <CustomTextField
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleFwName(e, fwEcuItem.fw_ecu_);
                      }}
                      label='fw name'
                      placeholder='fw name'
                      value={fwEcuItem.fw_name}
                    />
                  </TableCell>
                  <TableCell className={classes.row}>
                    <CustomSelect
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        handleFwEcuBrand(e, fwEcuItem.fw_ecu_);
                      }}
                      label='fw ecu brand'
                      options={fwEcuBrandsList}
                      value={fwEcuItem.fw_ecu_brand_}
                    />
                  </TableCell>
                  <TableCell className={classes.row}>
                    <CustomTextField
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleNiceName(e, fwEcuItem.fw_ecu_);
                      }}
                      label='nice name'
                      placeholder='nice name'
                      value={fwEcuItem.nice_name}
                    />
                  </TableCell>
                  <TableCell className={classes.row}>
                    <IconButton onClick={handleSaveUpdate(fwEcuItem.fw_ecu_)} disabled={!fwEcuItem.changed} className={classes.saveIcon}>
                      <SaveIcon fontSize='large' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {!expectFwEcusAnswer && (
              <TableRow hover>
                <TableCell className={classes.row}>Добавить новый</TableCell>
                <TableCell className={classes.row} />
                <TableCell className={classes.row}>
                  <CustomTextField onChange={handleNewFwName} label='fw name' placeholder='fw name' value={new_fw_name} />
                </TableCell>
                <TableCell className={classes.row}>
                  <CustomSelect onChange={handleNewFwEcuBrand} label='fw ecu brand' options={fwEcuBrandsList} value={new_fw_ecu_brand_} />
                </TableCell>
                <TableCell className={classes.row}>
                  <CustomTextField onChange={handleNewNiceName} label='nice name' placeholder='nice name' value={new_nice_name} />
                </TableCell>
                <TableCell className={classes.row}>
                  <IconButton onClick={handleSaveNewFwEcu} disabled={!allowAddNew} className={classes.addIcon}>
                    <AddCircleIcon fontSize='large' />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {expectFwEcusAnswer && <Preloader centered />}
      <TablePagination
        rowsPerPageOptions={DefaultTableOptions.FILES_COUNT_OPTIONS}
        component='div'
        count={totalEcusCount}
        rowsPerPage={filesPerPage}
        labelRowsPerPage={<strong>Файлов на странице: </strong>}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={FwEcusPaginationActions}
      />
    </Paper>
  );
}
