
export enum FileStatus {
  'Любые'= 0,
  'Новые' = 1,
  'Оплаченные' = 2,
  'Обработанные' = 3,
  'Выданные' = 4,
  'Cannot Open' = 10,
  'Unsupported Format' = 11,
  'Wrong ecu/engine' = 12,
  'Actions not supported' = 13,
  'Payment not completed' = 14,
  'Закрыты' = 99
}

export enum OneFileStatus {
  'Любой'= 0,
  'Новый' = 1,
  'Оплаченный' = 2,
  'Обработанный' = 3,
  'Выданный' = 4,
  'Cannot Open' = 10,
  'Unsupported Format' = 11,
  'Wrong ecu/engine' = 12,
  'Actions not supported' = 13,
  'Payment not completed' = 14,
  'Закрыт' = 99
}