import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Button, TextField, Typography} from "@material-ui/core";

import {useParams} from "react-router";
import Preloader from "../../../components/Preloader";
import currentConfig from "../../../utils/config.utils";

const useStyles = makeStyles({
  container: {
    minHeight: '80vh',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif'
  },
  title: {
    padding: '16px 0 0 16px'
  },
  fileInfoContainer: {
    display: "grid",
    gridTemplateColumns: "min-content auto",
    gridColumnGap: "50px",
    marginLeft: "50px",
    marginTop: "20px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  messageContainer: {
    marginLeft: "50px",
    borderBottom: "1px solid black",
    marginBottom: "20px",
  },
  messageHeader: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  messageHeaderComponent: {
    display: "grid",
    gridTemplateColumns: "min-content auto",
    gridColumnGap: "20px",
    marginBottom: "15px",
  },
  message: {
    marginTop: "20px",
    marginBottom: "30px",
  },
  dtcContainer: {
    display: "grid",
    gridTemplateColumns: "min-content auto",
    gridColumnGap: "20px",
    marginBottom: "15px",
  },
  attachmentContainer: {
    display: "none",
    gridTemplateColumns: "min-content auto",
    gridColumnGap: "20px",
    marginBottom: "15px",
  },
  inputContainer: {
    marginLeft: "50px",
  },
  textInputField: {
    minWidth: "100%",
  },
  dtcInputField: {
    minWidth: "100%",
  },
  textLabel: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  dtcLabel: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  fileHeader: {
    marginTop: "20px",
    display: "none",
    gridTemplateColumns: "min-content auto",
    gridColumnGap: "20px",
  },
  fileContent: {
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "min-content auto",
    gridColumnGap: "20px",
  },
  selectedFileLabel: {
    whiteSpace: "nowrap",
  },
  openFileBtn: {
    maxWidth: "100px"
  },
  sendBtnContainer: {
    display: "flex",
    justifyContent: "end",
  },
  sendBtn: {
    width: "200px",
    color: '#000000',
    backgroundColor: "gray",
  },
  preloaderContainer: {
    marginLeft: "20px",
    marginRight: "20px",
  },
  uploadFile: {
    marginTop: 16,
    minWidth: 150,
  },
  hideUploader: {
    visibility: 'hidden',
    position: 'absolute',
  },
  uploaderBtn: {
    backgroundColor: "#3f51b5",
    color: "white",
    padding: 8,
    cursor: 'pointer',
    width: '150px',
    textAlign: 'center',
    borderRadius: 4,
    marginRight: 8,
    '&:hover': {
      backgroundColor: "#263dbd",
    },
  },
  uploader: {
    display: 'flex',
    alignItems: 'center',
  },
  dtcTextInput: {
    width: "100%",
  }
});

interface IProps {
  changeCount?: (count: number) => void
}

function Messages({changeCount}: IProps) {
  const {key} = useParams<{key: string}>();

  const classes = useStyles();

  const [text, setText] = useState<string>("");
  const [dtc, setDtc] = useState<string>("");
  const [file, setFile] = useState<any>(null);
  const [errorDtc, setErrorDtc] = useState<boolean>(false);
  const [expectAnswerMessages, setExpectAnswerMessages] = useState<boolean>(false);
  const [expectAnswerSendMessage, setExpectAnswerSendMessage] = useState<boolean>(false);
  const [messages, setMessages] = useState<any>(null);

  const loadMessages = (key: string | null) => {
    setExpectAnswerMessages(true);
    const config = currentConfig();
    fetch(config.apiUrl + 'ticket/loadMessages', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify({
        fileKey: key
      })
    }).then(response => response.json())
      .then(data => {
        setMessages(data.response.messages);
        setExpectAnswerMessages(false);
      });
  }

  const sendMessage = (data: any) => {
    setExpectAnswerSendMessage(true);
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('text', data.text);
    formData.append('dtc', data.dtc);
    formData.append('fileKey', data.fileKey);

    const config = currentConfig();
    fetch(config.apiUrl + 'ticket/sendMessage', {
      method: 'post',
      headers: {
        'Cache-Control': 'no-cache',
      },
      credentials: 'include',
      body: formData
    }).then(response => response.json())
      .then(() => {
        setExpectAnswerSendMessage(false);
        loadMessages(key);
      })
  }

  useEffect(() => {
    loadMessages(key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  useEffect(() => {
    if (messages) {
      changeCount && changeCount(messages.length);
    }
  }, [messages, changeCount]);


  const textOnChange = (event: any) => {
    setText(event.target.value);
  }

  const dtcOnChange = (event: any) => {
    const valid = validateDtc(event.target.value);
    if (!valid) {
      setErrorDtc(true);
    } else {
      setErrorDtc(false);
    }

    setDtc(event.target.value);
  }

  const fileOnChange = (event: any) => {
    setFile(event.target.files[0])
  }

  const sendMessageHandle = () => {
    sendMessage({
      text: text,
      dtc: dtc,
      file: file,
      fileKey: key,
    })

    clearInputs();
  }

  const clearInputs = () => {
    setText("");
    setDtc("");
    setFile(null);
  }

  const validateDtc = (dtc: string) => {
    if (dtc.length === 0) {
      return true;
    }
    const reg = new RegExp("^[0-9a-fA-F]{4}");

    const dtcArr = dtc.split(",");

    for (let i = 0; i < dtcArr.length; i++) {
      if (dtcArr[i].length !== 4) {
        return false;
      }

      const valid = reg.test(dtcArr[i]);
      if (!valid) {
        return false;
      }
    }
    return true;
  }

  return (
    <>
      {expectAnswerSendMessage && <Preloader pageCentered />}
      {!expectAnswerSendMessage && (
        <>

          <Typography className={classes.title}  variant="h5">
            Сообщения
            <span className={classes.preloaderContainer}>
                            {expectAnswerMessages ? <Preloader size={20}/> : null}
                        </span>
          </Typography>

          {messages && Array.isArray(messages) &&
            messages.map((message, index) => {
              return (
                <div key={index} className={classes.messageContainer}>
                  <div className={classes.messageHeader}>
                    <div className={classes.messageHeaderComponent}>
                      <label>
                        <b>Дата</b>
                      </label>
                      <label>
                        {new Date((message.add_date + ' UTC').replace(/-/g, "/")).toLocaleString()}
                      </label>
                    </div>
                    <div className={classes.messageHeaderComponent}>
                      <label>
                        <b> {message.clientusername ? "Клиент: " : "Админ: "} </b>
                      </label>
                      <label>
                        {message.clientusername ? message.clientusername : message.adminusername}
                      </label>
                    </div>
                  </div>
                  <div className={classes.message}>
                    <label>
                      {message.message}
                    </label>
                  </div>

                  <div className={classes.dtcContainer}>
                    <label>
                      <b>DTC</b>
                    </label>
                    <label>
                      {message.dtc}
                    </label>
                  </div>

                  <div className={classes.attachmentContainer}>
                    <label>
                      <b> Прикрипленное </b>
                    </label>
                    <label>
                      <a href="//google.com">
                        test.bin
                      </a>
                    </label>
                  </div>
                </div>
              )
            })}
          <Typography variant="h5" className={classes.title}  >
            Новое сообщение
          </Typography>
          <div className={classes.inputContainer}>
            <Typography className={classes.textLabel} variant="h6">
              Text
            </Typography>
            <TextField value={text}
                       onChange={textOnChange}
                       className={classes.textInputField}
                       multiline={true}/>

            <Typography className={classes.dtcLabel} variant="h6">
              DTC
            </Typography>

            <TextField error={errorDtc}
                       onChange={dtcOnChange}
                       helperText={"Пожалуйста введите 4х значный OBD код (PXXXX) известных Вам ошибок без буквы P, без пробелов, через запятую. Если Вы знаете код производителя или OBD код начинается с символа U, введите текстом в сообщение"}
                       className={classes.dtcTextInput}
                       value={dtc}/>

            <div className={classes.fileHeader}>
              <Typography variant="h6">
                Файл
              </Typography>

              <div className={classes.uploader}>
                <label className={classes.uploaderBtn}>
                  Выбрать файл
                  <input type='file' className={classes.hideUploader} onChange={fileOnChange}/>
                </label>
              </div>
            </div>
            <div className={classes.fileContent}>
              <label className={classes.selectedFileLabel}>
                <b>{file != null ? "Выбранный файл" : null}</b>
              </label>
              <label>
                {file?.name}
              </label>
            </div>
          </div>
          <div className={classes.sendBtnContainer}>
                        <span className={classes.preloaderContainer}>
                            {expectAnswerSendMessage ? <Preloader size={40}/> : null}
                        </span>
            <Button disabled={errorDtc || text.trim().length === 0}
                    onClick={sendMessageHandle}
                    className={classes.uploadFile} variant='contained' color='primary'>
              Отправить
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export default Messages;
