// local dependencies
import { emailOnly, noSpacePresent } from 'constants/regexp';

interface IOptions {
  maxLength?: number;
  noSpace?: boolean;
  minLength?: number;
  isRequired?: boolean;
}

const defaultEmailOptions: IOptions = {
  maxLength: 200,
  noSpace: true,
  minLength: 6,
};

const defaultPasswordOptions: IOptions = {
  maxLength: 50,
  noSpace: false,
  minLength: 8,
};

const defaultStringOptions: IOptions = {
  maxLength: 30,
  noSpace: false,
  minLength: 1,
  isRequired: true,
};

const getEmailInvalidReason = (string: string, options: IOptions | null) => {
  options = {
    ...defaultEmailOptions,
    ...options,
  };
  if (options.minLength && string.length < options.minLength) {
    return 'должно быть не меньше чем 6 символа';
  } else if (options.maxLength && string.length > options.maxLength) {
    return 'должно быть не более 200 символов';
  } else if (options.noSpace && noSpacePresent.test(string)) {
    return 'Пробелы недопустимы';
  } else if (!emailOnly.test(string)) {
    return !emailOnly.test(string) && 'неверный формат "email"';
  }
  return null;
};

const getPasswordInvalidReason = (string: string, options: IOptions | null) => {
  options = {
    ...defaultPasswordOptions,
    ...options,
  };
  if (options.minLength && string.length < options.minLength) {
    return 'должно быть не меньше чем 8 символов';
  } else if (options.maxLength && string.length > options.maxLength) {
    return 'должно быть не более 50 символов';
  } else if (options.noSpace && noSpacePresent.test(string)) {
    return 'Пробелы недопустимы';
  }
  return null;
};

const getStringInvalidReason = (string: string, options?: IOptions) => {
  options = {
    ...defaultStringOptions,
    ...options,
  };
  if (options.minLength && string.length < options.minLength) {
    return `должно быть не меньше чем ${options.minLength} символов`;
  } else if (options.maxLength && string.length > options.maxLength) {
    return `должно быть не более ${options.maxLength} символов`;
  } else if (options.noSpace && noSpacePresent.test(string)) {
    return 'Пробелы недопустимы';
  } else if (options.isRequired && !String(string).length) {
    return 'Поле обязательно';
  }
  return null;
};

export default {
  getPasswordInvalidReason,
  getEmailInvalidReason,
  getStringInvalidReason,
};
