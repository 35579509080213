import React, {useCallback, useEffect, useState} from 'react';
import Layout from "../../components/layout/Common";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import currentConfig from "../../utils/config.utils";
import PriceGroupTable from "./components/PriceGroupTable";
import Preloader from "../../components/Preloader";
import PriceGroupEditor from "./components/PriceGroupEditor/PriceGroupEditor";
import {IPriceGroup, ISettingsList, ISettingsResponse, IVehicle, IVehiclesResponse} from "./types";
import {Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {ToastContainer} from "react-toastify";

const useStyles = makeStyles({
    container: {
        minHeight: '80vh',
    },
    title: {
        padding: '16px 0 0 16px'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: '95%',
        margin: '20px'
    },
    dropDown: {
        minWidth: '80%',
    }
});

export interface IPriceGroupResponse {
    prices: IPriceGroup[]
    count: string
}

function PriceGroup() {
    const classes = useStyles();

    const [expectAnswer, setExpectAnswer] = useState<boolean>(false);
    const [vehicleId, setVehicleId] = useState<number>(0);
    const [vehicles, setVehicles] = useState<IVehicle[]>([]);
    const [vehicle, setVehicle] = useState<IVehicle | undefined>();
    const [prices, setPrices] = useState<IPriceGroupResponse>({
        prices: [],
        count: '0',
    });

    const [priceDataLoading, setPriceDataLoading] = useState(false);
    const changeTableContent = useCallback(
        () => {
            if (vehicleId === 0) {
                return
            }
            const config = currentConfig();
            setPriceDataLoading(true)
            fetch(config.apiUrl + `price_group_list/${vehicleId}`, {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
            })
                .then(response => response.json())
                .then(data => {
                    setPrices(data.response);
                })
                .catch(err => {
                    console.log('error:', err)
                })
                .finally(() => {
                    setTimeout(() => {
                        setPriceDataLoading(false)
                    }, 500)
                })
            setEditedRow(null)
        },
        [vehicleId],
    );


    const [settings, setSettings] = useState<ISettingsList>({
        stEcuSettings: [],
    });

    const fetchVehicles = () => {
        setExpectAnswer(true);
        const config = currentConfig();
        fetch(config.apiUrl + 'price_group_vehicles', {
            method: 'get',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
        })
            .then(response => response.json())
            .then((response: IVehiclesResponse) => {
                setExpectAnswer(false);
                setVehicles(response.response.vehicles);
            })
            .catch(err => {
                console.log('error:', err)
            })
    }

    const fetchSettings = useCallback(
        () => {
            if (vehicleId === 0) {
                return
            }

            const config = currentConfig();
            fetch(config.apiUrl + `price_group_settings/${vehicleId}`, {
                method: 'get',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
            })
                .then(response => response.json())
                .then((response: ISettingsResponse) => {
                    setSettings(response.response.settings);
                })
                .catch(err => {
                    console.log('error:', err)
                })
        },
        [vehicleId],
    );


    useEffect(() => {
        changeTableContent();
        fetchSettings();
    }, [vehicleId, changeTableContent, fetchSettings]);

    useEffect(() => {
        fetchVehicles();
    }, [])

    const handleVehicleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const vehicleId = event.target.value as number;
        setVehicleId(vehicleId);

        const row = vehicles.find(el => el.vehicle_ === vehicleId)
        setVehicle(row);
    };

    const [editedRow, setEditedRow] = useState<IPriceGroup | null>(null);
    const onEditRow = (price: IPriceGroup) => {
        if (!price.price_group_) {
            setEditedRow(null)
            return
        }
        setEditedRow(price)
    }

    const saved = () => {
        changeTableContent()
    }

    const cansel = () => {
        setEditedRow(null)
    }

    const changeHandle = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: keyof IVehicle) => {
        if (!vehicle) {
            return
        }
        setVehicle({
            ...vehicle,
            [key]: event.target.value
        })
    }

    const saveHandler = () => {
        if (!vehicle) {
            return
        }

        const config = currentConfig();
        const {vehicle_, ...data} = vehicle
        fetch(config.apiUrl + `update_vehicle/${vehicle_}`, {
            method: 'put',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(() => {
                fetchSettings()
            })
            .catch(err => {
                console.log('error:', err)
            })
    }

    return (
        <>
            <Layout noHeightConstraint>
                {expectAnswer && <Preloader pageCentered/>}
                {!expectAnswer && <>
                    <Card variant='outlined' className={classes.container}>
                        <Typography className={classes.title} variant='h4'>
                            Цены
                        </Typography>
                        <CardContent>
                            <FormControl className={classes.formControl}>
                                <Grid container spacing={2}>
                                    <Grid item xs={7}>
                                        <InputLabel id="vehicle-drop-down">Vehicle</InputLabel>
                                        <Select
                                            labelId="vehicle-drop-down"
                                            value={vehicleId}
                                            onChange={handleVehicleChange}
                                            className={classes.dropDown}
                                        >
                                            {vehicles.map(vehicle => (<MenuItem key={vehicle.vehicle_}
                                                                                value={vehicle.vehicle_}>{vehicle.name}</MenuItem>))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {vehicle && <TextField required label="Первое" type="number"
                                                               inputProps={{min: "0", step: "1"}}
                                                               value={vehicle.price_first} onChange={(e) => {
                                            changeHandle(e, 'price_first')
                                        }}/>}
                                    </Grid>
                                    <Grid item xs={1}>
                                        {vehicle && <TextField required label="Второе+" type="number"
                                                               inputProps={{min: "0", step: "1"}}
                                                               value={vehicle.price_second} onChange={(e) => {
                                            changeHandle(e, 'price_second')
                                        }}/>}
                                    </Grid>
                                    <Grid item xs={1}>
                                        {vehicle && <TextField required label="Adblue" type="number"
                                                               inputProps={{min: "0", step: "1"}}
                                                               value={vehicle.price_adblue} onChange={(e) => {
                                            changeHandle(e, 'price_adblue')
                                        }}/>}
                                    </Grid>
                                    <Grid item xs={1}>
                                        {vehicle && <TextField required label="DTC" type="number"
                                                               inputProps={{min: "0", step: "0.01"}}
                                                               value={vehicle.price_dtc} onChange={(e) => {
                                            changeHandle(e, 'price_dtc')
                                        }}/>}
                                    </Grid>
                                    <Grid item xs={1}>
                                        {vehicle &&
                                            <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                    onClick={saveHandler}>
                                                Save
                                            </Button>}
                                    </Grid>
                                </Grid>
                            </FormControl>
                            {!!vehicleId && (
                                <>
                                    {priceDataLoading && <CircularProgress/>}
                                    {!priceDataLoading && <>
                                        <PriceGroupTable
                                            prices={prices}
                                            changeTableContent={changeTableContent}
                                            editClick={onEditRow}
                                            editedRow={editedRow}
                                        />

                                        <PriceGroupEditor
                                            stEcuSettings={settings.stEcuSettings}
                                            vehicleId={vehicleId}
                                            saved={saved}
                                            cansel={cansel}
                                            editedRow={editedRow}
                                        />
                                    </>}
                                </>
                            )}
                        </CardContent>
                    </Card>
                </>}
                <ToastContainer/>
            </Layout>
        </>
    )
}

export default PriceGroup
