// outsource dependencies
import React from 'react';
// UI
import { makeStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
});

export default function CustomTextareaAutosize(props: any) {
  const classes = useStyles();
  const reduxProps = props.input || {};
  return <TextareaAutosize {...props} {...reduxProps} minRows={props.rowsMin || 3} className={classes.root} style={props.style || {}} />;
}
