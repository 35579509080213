import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from '@material-ui/core/TableBody';
import TableContainer from "@material-ui/core/TableContainer";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TelegramIcon from '@material-ui/icons/Telegram';
import CreateIcon from '@material-ui/icons/Create';
import BlockIcon from '@material-ui/icons/Block';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {IClient, IClientTableState} from "../index";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    TablePagination,
    Tooltip
} from "@material-ui/core";
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import ConfirmButton from "../../../components/button/ConfirmButton";
import CancelButton from "../../../components/button/CancelButton";
import { ThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles';
import currentConfig from "../../../utils/config.utils";

const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '16px 6px 16px 8px',
                fontSize: "0.82rem",
                letterSpacing: "0.01061em"
            },
        },
    },
});


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    row: {
        maxWidth: 250,
    },
    rowCenter: {
        textAlign: 'center',
    },
    status_1: {
        color: 'black',
        fontWeight: 'bold'
    },
    status_2: {
        color: 'red',
        fontWeight: 'bold'
    },
    status_3: {
        color: 'blue',
        fontWeight: 'bold'
    },
    status_4: {
        color: 'gray',
        fontWeight: 'bold'
    },
    archivedByDdmin: {
        opacity: 0.2
    },
    slaveEncoded: {
        color: 'red'
    },
    checkCircleIcon: {
        marginLeft: '5px',
        width: '16px',
        height: '16px',
        color: '#4caf50',
        marginBlock: '-3px'
    },
    telegramIcon: {
        marginLeft: '5px',
        width: '25px',
        height: '25px',
        color: '#115293',
        marginBlock: '-6px'
    },
    editIcon: {
        marginRight: '10px',
        "&:hover": {
            cursor: "pointer"
        }
    },
    blockUnblockIcon: {
        "&:hover": {
            cursor: "pointer"
        }
    }
});

interface IProps {
    clients: IClientTableState | null;
    page: number;
    changeTableContent: () => void;
}

const statusMap: { [index: string]: string } = {
    "1": "new",
    "2": "email confirmed",
    "3": "blocked"
}

const planMap: { [index: string]: number } = {
    "1": 0,
    "2": 5,
    "3": 10,
    "4": 15
}

function ClientsTable(props: IProps) {
    const {clients, page, changeTableContent} = props;
    const classes = useStyles();

    const [blockingUnblockingClient, setBlockingUnblockingClient] = useState<IClient | null>(null);

    const handleChangeClient = (id: string | number) => {
        window.open(window.location.origin + '/clients/edit/' + id, "_blank");
    };

    const handleBlockUnblockClient = (user: IClient) => {
        setBlockingUnblockingClient(user);
    };

    const handleCancelBlockUnblockClient = () => {
        setBlockingUnblockingClient(null);
    }

    const onChangePageHandle = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        let url = new URL(window.location.href);
        url.searchParams.set("page", (page + 1).toString());
        window.history.replaceState(null, "", url.href);
        changeTableContent()
    }

    const onSortTableHandle = (event: any, field: string) => {
        let url = new URL(window.location.href);

        let sortBy = url.searchParams.get("sort");
        let sortDirection = url.searchParams.get("direction");

        if (sortBy === null) {
            sortBy = "client_";
        }

        if (sortDirection === null) {
            sortDirection = "asc";
        }

        let changedUrl = new URL(window.location.href);
        if (sortBy !== field) {
            changedUrl.searchParams.set("sort", field);
            changedUrl.searchParams.set("direction", 'asc');
        } else {
            changedUrl.searchParams.set("sort", field);
            if (sortDirection !== "desc") {
                changedUrl.searchParams.set("direction", "desc");
            } else {
                changedUrl.searchParams.set("direction", "asc");
            }
        }

        changedUrl.searchParams.set("page", "1");

        window.history.replaceState(null, "", changedUrl.href);
        changeTableContent();
    }

    const sortIsActive = (field: string) => {
        let url = new URL(window.location.href);
        const sortBy = url.searchParams.get("sort");

        if (sortBy === null) {
            return field === "client_";
        } else {
            return sortBy === field;
        }
    }

    const isAscDirection = (field: string) => {
        let url = new URL(window.location.href);
        const sortBy = url.searchParams.get("sort");
        const sortDirection = url.searchParams.get("direction");
        if (sortBy === null || sortBy !== field) {
            return true;
        } else {
            return sortDirection !== 'desc';
        }
    }

    const handleBlockUnblockClientConfirm = () => {
        if (blockingUnblockingClient) {
            const config = currentConfig();
            fetch(config.apiUrl + 'client/'
                + (blockingUnblockingClient.status.toString() !== "3" ? "block" : "unblock"), {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                credentials: "include",
                body: JSON.stringify({
                    id: blockingUnblockingClient.client_
                })
            })
                .then(response => response.json())
                .then(() => {
                    setBlockingUnblockingClient(null);
                    changeTableContent();
                });
        }
    }

    return (
        <div>
            {clients ?
                (<Paper className={classes.root}>
                    <TableContainer>
                        <ThemeProvider theme={theme}>
                        <Table stickyHeader={true}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={(event) => onSortTableHandle(event, "client_")}
                                            active={sortIsActive("client_")} direction={isAscDirection("client_") ? "asc" : "desc"}>ID</TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={(event) => onSortTableHandle(event, "username")}
                                            active={sortIsActive("username")} direction={isAscDirection("username") ? "asc" : "desc"}>Имя пользователя</TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={(event) => onSortTableHandle(event, "first_name")}
                                            active={sortIsActive("first_name")} direction={isAscDirection("first_name") ? "asc" : "desc"}>Данные клиента</TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={(event) => onSortTableHandle(event, "email")}
                                            active={sortIsActive("email")} direction={isAscDirection("email") ? "asc" : "desc"}>Email/телефон</TableSortLabel></TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={(event) => onSortTableHandle(event, "status")}
                                            active={sortIsActive("status")} direction={isAscDirection("status") ? "asc" : "desc"}>Статус</TableSortLabel></TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={(event) => onSortTableHandle(event, "plan")}
                                            active={sortIsActive("plan")} direction={isAscDirection("plan") ? "asc" : "desc"}>План</TableSortLabel></TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={(event) => onSortTableHandle(event, "mms_key")}
                                            active={sortIsActive("mms_key")} direction={isAscDirection("mms_key") ? "asc" : "desc"}>MMS KEY</TableSortLabel></TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={(event) => onSortTableHandle(event, "bonus")}
                                            active={sortIsActive("bonus")} direction={isAscDirection("bonus") ? "asc" : "desc"}>Бонус</TableSortLabel></TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            onClick={(event) => onSortTableHandle(event, "create_date")}
                                            active={sortIsActive("create_date")} direction={isAscDirection("create_date") ? "asc" : "desc"}>Дата
                                            регистрации</TableSortLabel></TableCell>
                                    <TableCell>Действия</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clients?.clients?.map((client: IClient) =>
                                    <TableRow key={client.client_}>
                                        <TableCell>
                                            <div>{client.client_}</div>
                                        </TableCell>
                                        <TableCell>
                                            <span>{client.username}</span>
                                            {client.telegram_ ? <TelegramIcon className={classes.telegramIcon}/> : null}
                                        </TableCell>
                                        <TableCell>
                                            {client.last_name ? <div>Фамилия: {client.last_name}</div> : null}
                                            {client.first_name ? <div>Имя: {client.first_name}</div> : null}
                                            {client.company ? <div>Компания: {client.company}</div> : null}
                                        </TableCell>
                                        <TableCell>
                                            <span>{client.email}</span>
                                            {client.confirmation_hash === "confirmed" ?
                                                <CheckCircleIcon className={classes.checkCircleIcon}/> : null}
                                            {client.mobile_phone ? <div>{client.mobile_phone}</div> : null}
                                        </TableCell>
                                        <TableCell>
                                            <div>{statusMap[client.status]}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>{(planMap[client.plan] || client.plan) + "%"}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>{client.mms_key}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>{client.bonus}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div>{client.create_date}</div>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Редактировать" placement="top">
                                                <CreateIcon className={classes.editIcon}
                                                            onClick={() => handleChangeClient(client.client_)}/>
                                            </Tooltip>
                                            {client.status.toString() !== "3" ?
                                                (<Tooltip title="Блокировать" placement="top">
                                                    <BlockIcon className={classes.blockUnblockIcon}
                                                               onClick={() => handleBlockUnblockClient(client)}/>
                                                </Tooltip>)
                                                : (<Tooltip title="Разблокировать" placement="top">
                                                    <LockOpenOutlinedIcon className={classes.blockUnblockIcon}
                                                                          onClick={() => handleBlockUnblockClient(client)}/>
                                                </Tooltip>)
                                            }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        </ThemeProvider>
                    </TableContainer>

                    <TablePagination
                        component={"div"}
                        rowsPerPageOptions={[]}
                        count={clients ? clients.count : 0}
                        onPageChange={onChangePageHandle}
                        page={page}
                        rowsPerPage={20}/>

                    <Dialog open={blockingUnblockingClient !== null}>
                        <DialogContent>
                            <DialogContentText>
                                {blockingUnblockingClient ?
                                    ("Подтвердите " + (blockingUnblockingClient.status.toString() !== "3"
                                        ? "блокировку" : "разблокировку")
                                        + " клиента: " + blockingUnblockingClient.username)
                                    : ""}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions disableSpacing={true}>
                            <ConfirmButton style={{marginRight: "10px"}} onClick={handleBlockUnblockClientConfirm}>Подтвердить</ConfirmButton>
                            <CancelButton  onClick={handleCancelBlockUnblockClient}>Отменить</CancelButton>
                        </DialogActions>
                    </Dialog>
                </Paper>) : null}
        </div>
    )
}


export default ClientsTable;
