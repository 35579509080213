// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, InjectedFormProps } from 'redux-form';
// UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
// constants
import { APP_COLOR } from 'constants/style';
// local dependencies
import {
  selector as fwSoftSelector,
  // actions
  updateFwSoftDataAction,
  resetErrorEditAction,
  updateErrorEditAction,
  editSoftAction,
  // interface
  IFwEcu,
  IFwSoftGroup,
  IEditFormError,
  IEditForm,
  IFwSoft,
  // constants
  EDIT_FIELD_KEY,
} from 'private-layout/fw-soft/reducer';
// UI
import CustomTextField from 'components/input/TextField';
import CustomAutocomplete, {ICustomAutocompleteOption} from 'components/input/Autocomplete';
import Preloader from 'components/Preloader';
import CustomTextareaAutosize from 'components/input/Textarea';
// services
import ValidationService from 'services/validation.service';

// configurations
export const FORM_NAME = 'editFwSoftForm';

const useStyles = makeStyles({
  paperFullWidth: {
    width: 500,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 24px',
    backgroundColor: APP_COLOR.GREY,
    color: APP_COLOR.WHITE,
  },
  close: {
    padding: 6,
    color: APP_COLOR.WHITE,
    cursor: 'pointer',
  },
  titleContent: {
    lineHeight: 2,
  },
  content: {
    display: 'flex',
    padding: 12,
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  continueAction: {
    backgroundColor: APP_COLOR.LIGHT_GREY,
    color: APP_COLOR.WHITE,
    '&:hover': {
      color: APP_COLOR.BLACK,
    },
    transition: '0.6s',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
});

interface IProps {
  open: boolean;
  fwEcuList?: IFwEcu[] | null;
  fwSoftGroup?: IFwSoftGroup[] | null;

  editedFwSoftEcuId?: number | string;
  editedSoftFull?: string | number;
  editedSoftIdx?: string | number;
  editedFwSoftGroupId?: number | string;
  editedConfig?: string | number | null;

  expectFwSoftAnswer?: boolean;

  fwSoftEdit?: IFwSoft;

  editErrors?: IEditForm | null;
  // state action
  updateEditedFwSoftEcuId?: (editedFwSoftEcuId: string) => void;
  updateEditedSoftFull?: (editedSoftFull: string) => void;
  updateEditedSoftIdx?: (editedSoftIdx: string) => void;
  updateEditedFwSoftGroupId?: (editedFwSoftGroupId: string) => void;
  updateEditedConfig?: (editedConfig: string) => void;

  resetErrorEdit?: (fieldKey: string) => void;
  updateErrorEdit?: (editErrors: IEditFormError) => void;

  editSoft?: () => void;
  //
  handleClose: () => void;
  // redux
  handleSubmit?: any;
  validate?: any;
}

const CreateFwSoftDialog: React.FC<IProps & InjectedFormProps<{}, IProps>> = (props: IProps) => {
  const {
    open,
    // state
    fwEcuList,
    fwSoftGroup,
    expectFwSoftAnswer,
    editedFwSoftEcuId,
    editedSoftFull,
    editedSoftIdx,
    editedFwSoftGroupId,
    editedConfig,
    editErrors,
    //
    handleClose,
    // state action
    updateEditedFwSoftEcuId,
    updateEditedSoftFull,
    updateEditedSoftIdx,
    updateEditedFwSoftGroupId,
    updateEditedConfig,
    resetErrorEdit,
    updateErrorEdit,
    //
    editSoft,
    //
    handleSubmit,
    validate,
  } = props;
  const classes = useStyles();
  const isEditedFwSoftEcuId = Boolean(editErrors?.editedFwSoftEcuId);
  const isEditedSoftFull = Boolean(editErrors?.editedSoftFull);
  const isEditedSoftIdx = Boolean(editErrors?.editedSoftIdx);
  const editedFwSoftEcuIdError = typeof editErrors?.editedFwSoftEcuId === 'string' ? editErrors.editedFwSoftEcuId : '';
  const editedSoftFullError = typeof editErrors?.editedSoftFull === 'string' ? editErrors.editedSoftFull : '';
  const editedSoftIdxError = typeof editErrors?.editedSoftIdx === 'string' ? editErrors.editedSoftIdx : '';

  const isSubmitNotAvailable = editErrors && Boolean(Object.values(editErrors).filter((error) => Boolean(error)).length);

  const handleOnFocus = (fieldKey: string) => () => {
    const isErrorInState =
      editErrors && Object.keys(editErrors).some((errorKey: string, index) => Object.values(editErrors)[index] && fieldKey === errorKey);
    if (isErrorInState) {
      resetErrorEdit && resetErrorEdit(fieldKey);
    }
  };
  const handleNewSoftEcuId = (value: ICustomAutocompleteOption | null) => {
    const flattenValue = String(value?.key || '');
    updateEditedFwSoftEcuId && updateEditedFwSoftEcuId(flattenValue);
  };
  const handleEditedSoftFull = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateEditedSoftFull && updateEditedSoftFull(e.target.value);
  };
  const handleEditedSoftIdx = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateEditedSoftIdx && updateEditedSoftIdx(e.target.value);
  };
  const handleEditedFwSoftGroupId = (value: ICustomAutocompleteOption | null) => {
    const flattenValue = String(value?.key || '');
    updateEditedFwSoftGroupId && updateEditedFwSoftGroupId(flattenValue);
  };
  const handleEditedConfig = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateEditedConfig && updateEditedConfig(e.target.value);
  };
  const handleFormSubmit = () => {
    const localErrors =
      validate &&
      validate({
        editedFwSoftEcuId,
        editedSoftFull,
        editedSoftIdx,
        editedFwSoftGroupId,
        editedConfig,
      });
    if (!localErrors && !isSubmitNotAvailable) {
      editSoft && editSoft();
    }
    if (localErrors) {
      updateErrorEdit && updateErrorEdit(localErrors);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paperFullWidth: classes.paperFullWidth }} fullWidth maxWidth='md'>
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <Typography variant='h6' className={classes.titleContent}>
          Редактирование FW soft
        </Typography>
        <CloseIcon onClick={handleClose} className={classes.close} />
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <Form onSubmit={handleSubmit(handleFormSubmit)} className={classes.form}>
            {Array.isArray(fwEcuList) && (
                <CustomAutocomplete
                    options={fwEcuList}
                    onChange={handleNewSoftEcuId}
                    error={isEditedFwSoftEcuId}
                    onFocus={handleOnFocus(EDIT_FIELD_KEY.editedFwSoftEcuId)}
                    helperText={editedFwSoftEcuIdError}
                    label='New Fw Soft Ecu'
                    placeholder='New Fw Soft Ecu'/>
            )}
            <Field
              type='text'
              component={CustomTextField}
              name='editedSoftFull'
              placeholder='New Soft Full'
              label='New Soft Full'
              onChange={handleEditedSoftFull}
              error={isEditedSoftFull}
              onFocus={handleOnFocus(EDIT_FIELD_KEY.editedSoftFull)}
              helperText={editedSoftFullError}
            />
            <Field
              type='text'
              component={CustomTextField}
              name='editedSoftIdx'
              placeholder='New Soft Idx'
              label='New Soft Idx'
              onChange={handleEditedSoftIdx}
              error={isEditedSoftIdx}
              onFocus={handleOnFocus(EDIT_FIELD_KEY.editedSoftIdx)}
              helperText={editedSoftIdxError}
            />
            {Array.isArray(fwSoftGroup) && (
                <CustomAutocomplete
                    options={fwSoftGroup}
                    onChange={handleEditedFwSoftGroupId}
                    label='New Fw Soft Group'
                    placeholder='New Fw Soft Group'/>
            )}

            <Field type='text' component={CustomTextareaAutosize} name='editedConfig' placeholder='New Config' label='New Config' onChange={handleEditedConfig} />
          </Form>
          <div className={classes.actions}>
            {expectFwSoftAnswer && <Preloader />}
            {!expectFwSoftAnswer && (
              <Button onClick={handleFormSubmit} className={classes.continueAction} disabled={Boolean(isSubmitNotAvailable)}>
                Редактировать
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect(
  (state) => ({
    editedFwSoftEcuId: fwSoftSelector(state).editedFwSoftEcuId,
    editedSoftFull: fwSoftSelector(state).editedSoftFull,
    editedSoftIdx: fwSoftSelector(state).editedSoftIdx,
    editedFwSoftGroupId: fwSoftSelector(state).editedFwSoftGroupId,
    editedConfig: fwSoftSelector(state).editedConfig,
    editErrors: fwSoftSelector(state).editErrors,
    expectFwSoftAnswer: fwSoftSelector(state).expectFwSoftAnswer,
    initialValues: {
      editedFwSoftEcuId: fwSoftSelector(state).editedFwSoftEcuId,
      editedSoftFull: fwSoftSelector(state).editedSoftFull,
      editedSoftIdx: fwSoftSelector(state).editedSoftIdx,
      editedFwSoftGroupId: fwSoftSelector(state).editedFwSoftGroupId,
      editedConfig: fwSoftSelector(state).editedConfig,
    },
  }),
  (dispatch) => ({
    updateEditedFwSoftEcuId: (editedFwSoftEcuId: string) => dispatch(updateFwSoftDataAction({ editedFwSoftEcuId })),
    updateEditedSoftFull: (editedSoftFull: string) => dispatch(updateFwSoftDataAction({ editedSoftFull })),
    updateEditedSoftIdx: (editedSoftIdx: string) => dispatch(updateFwSoftDataAction({ editedSoftIdx })),
    updateEditedFwSoftGroupId: (editedFwSoftGroupId: string) => dispatch(updateFwSoftDataAction({ editedFwSoftGroupId })),
    updateEditedConfig: (editedConfig: string) => dispatch(updateFwSoftDataAction({ editedConfig })),

    resetErrorEdit: (fieldKey: string) => dispatch(resetErrorEditAction(fieldKey)),
    updateErrorEdit: (editErrors: IEditFormError) => dispatch(updateErrorEditAction(editErrors)),

    editSoft: () => dispatch(editSoftAction()),
  })
)(
  reduxForm<{}, IProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values: IEditForm): any => {
      const errors: IEditFormError = {
        editedFwSoftEcuId: null,
        editedSoftFull: null,
        editedSoftIdx: null,
        editedFwSoftGroupId: null,
        editedConfig: null,
      };
      const editedFwSoftEcuIdInvalid = !values.editedFwSoftEcuId ? 'Поле обязательно' : false;
      const editedSoftFullInvalid =
        typeof values.editedSoftFull === 'string' ? ValidationService.getStringInvalidReason(values.editedSoftFull) : 'Поле обязательно';
      const editedSoftIdxInvalid =
        typeof values.editedSoftIdx === 'string'
          ? ValidationService.getStringInvalidReason(values.editedSoftIdx, { minLength: 1, maxLength: 20, isRequired: true })
          : 'Поле обязательно';
      if (editedFwSoftEcuIdInvalid) {
        errors.editedFwSoftEcuId = editedFwSoftEcuIdInvalid;
      }
      if (editedSoftFullInvalid) {
        errors.editedSoftFull = editedSoftFullInvalid;
      }
      if (editedSoftIdxInvalid) {
        errors.editedSoftIdx = editedSoftIdxInvalid;
      }
      // if at least one error were found
      if (Object.values(errors).filter((error) => Boolean(error)).length) return errors;
      return null;
    },
  })(CreateFwSoftDialog)
);
