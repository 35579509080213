import React, {useEffect, useState} from 'react';
import currentConfig from "../../utils/config.utils";
import {toast} from "react-toastify";
import {
  CircularProgress, Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField
} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles({
  dialog: {
    width: '500px',
  },
  margin: {
    margin: '1rem 0',
  }
});

interface IKeys {
  key: string
  name: string
}

interface IProps {
  fileKey: string
  show: boolean
  onClose: (closed: boolean) => void
}

function CloseDialog({fileKey, show, onClose}: IProps) {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);

  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [newKey, setNewKey] = useState('');
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<IKeys[]>([]);
  const loading = openAutocomplete && options.length === 0;

  const [closeReason, setCloseReason] = useState('');

  const handleCloseReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCloseReason(event.target.value);
  };

  useEffect(() => {
    setOpenDialog(show)
    setOptions([])
    setSearch('')
    setNewKey('')
  }, [fileKey, show]);

  const onCloseHandler = () => {
    setOpenDialog(false)
    onClose(false)
  }

  const [closeReasonError, setCloseReasonError] = useState(false);
  const onOkHandler = () => {
    if (closeReason.length === 0) {
      setCloseReasonError(true)
      return;
    }

    const config = currentConfig();
    fetch(config.apiUrl + 'admin/close-task', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify({fileKey, closeReason, newKey})
    }).then(response => response.json())
      .then((data) => {
        if (data.result === 'success') {
          setOpenDialog(false)
          onClose(true)
          toast.success('Задача успешно закрыта')
        }
        if (data.result === 'error') {
          toast.error(data.errorMessage)
        }
      })
      .catch(err => {
        console.log('error:', err)
        toast.error(err);
      })
  }

  useEffect(() => {
    closeReason.length > 0 && setCloseReasonError(false)
  }, [closeReason]);


  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    if (search.length < 2) {
      return
    }
    const config = currentConfig();
    fetch(config.apiUrl + 'admin/file-keys', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify({fileKey, search})
    }).then(response => response.json())
      .then(data => {
        setOptions(data.response.data)
      })
      .catch(err => {
        console.log('error:', err)
        toast.error(err);
      })
  }, [search, fileKey]);


  return (
    <Dialog
      open={openDialog}
      onClose={onCloseHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Закрыть задачу?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className={classes.dialog}>
          <FormControl fullWidth className={classes.margin}>
            <TextField
              error={closeReasonError}
              label="Причина"
              multiline
              rows={4}
              variant="outlined"
              value={closeReason}
              onChange={handleCloseReasonChange}
            />
          </FormControl>

          <Autocomplete
            open={openAutocomplete}
            onOpen={() => {
              setOpenAutocomplete(true);
            }}
            onClose={() => {
              setOpenAutocomplete(false);
            }}
            onChange={(e, val: IKeys | null) => {
              setNewKey(val?.key || '')
            }}
            getOptionSelected={(option, value) => option.key === value.key}
            getOptionLabel={(option) => `${option.key} (${option.name})`}
            options={options}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ключ"
                variant="outlined"
                onChange={searchHandler}
                value={search}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />

        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.margin}>
        <Button onClick={onCloseHandler} color="primary" variant="contained">
          Отмена
        </Button>
        <Button onClick={onOkHandler} color="secondary" autoFocus  variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CloseDialog