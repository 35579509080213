import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from '@material-ui/core/TableBody';
import TableContainer from "@material-ui/core/TableContainer";
import {TableCell, ThemeProvider} from '@material-ui/core'
import {createTheme} from '@material-ui/core/styles';
import PriceGroupTableRow from "./PriceGroupTableRow";
import {IPriceGroupResponse} from "../index";
import {IPriceGroup} from "../types";

const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '16px 6px 16px 8px',
                fontSize: "0.82rem",
                letterSpacing: "0.01061em"
            },
        },
    },
});

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    row: {
        maxWidth: 250,
    },
    rowCenter: {
        textAlign: 'center',
    },
    status_1: {
        color: 'black',
        fontWeight: 'bold'
    },
    status_2: {
        color: 'red',
        fontWeight: 'bold'
    },
    status_3: {
        color: 'blue',
        fontWeight: 'bold'
    },
    status_4: {
        color: 'gray',
        fontWeight: 'bold'
    },
    archivedByDdmin: {
        opacity: 0.2
    },
    slaveEncoded: {
        color: 'red'
    },
    checkCircleIcon: {
        marginLeft: '5px',
        width: '16px',
        height: '16px',
        color: '#4caf50',
        marginBlock: '-3px'
    },
    telegramIcon: {
        marginLeft: '5px',
        width: '25px',
        height: '25px',
        color: '#115293',
        marginBlock: '-6px'
    },
    editIcon: {
        marginRight: '10px',
        "&:hover": {
            cursor: "pointer"
        }
    },
    blockUnblockIcon: {
        "&:hover": {
            cursor: "pointer"
        }
    }
});

interface IProps {
    prices: IPriceGroupResponse
    changeTableContent: () => void;
    editClick: (price: IPriceGroup) => void;
    editedRow: IPriceGroup | null
}

function PriceGroupTable({prices, changeTableContent, editClick, editedRow}: IProps) {
    const classes = useStyles();

    const [editedRowId, setEditedRowId] = useState(0);

    useEffect(() => {
        if (!editedRow) {
            setEditedRowId(0)
        }
    }, [editedRow]);


    const onEditRow = (price: IPriceGroup) => {
        if (!price.price_group_) {
            return
        }
        setEditedRowId(price.price_group_)
        editClick(price)
    }

    return (
        <div>
            {prices ?
                <Paper className={classes.root}>
                    <TableContainer>
                        <ThemeProvider theme={theme}>
                            <Table stickyHeader={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="30px">
                                            #
                                        </TableCell>
                                        <TableCell width="50%">
                                            Имя
                                        </TableCell>
                                        <TableCell>
                                            Первое
                                        </TableCell>
                                        <TableCell>
                                            Второе+
                                        </TableCell>
                                        <TableCell>
                                             AdBlue
                                        </TableCell>
                                        <TableCell>
                                            DTC
                                        </TableCell>
                                        <TableCell width="90px">
                                            -
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {prices.prices.map((price) => {
                                        return (
                                            <PriceGroupTableRow
                                                key={price.price_group_}
                                                changeTableContent={changeTableContent}
                                                config={price}
                                                isEditedRow={editedRowId === price.price_group_}
                                                editClick={onEditRow}
                                            />
                                        )
                                    })}

                                </TableBody>
                            </Table>
                        </ThemeProvider>
                    </TableContainer>
                </Paper> : null}
        </div>
    )
}


export default PriceGroupTable;
