// outsource dependencies
import { fork, takeLatest, put, delay, call } from 'redux-saga/effects';
// local dependencies
import {APP_TYPES, updateAppDataAction, updateAppExpectAnswerAction, userLogOutAction, userPingAction} from 'reducers';
// sagas
import publicSagas from 'public-layout/sagas';
import privateSagas from 'private-layout/sagas';
// services
import API from 'services/request.service';
import cookieStorage from 'services/storage.service';

function* appInitializeSaga() {
  try {
    const { data } = yield call<any>(API, {
      url: '/init',
      method: 'GET',
    });
    const email = data?.response?.email || null;
    const username = data?.response?.username || null;
    const role = data?.response?.role || null;
    if (Boolean(email) && Boolean(username)) {
      yield put(updateAppDataAction({ initialized: true, user: { email, username, role } }));
      while (true) {
        yield put(userPingAction());
        yield delay(60 * 1000);
      }
    } else {
      const {pathname, search} = new URL(window.location.href);
      if (pathname !== '/log-in') {
        localStorage.setItem('deep_link', `${pathname}${search}`);
      }
      yield put(updateAppDataAction({ initialized: true }));
    }
  } catch ({ message }) {
    console.error('appInitializeSaga error: ', message);
    yield put(updateAppDataAction({ initialized: true }));
  }
}

function* logOutSaga() {
  yield cookieStorage.removeItem('connect.sid');
  yield put(updateAppDataAction({ user: null }));
  yield call<any>(API, {
    url: '/admin/logout',
    method: 'POST',
  });
  window.location.reload();
}

function* pingSaga() {
  try {
    yield call<any>(API, {
      url: '/admin/ping',
      method: 'POST',
    });
  } catch (e) {
    console.error(e);
    yield put(userLogOutAction());
  }
}

function* navigationSaga() {
  yield put(updateAppExpectAnswerAction(true));
  yield delay(300); // ms
  yield put(updateAppExpectAnswerAction(false));
}

function* appSagas() {
  yield takeLatest(APP_TYPES.INITIALIZE, appInitializeSaga);
  yield takeLatest(APP_TYPES.LOG_OUT, logOutSaga);
  yield takeLatest(APP_TYPES.PING, pingSaga);
  yield takeLatest(APP_TYPES.NAVIGATE, navigationSaga);
}

export function* sagas() {
  yield fork(appSagas);
  yield fork(publicSagas);
  yield fork(privateSagas);
}

export default sagas;
