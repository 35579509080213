// outsource dependencies
import { fork, takeLatest, call, put, all, select } from 'redux-saga/effects';
// local dependencies
import {
  TYPE,
  selector as clientRequestFileSelector,
  // action loading
  updateExpectAnswerAction,
  updateExpectFilesAnswerAction,
  updateExpectUploadFileAnswerAction,
  // action
  updateClientFilesAction,
  updateTotalFilesCountAction,
  // upload
  updateUploadFinishedAction,
  updateUploadSuccessAction,
  updateUploadErrorAction,
  // return type
  loadFilesAction,
  uploadFileAction,
  archiveFileAction,
  // interface
  IClientFile,
  // constants
  DefaultTableOptions,
  updateFilesStatusAction,
  updateFilesInfoAction,
  updateHiddenOnlyAction,
  updateSortAction,
  updateDirectionAction,
} from 'private-layout/client-request-files/reducer';
import API, { API_FORM_DATA } from 'services/request.service';

function* loadClientRequestFilesSaga({ payload }: ReturnType<typeof loadFilesAction>) {
  const { filesStatus,  fileInfo, hiddenOnly, sort, direction } = yield select(clientRequestFileSelector);
  const { count, page} = payload;
  const postData = { count, page, fileInfo, status: DefaultTableOptions.DEFAULT_FILES_STATUS, hidden: hiddenOnly, sort, direction};
  if (Array.isArray(filesStatus) && Boolean(filesStatus.length)) {
    postData.status = filesStatus;
  }
  yield put(updateExpectFilesAnswerAction(true));
  try {
    const { data } = yield call<any>(API, {
      url: '/admin/client-request-file',
      method: 'POST',
      data: postData,
    });
    let clientFiles = data?.response?.clientRequestFiles || null;
    clientFiles = Array.isArray(clientFiles)
      ? clientFiles.map((file: IClientFile) => ({ ...file, dtc: Boolean(file.dtc) ? { data: file.dtc, show: false } : file.dtc }))
      : clientFiles;
    const filesCount = data?.response?.filesCount || null;
    yield all([put(updateExpectFilesAnswerAction(false)), put(updateClientFilesAction(clientFiles)), put(updateTotalFilesCountAction(filesCount))]);
  } catch (e) {
    yield put(updateExpectFilesAnswerAction(false));
  }
}

function* archiveClientFileSaga({ payload }: ReturnType<typeof archiveFileAction>) {
  const { fileKey } = payload;
  const postData = {fileKey: fileKey, isArchived: true};
  try {
    yield call<any>(API, {
      url: '/admin/client-request-file/archive/',
      method: 'POST',
      data: postData,
    });
  } catch (e) {}
}

function* uploadFileSaga({ payload }: ReturnType<typeof uploadFileAction>) {
  const { notifyClient, approvePayment, comment } = payload;
  const { uploadedFile, currentFileKey } = yield select(clientRequestFileSelector);
  const formData = new FormData();
  formData.append('fileKey', currentFileKey);
  formData.append('uploadedFile', uploadedFile);
  formData.append('notifyClient', notifyClient);
  formData.append('approvePayment', approvePayment);
  formData.append('comment', comment);
  yield put(updateExpectUploadFileAnswerAction(true));
  try {
    yield call<any>(API_FORM_DATA, {
      url: '/admin/request-file/upload',
      method: 'POST',
      data: formData,
    });
    yield all([put(updateExpectUploadFileAnswerAction(false)), put(updateUploadFinishedAction(true)), put(updateUploadSuccessAction(true))]);

    // @ts-ignore
    const state = yield select();

    const clientFiles = [...state.clientRequestFiles.clientFiles]
    const index = clientFiles.findIndex(value => value.key === currentFileKey)
    clientFiles[index] = {
      ...clientFiles[index],
      status: "4",
    }

    yield put(updateClientFilesAction(clientFiles));
  } catch (e) {
    yield all([put(updateExpectUploadFileAnswerAction(false)), put(updateUploadFinishedAction(true)), put(updateUploadErrorAction(true))]);
  }
}

function* initializeRequestFilesSaga() {
  const initUrl = new URL(window.location.href);
  const search = initUrl.searchParams.get("search") || ''
  const status = initUrl.searchParams.get("status")
  const hidden = initUrl.searchParams.get("hidden") === '1'
  const sort = initUrl.searchParams.get("sort") || 'updateDate'
  const direction = initUrl.searchParams.get("direction") || 'desc'

  yield put(updateFilesStatusAction(status ? Number(status) : DefaultTableOptions.DEFAULT_FILES_STATUS[0]));
  yield put(updateFilesInfoAction(search));
  yield put(updateHiddenOnlyAction(hidden));
  yield put(updateSortAction(sort));
  yield put(updateDirectionAction(direction));
  yield put(updateExpectAnswerAction(true));
  try {
    const { data } = yield call<any>(API, {
      url: '/admin/client-request-file',
      method: 'POST',
      data: {
        count: DefaultTableOptions.DEFAULT_FILES_COUNT,
        page: DefaultTableOptions.DEFAULT_PAGE,
        status: status ? [status] : DefaultTableOptions.DEFAULT_FILES_STATUS,
        fileInfo: search,
        hidden: hidden,
        sort,
        direction,
      },
    });
    let clientFiles = data?.response?.clientRequestFiles || null;
    clientFiles = Array.isArray(clientFiles)
      ? clientFiles.map((file: IClientFile) => ({ ...file, dtc: Boolean(file.dtc) ? { data: file.dtc, show: false } : file.dtc }))
      : clientFiles;
    const filesCount = data?.response?.filesCount || null;
    yield all([put(updateExpectAnswerAction(false)), put(updateClientFilesAction(clientFiles)), put(updateTotalFilesCountAction(filesCount))]);
  } catch (e) {
    yield put(updateExpectAnswerAction(false));
  }
}
/**
 * connect page sagas
 *
 * @private
 */
function* activityTasks() {
  yield takeLatest(TYPE.LOAD_FILES, loadClientRequestFilesSaga);
  yield takeLatest(TYPE.UPLOAD_FILE, uploadFileSaga);
  yield takeLatest(TYPE.INITIALIZE_FILES, initializeRequestFilesSaga);
  yield takeLatest(TYPE.ARCHIVE_FILE, archiveClientFileSaga);
}

export function* sagas() {
  yield fork(activityTasks);
}

export default sagas;
