import React, {useEffect, useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TableCell,
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import currentConfig from "../../../utils/config.utils";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ConfirmButton from "../../../components/button/ConfirmButton";
import CancelButton from "../../../components/button/CancelButton";
import {makeStyles} from "@material-ui/core/styles";
import {IPriceGroup} from "../types";

interface IProps {
    config: IPriceGroup;
    changeTableContent: () => void;
    editClick: (item: IPriceGroup) => void;
    isEditedRow: boolean;
}

const useStyles = makeStyles({
    deleteIcon: {
        marginLeft: "20px",
        cursor: "pointer"
    },
    editedRow: {
        backgroundColor: '#8cfe95!important',
    }
});

const PriceGroupTableRow = ({config, changeTableContent, editClick, isEditedRow = false}: IProps) => {
    const classes = useStyles();

    const [configState, setConfigState] = useState<IPriceGroup>();
    const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false);

    const onSaveClickHandle = () => {
        editClick(config);
    }

    const removePriceList = () => {
        if (!configState) {
            return
        }
        const config = currentConfig();
        fetch(config.apiUrl + `delete_price_group/${configState.price_group_}`, {
            method: 'delete',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
        })
            .then(response => response.json())
            .then(() => {
                changeTableContent();
            })
            .catch(err => {
                console.log('error:', err)
            })
    }

    useEffect(() => {
        setConfigState({...config});
    }, [config])


    return (
        <TableRow className={isEditedRow ? classes.editedRow : ''}>
            <TableCell>
                {configState?.price_group_}
            </TableCell>
            <TableCell>
                {configState?.name}
            </TableCell>
            <TableCell>
                {configState?.coef_first}
            </TableCell>
            <TableCell>
                {configState?.coef_second}
            </TableCell>
            <TableCell>
                {configState?.coef_adblue}
            </TableCell>
            <TableCell>
                {configState?.coef_dtc}
            </TableCell>
            <TableCell>
                <EditIcon onClick={onSaveClickHandle} style={{cursor: "pointer"}} color={'primary'}/>
                <RemoveCircleOutlineIcon className={classes.deleteIcon} color={"secondary"}
                                         onClick={() => setOpenRemoveDialog(true)}/>
            </TableCell>

            <Dialog open={openRemoveDialog}>
                <DialogTitle>Подтвердите действие</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы действительно хотите удалить обьект?
                    </DialogContentText>
                </DialogContent>
                <DialogActions disableSpacing={true}>
                    <ConfirmButton onClick={removePriceList}>Удалить</ConfirmButton>
                    <CancelButton style={{marginLeft: "20px"}}
                                  onClick={() => setOpenRemoveDialog(false)}>Отменить</CancelButton>
                </DialogActions>
            </Dialog>
        </TableRow>
    )
}

export default PriceGroupTableRow;
