import React, {useEffect, useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Input,
    TableCell,
} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import currentConfig from "../../../utils/config.utils";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ConfirmButton from "../../../components/button/ConfirmButton";
import CancelButton from "../../../components/button/CancelButton";
import {makeStyles} from "@material-ui/core/styles";
import {IActionBrandForShow} from "../types";

interface IProps {
    action: IActionBrandForShow;
    changeTableContent: () => void;
}

const useStyles = makeStyles({
    deleteIcon: {
        marginLeft: "20px",
        cursor: "pointer"
    }
});

const StActionBrandEcuTableRow = ({action, changeTableContent}: IProps) => {
    const classes = useStyles();

    const [actionState, setActionState] = useState<IActionBrandForShow>(action);
    const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false);


    const isEditedRow = () => {
        return JSON.stringify(actionState) !== JSON.stringify(action);
    }

    const changeHandleAuto = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActionState({
            ...actionState,
            auto: event.target.checked
        })
    }

    const changeHandle = (key: 'config' | 'info' | 'key', event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setActionState({
            ...actionState,
            [key]: event.target.value
        })
    }

    const onSaveClickHandle = () => {
        if (!actionState) {
            return
        }
        if (isEditedRow()) {
            const config = currentConfig();
            fetch(config.apiUrl + `update_st_action_brand_ecu/${actionState.st_action_}/${actionState.st_brand_}/${actionState.st_ecu_}`, {
                method: 'put',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify({...actionState})
            })
                .then(response => response.json())
                .then(() => {
                    changeTableContent();
                })
                .catch(err => {
                    console.log('error:', err)
                })

        }
    }

    const removeStActionBrandEcu = () => {
        const config = currentConfig();
        fetch(config.apiUrl + `delete_st_action_brand_ecu/${actionState.st_action_}/${actionState.st_brand_}/${actionState.st_ecu_}`, {
            method: 'delete',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
        })
            .then(response => response.json())
            .then(() => {
                changeTableContent();
            })
            .catch(err => {
                console.log('error:', err)
            })
    }

    useEffect(() => {
        setActionState(JSON.parse(JSON.stringify(action)));
    }, [action])


    return (
        <TableRow>
            <TableCell>
                {actionState?.st_ecu_name}
            </TableCell>
            <TableCell>
                {actionState?.st_action_name}
            </TableCell>
            <TableCell>
                {actionState?.st_brand_name}
            </TableCell>
            <TableCell>
                <Input onChange={(e) => changeHandle('info', e)} value={actionState?.info || ''}/>
            </TableCell>
            <TableCell>
                <Input readOnly={true} onChange={(e) => changeHandle('key', e)} value={actionState?.key || ''}/>
            </TableCell>
            <TableCell>
                <Checkbox checked={actionState?.auto} onChange={(e) => changeHandleAuto(e)}/>
            </TableCell>
            <TableCell>
                <Input onChange={(e) => changeHandle('config', e)} value={actionState?.config || ''}/>
            </TableCell>
            <TableCell>
                <SaveIcon onClick={onSaveClickHandle} style={isEditedRow() ? {cursor: "pointer"} : {cursor: "auto"}}
                          color={isEditedRow() ? 'primary' : 'disabled'}/>
                <RemoveCircleOutlineIcon className={classes.deleteIcon} color={"secondary"}
                                         onClick={() => setOpenRemoveDialog(true)}/>
            </TableCell>

            <Dialog open={openRemoveDialog}>
                <DialogTitle>Подтвердите действие</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы действительно хотите удалить обьект?
                    </DialogContentText>
                </DialogContent>
                <DialogActions disableSpacing={true}>
                    <ConfirmButton onClick={removeStActionBrandEcu}>Удалить</ConfirmButton>
                    <CancelButton style={{marginLeft: "20px"}}
                                  onClick={() => setOpenRemoveDialog(false)}>Отменить</CancelButton>
                </DialogActions>
            </Dialog>
        </TableRow>
    )
}

export default StActionBrandEcuTableRow;
