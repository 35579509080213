import React, {useEffect, useState} from 'react';
import Preloader from "../../components/Preloader";
import Layout from "../../components/layout/Common";
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import currentConfig from "../../utils/config.utils";
import StActionTable from "./components/StActionTable";

const useStyles = makeStyles({
    container: {
        minHeight: '80vh',
    },
    title: {
        padding: '16px 0 0 16px'
    }
});

function StAction() {
    const classes = useStyles();
    const [expectAnswer, setExpectAnswer] = useState<boolean>(false);
    const [actions, setActions] = useState<any>(null);

    const changeTableContent = () => {
        setExpectAnswer(true);
        const config = currentConfig();

        fetch(config.apiUrl + 'st_action_list', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
        })
            .then(response => response.json())
            .then(data => {
                setActions(data.response);
                setExpectAnswer(false);
            })
            .catch(err => {
                console.log('error:', err)
            })

    }

    useEffect(() => {
        changeTableContent();
    }, []);


    return (
        <>
            <Layout noHeightConstraint>
                {expectAnswer && <Preloader pageCentered/>}
                {!expectAnswer && (
                    <Card variant='outlined' className={classes.container}>
                        <Typography className={classes.title} variant='h4'>
                            st_action
                        </Typography>
                        <CardContent>
                            <StActionTable changeTableContent={changeTableContent} actions={actions}/>
                        </CardContent>
                    </Card>
                )}
            </Layout>
        </>);

}

export default StAction;
