// outsource dependencies
import React from 'react';
// UI
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
// local dependencies
import {
  // constants
  DefaultTableOptions,
  // interface
  IDtc,
} from 'private-layout/client-request-files/reducer';

const useStyles = makeStyles({
  container: {
    padding: 15,
    maxWidth: 250,
    maxHeight: 100,
    margin: '8px 0 8px 1em',
    overflowY: 'auto',
  },
});

interface IProps {
  dtc: IDtc | null;
  fileKey: string;
  handleClick?: (fileKey: string) => void;
}

const FileDtc = (props: IProps) => {
  const { dtc, fileKey, handleClick } = props;
  const classes = useStyles();
  if (Number(dtc?.data?.length) > DefaultTableOptions.DEFAULT_DTC_LENGTH) {
    if (!dtc?.show)
      return (
        <Tooltip title='Развернуть' arrow placement='right'>
          <Link component='button' onClick={() => handleClick && handleClick(fileKey)}>
            {(dtc?.data || '').length > DefaultTableOptions.DEFAULT_DTC_LENGTH
              ? ((dtc?.data || '').substring(DefaultTableOptions.DEFAULT_DTC_LENGTH) + '...')
              : (dtc?.data || '')}
          </Link>
        </Tooltip>
      );
    return (
      <div>
        <Paper elevation={3} className={classes.container}>
          {dtc?.data}
        </Paper>
        <br />
        <Link component='button' onClick={() => handleClick && handleClick(fileKey)}>
          Свернуть
        </Link>
      </div>
    );
  }
  return <span>{dtc?.data}</span>;
};

export default FileDtc;
