import React, {useEffect, useState} from "react";
import ConfirmButton from "../../../components/button/ConfirmButton";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Input,
    TextField,
    Typography
} from "@material-ui/core";
import CancelButton from "../../../components/button/CancelButton";
import currentConfig from "../../../utils/config.utils";
import {makeStyles} from "@material-ui/core/styles";
import {IActionBrand, ISettings} from "../types";
import CustomAutocomplete, {ICustomAutocompleteOption} from "../../../components/input/Autocomplete";

interface IProps {
    actionSettings: ISettings | null
    changeTableContent: () => void
}

interface IFilter {
    st_ecu_?: string | null
    st_action_?: string | null
    st_brand_?: string | null
    search?: string | null
}

const useStyles = makeStyles({
    headerActions: {
        marginLeft: "20px",
        display: "flex",
        columnGap: "20px"
    },
    inputContainer: {
        padding: 8,
        minWidth: 140,
    },
    filterInputContainer: {
        padding: 8,
        minWidth: 230,
    }
});

const StActionBrandEcuHeader = ({changeTableContent, actionSettings}: IProps) => {
    const classes = useStyles();

    const [openNewAction, setOpenNewAction] = useState<boolean>(false);
    const [newAction, setNewAction] = useState<IActionBrand>({
        st_action_: 0,
        st_ecu_: 0,
        st_brand_: 0,
        auto: false,
        config: '',
        info: '',
        key: '',
    });

    const [filterData, setFilterData] = useState<IFilter>({
        st_ecu_: "",
        st_action_: "",
        st_brand_: "",
        search: "",
    })

    const [headerReady, setHeaderReady] = useState(false);
    const [defaultStEcu, setDefaultStEcu] = useState('');
    const [defaultStAction, setDefaultStAction] = useState('');
    const [defaultStBrand, setDefaultStBrand] = useState('');
    useEffect(() => {
        const initUrl = new URL(window.location.href);

        const data = {
            st_ecu_: initUrl.searchParams.get("st_ecu_") || '',
            st_action_: initUrl.searchParams.get("st_action_") || '',
            st_brand_: initUrl.searchParams.get("st_brand_") || '',
            search: initUrl.searchParams.get("search") || '',
        }
        setFilterData(data)
        setDefaultStEcu(data.st_ecu_)
        setDefaultStAction(data.st_action_)
        setDefaultStBrand(data.st_brand_)
        setNewAction({
            st_action_: 0,
            st_ecu_: 0,
            st_brand_: 0,
            auto: false,
            config: '',
            info: '',
            key: '',
        })
    }, []);

    useEffect(() => {
        setHeaderReady(true)
    }, [defaultStEcu, defaultStAction, defaultStBrand]);


    const changeFilterSearchHandle = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilterData({
            ...filterData,
            search: event.target.value
        })
    }

    const changeFilterDataHandle = (value: ICustomAutocompleteOption['key'] | undefined, field: string) => {
        setFilterData({
            ...filterData,
            [field]: value || '',
        })
    }

    const isBtnDisabled = () => {
        return newAction?.st_ecu_ === 0
            || newAction?.st_action_ === 0
            || newAction?.st_brand_ === 0
    }

    const changeNewActionAutoHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewAction({
            ...newAction,
            auto: event.target.checked
        })
    }

    const changeNewActionHandle = (value: ICustomAutocompleteOption['key'] | undefined, field: keyof IActionBrand) => {
        setNewAction({
            ...newAction,
            [field]: value || ''
        })
    }

    const handleOnClickFilter = () => {
        let url = new URL(window.location.href);
        url.searchParams.set("page", "1");
        url.searchParams.set("st_ecu_", filterData.st_ecu_ || '');
        url.searchParams.set("st_action_", filterData.st_action_ || '');
        url.searchParams.set("st_brand_", filterData.st_brand_ || '');
        url.searchParams.set("search", filterData.search || '');

        window.history.replaceState(null, "", url.href);
        changeTableContent();
    }

    const handleOnClickCancelFilter = () => {
        let url = new URL(window.location.href);
        url.searchParams.set("page", "1");
        url.searchParams.set("st_ecu_", "");
        url.searchParams.set("st_action_", "");
        url.searchParams.set("st_brand_", "");
        url.searchParams.set("search", "");

        window.history.replaceState(null, "", url.href);

        setFilterData({
            st_ecu_: "",
            st_action_: "",
            st_brand_: "",
            search: "",
        })

        changeTableContent();
    }

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            handleOnClickFilter();
        }
    }

    const openNewFwEcuConfigDialogHandle = () => {
        setOpenNewAction(true)
    }

    const closeNewFwEcuConfigDialogHandle = () => {
        setNewAction({
            st_ecu_: 0,
            st_action_: 0,
            st_brand_: 0,
            info: '',
            key: '',
            config: '',
            auto: false
        })

        setOpenNewAction(false);
    }

    const addNewItemHandle = () => {
        const config = currentConfig();
        fetch(config.apiUrl + 'st_action_brand_ecu', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                ...newAction
            })
        })
            .then(response => response.json())
            .then(() => {
                closeNewFwEcuConfigDialogHandle();
                changeTableContent();
            })
            .catch(err => {
                console.log('error:', err)
            })
    }

    return (
        <div>
            {headerReady &&
                <div className={classes.headerActions}>
                    <div className={classes.filterInputContainer}>
                        <CustomAutocomplete
                            input={{value: defaultStEcu}}
                            options={(actionSettings?.stEcuSettings.map(({id, name}) => ({key: id, name})) || [])}
                            onChange={(event) => changeFilterDataHandle(event?.key, "st_ecu_")}
                            label='ST ECU'
                            placeholder='ST ECU'/>
                    </div>

                    <div className={classes.filterInputContainer}>
                        <CustomAutocomplete
                            input={{value: defaultStAction}}
                            options={(actionSettings?.stActionSettings.map(({id, name}) => ({key: id, name})) || [])}
                            onChange={(event) => changeFilterDataHandle(event?.key, "st_action_")}
                            label='St Action'
                            placeholder='St Action'/>
                    </div>

                    <div className={classes.filterInputContainer}>
                        <CustomAutocomplete
                            input={{value: defaultStBrand}}
                            options={(actionSettings?.stBrandSettings.map(({id, name}) => ({key: id, name})) || [])}
                            onChange={(event) => changeFilterDataHandle(event?.key, "st_brand_")}
                            label='St Brand'
                            placeholder='St Brand'/>
                    </div>

                    <div className={classes.inputContainer}>
                        <TextField onKeyDown={handleKeyDown}
                                   onChange={changeFilterSearchHandle}
                                   value={filterData.search} label="Search"/>
                    </div>

                    <div className={classes.inputContainer}>
                        <ConfirmButton onClick={handleOnClickFilter} style={{width:'120px'}}>Фильтовать</ConfirmButton>&nbsp;
                        <CancelButton onClick={handleOnClickCancelFilter} style={{width:'120px'}}>Сбросить</CancelButton>&nbsp;
                        <ConfirmButton onClick={openNewFwEcuConfigDialogHandle} style={{width:'120px'}}>New</ConfirmButton>
                    </div>
                </div>
            }

            <Dialog open={openNewAction}>
                <DialogTitle>Новый St action brand ECU</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Заполните поля:
                    </DialogContentText>
                    <div>
                        <CustomAutocomplete
                            options={(actionSettings?.stEcuSettings.map(({id, name}) => ({key: id, name})) || [])}
                            onChange={(value) => changeNewActionHandle(value?.key, "st_ecu_")}
                            label='ST ECU'
                            placeholder='ST ECU'/>

                        <CustomAutocomplete
                            options={(actionSettings?.stActionSettings.map(({id, name}) => ({key: id, name})) || [])}
                            onChange={(value) => changeNewActionHandle(value?.key, "st_action_")}
                            label='ST Action'
                            placeholder='ST Action'/>

                        <CustomAutocomplete
                            options={(actionSettings?.stBrandSettings.map(({id, name}) => ({key: id, name})) || [])}
                            onChange={(value) => changeNewActionHandle(value?.key, "st_brand_")}
                            label='ST Brand'
                            placeholder='ST Brand'/>

                        <Typography style={{marginTop: '20px'}}>Info</Typography>
                        <TextField onChange={(event) => changeNewActionHandle(event.target.value, 'info')}
                                   value={newAction?.info} fullWidth={true} multiline={true}/>

                        <Typography>Config</Typography>
                        <Input onChange={(event) => changeNewActionHandle(event.target.value, 'config')}
                               value={newAction?.config}/>

                        <Typography>Auto</Typography>
                        <Checkbox checked={newAction?.auto} onChange={(e) => changeNewActionAutoHandle(e)}/>
                    </div>
                </DialogContent>
                <DialogActions disableSpacing={true}>
                    <ConfirmButton disabled={isBtnDisabled()} onClick={addNewItemHandle}>Создать</ConfirmButton>
                    <CancelButton style={{marginLeft: "20px"}}
                                  onClick={closeNewFwEcuConfigDialogHandle}>Отменить</CancelButton>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default React.memo(StActionBrandEcuHeader, (prev, next) => {
    return JSON.stringify(prev.actionSettings) === JSON.stringify(next.actionSettings)
});


