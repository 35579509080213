import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Layout from 'components/layout/Common';
import {Card, Typography} from "@material-ui/core";
import { Link } from 'react-router-dom';

import {useParams} from "react-router";
import Preloader from "../../components/Preloader";
import currentConfig from "../../utils/config.utils";
import Messages from "./components/Messages";

const useStyles = makeStyles({
    container: {
        minHeight: '80vh',
        fontFamily: 'Roboto,Helvetica,Arial,sans-serif'
    },
    title: {
        padding: '16px 0 0 16px'
    },
    fileInfoContainer: {
        display: "grid",
        gridTemplateColumns: "min-content auto",
        gridColumnGap: "50px",
        marginLeft: "50px",
        marginTop: "20px",
        marginBottom: "20px",
        whiteSpace: "nowrap",
    },
    messageContainer: {
        marginLeft: "50px",
        borderBottom: "1px solid black",
        marginBottom: "20px",
    },
    messageHeader: {
        marginTop: "20px",
        display: "flex",
        justifyContent: "space-between",
    },
    messageHeaderComponent: {
        display: "grid",
        gridTemplateColumns: "min-content auto",
        gridColumnGap: "20px",
        marginBottom: "15px",
    },
    message: {
        marginTop: "20px",
        marginBottom: "30px",
    },
    dtcContainer: {
        display: "grid",
        gridTemplateColumns: "min-content auto",
        gridColumnGap: "20px",
        marginBottom: "15px",
    },
    attachmentContainer: {
        display: "none",
        gridTemplateColumns: "min-content auto",
        gridColumnGap: "20px",
        marginBottom: "15px",
    },
    inputContainer: {
        marginLeft: "50px",
    },
    textInputField: {
        minWidth: "100%",
    },
    dtcInputField: {
        minWidth: "100%",
    },
    textLabel: {
        marginTop: "20px",
        marginBottom: "10px",
    },
    dtcLabel: {
        marginTop: "20px",
        marginBottom: "10px",
    },
    fileHeader: {
        marginTop: "20px",
        display: "none",
        gridTemplateColumns: "min-content auto",
        gridColumnGap: "20px",
    },
    fileContent: {
        marginTop: "20px",
        display: "grid",
        gridTemplateColumns: "min-content auto",
        gridColumnGap: "20px",
    },
    selectedFileLabel: {
        whiteSpace: "nowrap",
    },
    openFileBtn: {
        maxWidth: "100px"
    },
    sendBtnContainer: {
        display: "flex",
        justifyContent: "end",
    },
    sendBtn: {
        width: "200px",
        color: '#000000',
        backgroundColor: "gray",
    },
    preloaderContainer: {
        marginLeft: "20px",
        marginRight: "20px",
    },
    uploadFile: {
        marginTop: 16,
        minWidth: 150,
    },
    hideUploader: {
        visibility: 'hidden',
        position: 'absolute',
    },
    uploaderBtn: {
        backgroundColor: "#3f51b5",
        color: "white",
        padding: 8,
        cursor: 'pointer',
        width: '150px',
        textAlign: 'center',
        borderRadius: 4,
        marginRight: 8,
        '&:hover': {
            backgroundColor: "#263dbd",
        },
    },
    uploader: {
        display: 'flex',
        alignItems: 'center',
    },
    dtcTextInput: {
        width: "100%",
    }
});

function Ticket() {
    const {key} = useParams<{key: string}>();

    const classes = useStyles();

    const [expectAnswerSendMessage, setExpectAnswerSendMessage] = useState<boolean>(false);
    const [fileData, setFileData] = useState<any>(null);


    const loadFileData = (key: string | null) => {
        setExpectAnswerSendMessage(true)
        const config = currentConfig();
        fetch(config.apiUrl + 'ticket/loadFileData', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                fileKey: key
            })
        }).then(response => response.json())
            .then(data => {
                setExpectAnswerSendMessage(false)
                setFileData(data.response.fileData);
            });
    }

    useEffect(() => {
        loadFileData(key);
    }, [key]);

    return (
        <Layout noHeightConstraint>
            {expectAnswerSendMessage && <Preloader pageCentered />}
            {!expectAnswerSendMessage && (
            <Card variant='outlined' className={classes.container}>
                <Typography className={classes.title} variant='h4'>
                    Ticket
                </Typography>
                    {fileData ?
                        <div className={classes.fileInfoContainer}>
                            <Typography>
                                <b>Файл</b>
                            </Typography>
                            <Typography>
                                <Link to={`/client-request-files?search=${fileData?.key}&status=0`}>
                                    {fileData?.key}
                                </Link>
                            </Typography>
                            <Typography>
                                <b>Дата</b>
                            </Typography>
                            <Typography>
                                {new Date((fileData?.request_date + ' UTC').replace(/-/g, "/")).toLocaleString()}
                            </Typography>
                            <Typography>
                                <b>Имя</b>
                            </Typography>
                            <Typography>
                                {fileData?.name}
                            </Typography>
                        </div> : null}

                    <Messages/>
            </Card>
            )}
        </Layout>
    )
}

export default Ticket;
