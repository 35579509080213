// outsource dependencies
import React, { useState } from 'react';
// UI
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
});

interface IOption {
  key: string;
  name: string;
}

interface IProps {
  type?: string;
  label: string;
  value?: string;
  options?: IOption[] | null;
  onChange?: (e?: any) => void;
  showPlaceholder?: boolean;
  disabled?: boolean;
}
export default function CustomSelect(props: IProps) {
  const { label, options, onChange, showPlaceholder, disabled, value } = props;
  const classes = useStyles();
  const [defaultValue, setValue] = useState<string>(value || '');
  const handleOnChange = (e?: any) => {
    setValue(e.target.value);
    onChange && onChange(e);
  };

  const placeholderText = '';
  return (
    <FormControl className={classes.container}>
      <InputLabel>{label}</InputLabel>
      <Select onChange={handleOnChange} disabled={Boolean(disabled)} value={defaultValue}>
        {showPlaceholder && <MenuItem value=''>{placeholderText}</MenuItem>}
        {Array.isArray(options) &&
          options.map((option: IOption) => (
            <MenuItem key={option.key} value={option.key}>
              {option.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

CustomSelect.defaultProps = {
  showPlaceholder: false,
};
