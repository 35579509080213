import React, {useEffect, useState} from 'react';
import Preloader from "../../components/Preloader";
import Layout from "../../components/layout/Common";
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClientsTable from "./components/ClientsTable";
import ClientsHeader from "./components/ClientHeader";
import currentConfig from "../../utils/config.utils";

const useStyles = makeStyles({
    container: {
        minHeight: '80vh',
    },
    title: {
        padding: '16px 0 0 16px'
    }
});

export interface IClientTableState {
    clients: IClient[],
    count: number
}

export interface IClient {
    client_: string | number;
    username: string;
    status: string | number;
    company: string | null;
    email: string;
    first_name: string | null;
    last_name: string | null;
    mobile_phone: string | null;
    confirmation_hash: string | null;
    plan: number
    telegram_: string | null;
    mms_key: string | null;
    bonus: number;
    create_date: string;
}

const rowsPerPage = 20;

const strToBoolean = (str: string | null) => {
    if (str === null) {
        return false;
    } else if (str.toLowerCase() === "true") {
        return true;
    }
    return false;
}

function Clients() {
    const classes = useStyles();

    const [clients, setClients] = useState<IClientTableState | null>(null);
    const [expectAnswer, setExpectAnswer] = useState<boolean>(false);

    let url = new URL(window.location.href);
    const page = url.searchParams.get("page") ? Number(url.searchParams.get("page")) : 1

    const changeTableContent = () => {
        setExpectAnswer(true);
        let tableUrl = new URL(window.location.href);

        const tablePage = tableUrl.searchParams.get("page") ? Number(tableUrl.searchParams.get("page")) : 1;
        const mmskey = strToBoolean(tableUrl.searchParams.get("mmskey"));
        const bonus = strToBoolean(tableUrl.searchParams.get("bonus"));
        const status = tableUrl.searchParams.get("status");
        const clientInfo = tableUrl.searchParams.get("info");
        const sortBy = tableUrl.searchParams.get("sort");
        const sortDirection = tableUrl.searchParams.get("direction");

        const config = currentConfig();
        fetch(config.apiUrl + 'clients', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                count: rowsPerPage,
                page: tablePage,
                status: {
                    mmskey: mmskey,
                    bonus: bonus,
                    status: status === "0" ? 0 : Number(status),
                    clientInfo: (clientInfo !== null && clientInfo.trim()) !== "" ? clientInfo : null,
                    sortBy: sortBy === null ? "client_" : sortBy,
                    sortDirection: (sortDirection === null || sortDirection !== "desc") ? "asc" : "desc"
                }
            })
        })
            .then(response => response.json())
            .then(data => {
                setClients(data.response);
                setExpectAnswer(false);
            })
            .catch(err => {
                console.log('error:', err)
            })
    }

    useEffect(() => {
        changeTableContent();
    }, []);

    return (
        <>
            <Layout noHeightConstraint>
                {expectAnswer && <Preloader pageCentered/>}
                {!expectAnswer && (
                    <Card variant='outlined' className={classes.container}>
                        <Typography className={classes.title} variant='h4'>
                            Clients
                        </Typography>
                        <ClientsHeader changeTableContent={changeTableContent}/>
                        <CardContent>
                            <ClientsTable changeTableContent={changeTableContent} page={page - 1} clients={clients}/>
                        </CardContent>
                    </Card>
                )}
            </Layout>
        </>);

}

export default Clients;