import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    container_8: {
        display: 'flex',
        flexWrap: 'wrap',
        fontFamily: 'monospace',
        width: '420px',
        color: '#8d8d8d',
        "& div": {
            flex: '0 0',
            margin: '0px 2px',
            "&.found": {
                color: 'black',
                backgroundColor: '#feff00',
                fontWeight: 'bold'
            }
        }
    },
    container_16: {
        display: 'flex',
        flexWrap: 'wrap',
        fontFamily: 'monospace',
        width: '760px',
        color: '#8d8d8d',
        "& div": {
            flex: '0 0',
            margin: '0px 2px',
            "&.found": {
                color: 'black',
                backgroundColor: '#feff00',
                fontWeight: 'bold'
            }
        }
    }
})

interface IProps {
    data: string
    bitness: number
    highlightPos?: number[]
}

export default function PrettifyMasks({data, bitness, highlightPos}: IProps) {
    const classes = useStyles();
    const numInRows = 20

    //const [out, setOut] = useState<Array<any>>([]);

    const size = bitness / 8
    console.log('render len:', (data.length / (size * 2)))
//    useEffect(() => {

        let _out = []
        let inRow = 1
        for (let i = 0; i < data.length; i += (size * 2)) {
            const val = data.slice(i, i + size * 2)
            if (highlightPos && highlightPos.indexOf(i / (size * 2)) > -1)
                _out.push({v: val, f: true})
            else
                _out.push({v: val, f: false})
            if (inRow === numInRows) {
                //_out.push(<br/>)
                inRow = 0
            }
            inRow++
        }
        //console.log(_out)
        //setOut(_out)
//    }, [data])
    return (
        <div className={bitness === 8 ? classes.container_8 : classes.container_16}>
            {_out.map((item, i) => {
                return (
                    <div key={i} className={(item.f ? 'found' : undefined)}>
                       {item.v}
                    </div>
                )
            })}
        </div>
    )
}