// outsource dependencies
import React from 'react';
// UI
// import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

// const useStyles = makeStyles({
// });

export default function CustomTextField(props: any) {
  // const classes = useStyles();
  const reduxProps = props.input || {}
  return <TextField {...props} {...reduxProps} />;
}
