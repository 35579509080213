import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Card, CardContent} from "@material-ui/core";
import Layout from "../../components/layout/Common";
//import currentConfig from "../../utils/config.utils";
import {useParams} from "react-router-dom";
import Preloader from "../../components/Preloader";
import History from "./components/History";
import currentConfig from 'utils/config.utils';
import PrettyHex from './components/PrettyHex';

const useStyles = makeStyles({
    container: {
        minHeight: '80vh',
    },
    title: {
        padding: '16px 0 0 16px'
    },
    compareContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column"
    },
    preloaderContainer: {
        marginTop: "20px"
    },
    fileBox: {
        fontFamily: 'monospace',
        overflowX: 'scroll',
        whiteSpace: 'nowrap'
    },
    fileBoxTable: {
        marginTop: 10
    },
    fileBoxAddress: {
        color: 'black',
        fontWeight: 'bold',
        paddingLeft: '2px'
    },
    fileBoxCO: {
        color: '#c6c3c3',
        backgroundColor: '#fbf1d0'
    },
    fileBoxCP: {
        color: '#c6c3c3',
        backgroundColor: '#fbf1d0'
    },
    fileBoxPS: {
        color: '#c6c3c3',
    },
    fileBoxPE: {
        color: '#c6c3c3',
    },
    leftContainer: {
    },
    rightContainer: {
    },
    centerContainer: {
    }
});


function ClientHistoryCompare() {
    const classes = useStyles();
    let {id} = useParams<{id: string}>();

    const [expectAnswer, setExpectAnswer] = useState<boolean>(false);
    const [compareResponse, setCompareResponse] = useState<Array<Object>>([]);

    async function compareCallback (clientFile:number) {
        setExpectAnswer(true)
        const config = currentConfig();
        let res = await fetch(config.apiUrl + 'analyze/client-history-compare', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({id: clientFile})
        })
        const result = await res.json()
        setCompareResponse(result.response.data)
        setExpectAnswer(false)
    }

    return (
        <Layout noHeightConstraint>
            <Card variant='outlined' className={classes.container}>
                <Typography className={classes.title} variant='h4'>
                    Compare files
                </Typography>
                <History clientFile={+id} compareCallback={compareCallback} ></History>
                <CardContent>
                    {expectAnswer && <Preloader/>}
                    {!expectAnswer && <div className={classes.fileBox}>
                            {compareResponse.map((diff:any) => {
                                return (
                                    <table className={classes.fileBoxTable} key={diff.a}>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td className={classes.fileBoxAddress} colSpan={2}>
                                                    {diff.a}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <PrettyHex hex={diff.ops} extClasses={classes.fileBoxPS}/>
                                                </td>
                                                <td>
                                                    <PrettyHex hex={diff.o} hexDiff={diff.p} extClasses={classes.fileBoxCO} baseAddress={diff.a}/>
                                                </td>
                                                <td>
                                                    <PrettyHex hex={diff.ope} extClasses={classes.fileBoxPE}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <PrettyHex hex={diff.pps} extClasses={classes.fileBoxPS}/>
                                                </td>
                                                <td>
                                                    <PrettyHex hex={diff.p} hexDiff={diff.o} extClasses={classes.fileBoxCP} baseAddress={diff.a}/>
                                                </td>
                                                <td>
                                                    <PrettyHex hex={diff.ppe} extClasses={classes.fileBoxPE}/>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )
                            })}
                    </div>}
                </CardContent>
            </Card>
        </Layout>
    )
}


export default ClientHistoryCompare;
