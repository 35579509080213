// outsource dependencies
import { fork, takeLatest, call, put, all, select } from 'redux-saga/effects';
// local dependencies
import {
  TYPE,
  selector as fwEcuSelector,
  // action
  loadEcusAction,
  updateFwEcuAction,
  updateFwEcusDataAction,
  // constants
  DefaultTableOptions,
  // interface
  IFwEcu,
} from 'private-layout/fw-ecu/reducer';
import API from 'services/request.service';

function* loadClientRequestFilesSaga({ payload }: ReturnType<typeof loadEcusAction>) {
  const { count, page } = payload;
  yield put(updateFwEcusDataAction({ expectFwEcusAnswer: true }));
  try {
    const { data } = yield call<any>(API, {
      url: '/ecus/getFwEcus',
      method: 'POST',
      data: {
        count,
        page,
      },
    });
    let fwEcusList = data?.response?.ecus || null;
    fwEcusList = fwEcusList.map((fwEcuItem: IFwEcu) => ({ ...fwEcuItem, changed: false }));
    yield all([put(updateFwEcusDataAction({ expectAnswer: false, expectFwEcusAnswer: false })), put(updateFwEcusDataAction({ fwEcusList }))]);
  } catch (e) {
    yield put(updateFwEcusDataAction({ expectFwEcusAnswer: false }));
  }
}

function* initializeFwEcusSaga() {
  yield put(updateFwEcusDataAction({ expectAnswer: true, expectFwEcusAnswer: true }));
  try {
    const { data: brands } = yield call<any>(API, {
      url: '/ecus/get-fw-ecu-brands',
      method: 'GET',
    });
    let fwEcuBrandsList = brands?.response?.ecuBrands || [];
    const { data } = yield call<any>(API, {
      url: '/ecus/getFwEcus',
      method: 'POST',
      data: {
        count: DefaultTableOptions.DEFAULT_FILES_COUNT,
        page: DefaultTableOptions.DEFAULT_PAGE,
      },
    });
    let fwEcusList = data?.response?.ecus || null;
    fwEcusList = fwEcusList.map((fwEcuItem: IFwEcu) => ({ ...fwEcuItem, changed: false }));
    const totalEcusCount = data?.response?.ecusCount || null;
    yield all([put(updateFwEcusDataAction({ expectAnswer: false, expectFwEcusAnswer: false })), put(updateFwEcusDataAction({ fwEcusList, totalEcusCount, fwEcuBrandsList }))]);
  } catch (e) {
    yield put(updateFwEcusDataAction({ expectAnswer: false, expectFwEcusAnswer: false }));
  }
}

function* updateFwEcuSaga({ payload }: ReturnType<typeof updateFwEcuAction>) {
  const { fwEcusList } = yield select(fwEcuSelector);
  const updatedFwEcu = fwEcusList?.find((fwEcuItem: IFwEcu) => fwEcuItem.fw_ecu_ === payload.fw_ecu_);
  try {
    yield call<any>(API, {
      url: '/ecus/update-ecu',
      method: 'POST',
      data: {
        fw_ecu_: updatedFwEcu?.fw_ecu_,
        fw_name: updatedFwEcu?.fw_name,
        fw_ecu_brand_: Number(updatedFwEcu?.fw_ecu_brand_),
        nice_name: updatedFwEcu?.nice_name,
      },
    });
    const updateFwEcusList = fwEcusList?.map((fwEcuItem: IFwEcu) => {
      if (fwEcuItem.fw_ecu_ === payload.fw_ecu_) return { ...fwEcuItem, changed: false };
      return fwEcuItem;
    });
    yield put(updateFwEcusDataAction({ fwEcusList: updateFwEcusList, fwUpdateSuccess: true }));
  } catch (e) {
    yield put(updateFwEcusDataAction({ fwUpdateError: true }));
  }
}

function* addNewFwEcuSaga() {
  const { new_fw_name, new_fw_ecu_brand_, new_nice_name, ecusPerPage, page } = yield select(fwEcuSelector);
  yield put(updateFwEcusDataAction({ expectFwEcusAnswer: true }));
  try {
    yield call<any>(API, {
      url: '/ecus/save-ecu',
      method: 'POST',
      data: {
        fw_name: new_fw_name,
        fw_ecu_brand_: Number(new_fw_ecu_brand_),
        nice_name: new_nice_name,
      },
    });
    const { data } = yield call<any>(API, {
      url: '/ecus/getFwEcus',
      method: 'POST',
      data: {
        count: ecusPerPage,
        page,
      },
    });
    let fwEcusList = data?.response?.ecus || null;
    fwEcusList = fwEcusList.map((fwEcuItem: IFwEcu) => ({ ...fwEcuItem, changed: false }));
    const totalEcusCount = data?.response?.ecusCount || null;
    yield all([
      put(updateFwEcusDataAction({ newFwAddSuccess: true, new_fw_name: '', new_fw_ecu_brand_: '', new_nice_name: '', totalEcusCount, fwEcusList })),
      put(updateFwEcusDataAction({ expectFwEcusAnswer: false })),
    ]);
  } catch (e) {
    yield all([put(updateFwEcusDataAction({ newFwAddError: true })), put(updateFwEcusDataAction({ expectFwEcusAnswer: false }))]);
  }
}
/**
 * connect page sagas
 *
 * @private
 */
function* activityTasks() {
  yield takeLatest(TYPE.ADD_NEW_FW_ECU, addNewFwEcuSaga);
  yield takeLatest(TYPE.UPDATE_FW_ECU, updateFwEcuSaga);
  yield takeLatest(TYPE.LOAD_ECUS, loadClientRequestFilesSaga);
  yield takeLatest(TYPE.INITIALIZE_FILES, initializeFwEcusSaga);
}

export function* sagas() {
  yield fork(activityTasks);
}

export default sagas;
