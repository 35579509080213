// outsource dependencies
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
// local dependencies
import { selector as appSelector } from 'reducers';
import { historyPush } from 'store';
// UI
import Footer from 'components/Footer';
import Header from 'components/Header';
import Preloader from 'components/Preloader';
import ErrorDialog from 'components/dialog/Error';
// constants
import { CONSTRAINT } from 'constants/style';
import { ExpectedErrorStatus } from 'constants/error';
import { PUBLIC as ROUTE } from 'constants/routes';
// services
import API from 'services/request.service';
interface IStyle {
  noHeightConstraint?: boolean;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: (props: IStyle) => (props.noHeightConstraint ? '100%' : '100vh'),
    minHeight: '100vh',
    width: '100%',
    justifyContent: 'space-between',
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 16,
  },
  content: {
    maxWidth: CONSTRAINT.MAX_CONTENT_SIZE,
    width: '100%',
  },
});
interface IProps {
  children: any;
  appExpectAnswer?: boolean;
  noHeightConstraint?: boolean;
}

function Common(props: IProps) {
  const { children, appExpectAnswer, noHeightConstraint } = props;
  const classes = useStyles({ noHeightConstraint });
  const [globalError, setGlobalError] = useState<boolean>(false);
  const [unauthorizedError, setUnauthorizedError] = useState<boolean>(false);

  const handleGlobalErrorClose = () => {
    setGlobalError(false);
  };
  const handleUnauthorizedErrorClose = () => {
    setUnauthorizedError(false);
    historyPush(ROUTE.LOG_IN);
    window.location.reload();
  };

  useEffect(() => {
    // Global error handler
    API.interceptors.response.use(
      (response) => {
        // Do something with response data
        return response;
      },
      (error) => {
        const errorStatus = error?.response?.status || null;
        const isValidationError = Boolean(ExpectedErrorStatus[errorStatus]);
        console.error('error', error.response);
        if (!isValidationError) setGlobalError(true);
        if (Number(ExpectedErrorStatus[errorStatus]) === ExpectedErrorStatus.unauthorized) setUnauthorizedError(true);
        // Do something with response error
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <>
      <div className={classes.container}>
        <Header />
        <div className={classes.contentWrapper}>
          {appExpectAnswer && <Preloader pageCentered />}
          {!appExpectAnswer && (
            <div className={classes.contentWrapper}>
              <div className={classes.content}>{children}</div>
            </div>
          )}
        </div>
        <Footer />
      </div>
      {globalError && <ErrorDialog open={globalError} handleClose={handleGlobalErrorClose} text='Возникла серверная ошибка' />}
      {unauthorizedError && <ErrorDialog open={unauthorizedError} handleClose={handleUnauthorizedErrorClose} text='Пользователь неавторизован' />}
    </>
  );
}

export default connect((state) => ({
  appExpectAnswer: appSelector(state).appExpectAnswer,
}))(Common);
