import React from 'react';
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  def: {
    color: 'black',
    marginRight: 3
  },
  val: {
    marginTop: 0,
    marginBottom: 0,
  },
  item: {
    paddingTop: 0,
    paddingBottom: 0
  }
});

interface IProps {
  def: string
  val: any
  button?: any
}

function FileInfoDefItem({def, val, button}: IProps) {
  const classes = useStyles();

  return (
    <ListItem className={classes.item}>
      <ListItemIcon className={classes.def}>
        <strong>{def}:</strong>
      </ListItemIcon>
      <ListItemText className={classes.val}>
        {val}
      </ListItemText>
      {button}
    </ListItem>
  )
}

export default FileInfoDefItem