// types
export const TYPE = ((prefix) => ({
  PREFIX: new RegExp(prefix, 'i'),
  // complex actions
  DATA: `${prefix}DATA`,
  // state
  FW_NAME: `${prefix}FW_NAME`,
  FW_ECU_BRAND: `${prefix}FW_ECU_BRAND`,
  NICE_NAME: `${prefix}NICE_NAME`,
  // trigger
  LOAD_ECUS: `${prefix}LOAD_ECUS`,
  UPDATE_FW_ECU: `${prefix}UPDATE_FW_ECU`,
  ADD_NEW_FW_ECU: `${prefix}ADD_NEW_FW_ECU`,
  INITIALIZE_FILES: `${prefix}INITIALIZE_FILES`,
  CLEAR: `${prefix}CLEAR`,
}))('@fw-ecu/');

// selector
export const selector = (state: any) => state.fwEcu;
// UI display constants
const DEFAULT_FILES_COUNT = 20;
const DEFAULT_PAGE = 0;
const FILES_COUNT_OPTIONS = [DEFAULT_FILES_COUNT, 50, 100, 250];
export const DefaultTableOptions = {
  DEFAULT_FILES_COUNT,
  DEFAULT_PAGE,
  FILES_COUNT_OPTIONS,
};

export interface IFwEcuBrand {
  key: string;
  name: string;
}

export interface IFwEcu {
  fw_ecu_: number;
  fw_name: string;
  fw_ecu_brand_: string;
  fw_soft_count: string;
  nice_name: string;
  changed: boolean;
}

interface IState {
  fwEcuBrandsList?: IFwEcuBrand[] | null; 
  fwEcusList?: IFwEcu[] | null;
  totalEcusCount?: number;

  expectAnswer?: boolean;
  expectFwEcusAnswer?: boolean;

  page?: number;
  ecusPerPage?: number;

  fwUpdateSuccess?: boolean;
  fwUpdateError?: boolean;

  new_fw_name?: string;
  new_fw_ecu_brand_?: string;
  new_nice_name?: string;

  newFwAddSuccess?: boolean;
  newFwAddError?: boolean;
}

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}
// state flow
export const updateFwEcusDataAction = (data: IState) => typedAction(TYPE.DATA, data);

export const updateFwNameAction = (fw_ecu_: number, fw_name: string) => typedAction(TYPE.FW_NAME, { fw_ecu_, fw_name });
export const updateFwEcuBrandAction = (fw_ecu_: number, fw_ecu_brand_: string) => typedAction(TYPE.FW_ECU_BRAND, { fw_ecu_, fw_ecu_brand_ });
export const updateFwNiceNameAction = (fw_ecu_: number, nice_name: string) => typedAction(TYPE.NICE_NAME, { fw_ecu_, nice_name });
// triggers 
export const addNewFwEcuAction = () => typedAction(TYPE.ADD_NEW_FW_ECU);
export const updateFwEcuAction = (fw_ecu_: number) => typedAction(TYPE.UPDATE_FW_ECU, { fw_ecu_ });
export const loadEcusAction = (count: number, page: number) => typedAction(TYPE.LOAD_ECUS, { count, page });
export const initializeFwEcusAction = () => typedAction(TYPE.INITIALIZE_FILES);
export const clearStateAction = () => typedAction(TYPE.CLEAR);

type UserAction = ReturnType<
  | typeof updateFwEcusDataAction
  | typeof updateFwNameAction
  | typeof updateFwEcuBrandAction
  | typeof updateFwNiceNameAction
  //
  | typeof loadEcusAction
  | typeof initializeFwEcusAction
>;

const initialState: IState = {
  fwEcusList: null,
  totalEcusCount: 0,

  expectAnswer: false,
  expectFwEcusAnswer: false,

  page: DefaultTableOptions.DEFAULT_PAGE,
  ecusPerPage: DefaultTableOptions.DEFAULT_FILES_COUNT,

  fwUpdateSuccess: false,
  fwUpdateError: false,

  new_fw_name: '',
  new_fw_ecu_brand_: '',
  new_nice_name: '',

  newFwAddSuccess: false,
  newFwAddError: false,
};

export function fwEcu(state = initialState, action: UserAction): IState {
  switch (action.type) {
    default:
      return state;
    case TYPE.DATA:
      return { ...state, ...action.payload };
    case TYPE.FW_NAME:
      return _handleFwNameAction(state, action.payload);
    case TYPE.FW_ECU_BRAND:
      return _handleFwEcuBrandAction(state, action.payload);
    case TYPE.NICE_NAME:
      return _handleNiceNameAction(state, action.payload);
    case TYPE.CLEAR:
      return initialState;
  }
}

const _handleFwNameAction = (state: IState, payload: { fw_ecu_: number; fw_name: string }) => {
  const { fwEcusList } = state;
  if (Array.isArray(fwEcusList)) {
    const updateFwEcusList = fwEcusList.map(fwEcusItem => {
      if (fwEcusItem.fw_ecu_ === payload.fw_ecu_) return {...fwEcusItem, changed: true, fw_name: payload.fw_name};
      return fwEcusItem;
    });
    return { ...state, fwEcusList: updateFwEcusList };
  }
  return state;
};
const _handleFwEcuBrandAction = (state: IState, payload: { fw_ecu_: number; fw_ecu_brand_: string }) => {
  const { fwEcusList } = state;
  if (Array.isArray(fwEcusList)) {
    const updateFwEcusList = fwEcusList.map((fwEcusItem) => {
      if (fwEcusItem.fw_ecu_ === payload.fw_ecu_) return { ...fwEcusItem, changed: true, fw_ecu_brand_: payload.fw_ecu_brand_ };
      return fwEcusItem;
    });
    return { ...state, fwEcusList: updateFwEcusList };
  }
  return state;
};
const _handleNiceNameAction = (state: IState, payload: { fw_ecu_: number; nice_name: string }) => {
  const { fwEcusList } = state;
  if (Array.isArray(fwEcusList)) {
    const updateFwEcusList = fwEcusList.map((fwEcusItem) => {
      if (fwEcusItem.fw_ecu_ === payload.fw_ecu_) return { ...fwEcusItem, changed: true, nice_name: payload.nice_name };
      return fwEcusItem;
    });
    return { ...state, fwEcusList: updateFwEcusList };
  }
  return state;
};
