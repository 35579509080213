// outsource dependencies
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// UI
import { ToastContainer, toast } from 'react-toastify';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// local dependencies
// state
import {
  selector as fwSoftGroupSelector,
  // actions
  updateFwSoftGroupDataAction,
  // trigger
  loadSoftGroupAction,
  initializeFwEcusAction,
  clearStateAction,
  approveDeleteAction,
  // interface
  IFwSoftGroup,
  IFwEcu,
} from 'private-layout/fw-soft-group/reducer';
// UI
import Layout from 'components/layout/Common';
import FwSoftGroupsTable from 'private-layout/fw-soft-group/components/FwSoftGroupsTable';
import Preloader from 'components/Preloader';
import FwSoftGroupCreateDialog from 'private-layout/fw-soft-group/components/FwSoftGroupCreateDialog';
import FwSoftGroupDeleteDialog from 'private-layout/fw-soft-group/components/FwSoftGroupDeleteDialog';
import FwSoftGroupEditDialog from 'private-layout/fw-soft-group/components/FwSoftGroupEditDialog';
// utils
import { useQuery } from 'utils/hook.utils';

const useStyles = makeStyles({
  container: {
    minHeight: '80vh',
  },
  title: {
    padding: '16px 0 0 16px',
  },
});

interface IProps {
  // state
  fwSoftGroupList?: IFwSoftGroup[] | null;
  totalSoftGroupCount?: number;
  fwEcuList?: IFwEcu[] | null;

  expectAnswer?: boolean;
  expectFwSoftGroupAnswer?: boolean;

  fwSoftGroupIdDelete?: number | null;
  fwSoftGroupIdEdit?: number | null;

  page?: number;
  filesPerPage?: number;
  filterByFwEcu?: number | null;
  fwSearchVal?: string;

  fwSoftGroupCreateSuccess?: boolean;
  fwSoftGroupCreateError?: boolean;
  fwSoftGroupDeleteSuccess?: boolean;
  fwSoftGroupDeleteError?: boolean;
  fwSoftGroupEditSuccess?: boolean;
  fwSoftGroupEditError?: boolean;

  clearSuccessDeleteFeedback?: () => void;
  clearErrorDeleteFeedback?: () => void;
  clearSuccessCreateFeedback?: () => void;
  clearErrorCreateFeedback?: () => void;
  clearSuccessEditFeedback?: () => void;
  clearErrorEditFeedback?: () => void;
  // action
  updatePage?: (page: number) => void;
  updateFilesPerPage?: (filesPerPage: number) => void;
  updateFilterByFwEcu?: (filterByFwEcu: number | null) => void;
  updateFwSearchVal?: (fwSearchVal: string) => void;
  updateFwSoftGroupIdEdit?: (fwSoftGroupIdEdit: number | null) => void;
  updateFwSoftGroupIdDelete?: (fwSoftGroupIdDelete: number | null) => void;
  updateFwSoftGroupEdit?: ({
    editedFwEcu,
    editedFwKey,
    editedConfig,
    editedApproved,
  }: {
    editedFwEcu?: number | null;
    editedFwKey?: string;
    editedConfig?: string;
    editedApproved?: boolean;
  }) => void;
  // trigger
  loadSoftGroup?: () => void;
  initializeFiles?: () => void;
  clearState?: () => void;
  approveDelete?: () => void;
}

function ClientRequestFiles(props: IProps) {
  const {
    totalSoftGroupCount,
    fwSoftGroupList,
    fwEcuList,
    // loading
    expectAnswer,
    expectFwSoftGroupAnswer,
    // state
    fwSoftGroupIdDelete,
    // fwSoftGroupIdEdit,
    page,
    filesPerPage,
    filterByFwEcu,
    fwSearchVal,
    //
    fwSoftGroupCreateSuccess,
    fwSoftGroupCreateError,
    fwSoftGroupDeleteSuccess,
    fwSoftGroupDeleteError,
    fwSoftGroupEditSuccess,
    fwSoftGroupEditError,
    //
    clearSuccessDeleteFeedback,
    clearErrorDeleteFeedback,
    clearSuccessCreateFeedback,
    clearErrorCreateFeedback,
    clearSuccessEditFeedback,
    clearErrorEditFeedback,
    //
    updatePage,
    updateFilesPerPage,
    updateFilterByFwEcu,
    updateFwSearchVal,
    updateFwSoftGroupIdEdit,
    updateFwSoftGroupIdDelete,
    updateFwSoftGroupEdit,
    // trigger
    initializeFiles,
    clearState,
    loadSoftGroup,
    approveDelete,
  } = props;
  const classes = useStyles();
  const query = useQuery();
  const filterByEcu = query.get('fw_ecu');

  const fwSoftGroupDelete = Array.isArray(fwSoftGroupList) ? fwSoftGroupList.find((fwSoft: IFwSoftGroup) => fwSoft.fw_soft_group_ === fwSoftGroupIdDelete) : null;

  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    updateFwSoftGroupIdEdit && updateFwSoftGroupIdEdit(null);
  };
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    updateFwSoftGroupIdDelete && updateFwSoftGroupIdDelete(null);
  };
  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };
  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };
  const handleDelete = () => {
    approveDelete && approveDelete();
    handleCloseDeleteDialog();
  };

  const handleDeleteSuccessFeedback = () => {
    toast.success('Файл успешно удалён');
  };
  const handleDeleteErrorFeedback = () => {
    toast.error('Не удалось удалить файл');
  };
  const handleSaveSuccessFeedback = () => {
    toast.success('Файл успешно сохранён');
  };
  const handleSaveErrorFeedback = () => {
    toast.error('Не удалось сохранить файл');
  };
  const handleEditSuccessFeedback = () => {
    toast.success('Файл успешно отредактирован');
  };
  const handleEditErrorFeedback = () => {
    toast.error('Не удалось отредактировать файл');
  };

  // delete soft - watcher
  useEffect(() => {
    if (fwSoftGroupDeleteSuccess) {
      handleDeleteSuccessFeedback();
      clearSuccessDeleteFeedback && clearSuccessDeleteFeedback();
    }
    if (fwSoftGroupDeleteError) {
      handleDeleteErrorFeedback();
      clearErrorDeleteFeedback && clearErrorDeleteFeedback();
    }
  }, [fwSoftGroupDeleteSuccess, fwSoftGroupDeleteError, clearErrorDeleteFeedback, clearSuccessDeleteFeedback]);
  // edit soft - watcher
  useEffect(() => {
    if (fwSoftGroupEditSuccess) {
      handleEditSuccessFeedback();
      clearSuccessEditFeedback && clearSuccessEditFeedback();
      handleCloseEditDialog();
    }
    if (fwSoftGroupEditError) {
      handleEditErrorFeedback();
      clearErrorEditFeedback && clearErrorEditFeedback();
      handleCloseEditDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fwSoftGroupEditSuccess, fwSoftGroupEditError, clearErrorEditFeedback, clearSuccessEditFeedback]);
  // create soft - watcher
  useEffect(() => {
    if (fwSoftGroupCreateSuccess) {
      handleSaveSuccessFeedback();
      clearSuccessCreateFeedback && clearSuccessCreateFeedback();
      handleCloseCreateDialog();
    }
    if (fwSoftGroupCreateError) {
      handleSaveErrorFeedback();
      clearErrorCreateFeedback && clearErrorCreateFeedback();
      handleCloseCreateDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fwSoftGroupCreateSuccess, fwSoftGroupCreateError, clearErrorCreateFeedback, clearSuccessCreateFeedback]);
  // initialize
  useEffect(() => {
    if (filterByEcu) {
      updateFilterByFwEcu && updateFilterByFwEcu(Number(filterByEcu) || null);
    }
    initializeFiles && initializeFiles();
    return () => {
      clearState && clearState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeFiles, clearState, filterByEcu, filterByEcu]);
  return (
    <>
      <Layout noHeightConstraint>
        {expectAnswer && <Preloader pageCentered />}
        {!expectAnswer && (
          <Card variant='outlined' className={classes.container}>
            <Typography className={classes.title} variant='h4'>
              FW soft group
            </Typography>
            <CardContent>
              <FwSoftGroupsTable
                totalSoftGroupCount={Number(totalSoftGroupCount)}
                filesPerPage={Number(filesPerPage)}
                page={Number(page)}
                fwSoftGroupList={fwSoftGroupList}
                fwEcuList={fwEcuList}
                filterByFwEcu={filterByFwEcu}
                fwSearchVal={fwSearchVal}
                expectFwSoftGroupAnswer={expectFwSoftGroupAnswer}
                loadSoftGroup={loadSoftGroup}
                updateFilesPerPage={updateFilesPerPage}
                updatePage={updatePage}
                updateFilterByFwEcu={updateFilterByFwEcu}
                updateFwSearchVal={updateFwSearchVal}
                updateFwSoftGroupIdEdit={updateFwSoftGroupIdEdit}
                updateFwSoftGroupEdit={updateFwSoftGroupEdit}
                updateFwSoftGroupIdDelete={updateFwSoftGroupIdDelete}
                handleOpenDeleteDialog={handleOpenDeleteDialog}
                handleOpenEditDialog={handleOpenEditDialog}
                handleOpenCreateDialog={handleOpenCreateDialog}
              />
            </CardContent>
          </Card>
        )}
      </Layout>
      {openCreateDialog && <FwSoftGroupCreateDialog open={openCreateDialog} fwEcuList={fwEcuList} handleClose={handleCloseCreateDialog} />}
      {openDeleteDialog && (
        <FwSoftGroupDeleteDialog
          open={openDeleteDialog}
          fwSoftGroupDelete={fwSoftGroupDelete}
          fwEcuList={fwEcuList}
          handleClose={handleCloseDeleteDialog}
          handleAction={handleDelete}
        />
      )}
      {openEditDialog && <FwSoftGroupEditDialog open={openEditDialog} handleClose={handleCloseEditDialog} fwEcuList={fwEcuList} />}
      <ToastContainer />
    </>
  );
}

export default connect(
  (state) => fwSoftGroupSelector(state),
  (dispatch) => ({
    updatePage: (page: number) => dispatch(updateFwSoftGroupDataAction({ page })),
    updateFilesPerPage: (filesPerPage: number) => dispatch(updateFwSoftGroupDataAction({ filesPerPage })),
    updateFilterByFwEcu: (filterByFwEcu: number | null) => dispatch(updateFwSoftGroupDataAction({ filterByFwEcu })),
    updateFwSearchVal: (fwSearchVal: string) => dispatch(updateFwSoftGroupDataAction({ fwSearchVal })),

    updateFwSoftGroupIdEdit: (fwSoftGroupIdEdit: number | null) => dispatch(updateFwSoftGroupDataAction({ fwSoftGroupIdEdit })),
    updateFwSoftGroupIdDelete: (fwSoftGroupIdDelete: number | null) => dispatch(updateFwSoftGroupDataAction({ fwSoftGroupIdDelete })),
    updateFwSoftGroupEdit: ({
      editedFwEcu,
      editedFwKey,
      editedConfig,
      editedApproved,
    }: {
      editedFwEcu?: number | null;
      editedFwKey?: string;
      editedConfig?: string;
      editedApproved?: boolean;
    }) => dispatch(updateFwSoftGroupDataAction({ editedFwEcu, editedFwKey, editedConfig, editedApproved })),

    clearSuccessDeleteFeedback: () => dispatch(updateFwSoftGroupDataAction({ fwSoftGroupDeleteSuccess: false })),
    clearErrorDeleteFeedback: () => dispatch(updateFwSoftGroupDataAction({ fwSoftGroupDeleteError: false })),
    clearSuccessCreateFeedback: () => dispatch(updateFwSoftGroupDataAction({ fwSoftGroupCreateSuccess: false })),
    clearErrorCreateFeedback: () => dispatch(updateFwSoftGroupDataAction({ fwSoftGroupCreateError: false })),
    clearSuccessEditFeedback: () => dispatch(updateFwSoftGroupDataAction({ fwSoftGroupEditSuccess: false })),
    clearErrorEditFeedback: () => dispatch(updateFwSoftGroupDataAction({ fwSoftGroupEditError: false })),

    loadSoftGroup: () => dispatch(loadSoftGroupAction()),
    initializeFiles: () => dispatch(initializeFwEcusAction()),
    clearState: () => dispatch(clearStateAction()),
    approveDelete: () => dispatch(approveDeleteAction()),
  })
)(ClientRequestFiles);
