// outsource dependencies
import { fork, takeLatest, call, put, all, select } from 'redux-saga/effects';
// local dependencies
import {
  TYPE,
  selector as fwSoftGroupSelector,
  // action
  updateFwSoftGroupDataAction,
  loadSoftGroupAction,
  // constants
  DefaultTableOptions,
  // interface
} from 'private-layout/fw-soft-group/reducer';
import API from 'services/request.service';

function* loadSoftGroupsSaga() {
  const { filesPerPage, page, filterByFwEcu, fwSearchVal } = yield select(fwSoftGroupSelector);
  yield put(updateFwSoftGroupDataAction({ expectFwSoftGroupAnswer: true }));
  try {
    const { data } = yield call<any>(API, {
      url: '/ecus/get-fw-soft-group',
      method: 'POST',
      data: {
        count: filesPerPage,
        page,
        fw_ecu_: filterByFwEcu,
        val: fwSearchVal,
      },
    });
    let fwSoftGroupList = data?.response?.softGroup || null;
    const totalSoftGroupCount = data?.response?.softGroupCount || null;
    yield all([
      put(updateFwSoftGroupDataAction({ expectAnswer: false, expectFwSoftGroupAnswer: false })),
      put(updateFwSoftGroupDataAction({ totalSoftGroupCount, fwSoftGroupList })),
    ]);
  } catch (e) {
    yield put(updateFwSoftGroupDataAction({ expectFwSoftGroupAnswer: false }));
  }
}

function* initializeFwSoftGroupSaga() {
  const { filterByFwEcu } = yield select(fwSoftGroupSelector);
  yield put(updateFwSoftGroupDataAction({ expectAnswer: true, expectFwSoftGroupAnswer: true }));
  try {
    const { data: ecusList } = yield call<any>(API, {
      url: '/ecus/get-fw-ecus-list',
      method: 'GET',
    });
    const fwEcuList = ecusList?.response?.ecus || null;
    const { data } = yield call<any>(API, {
      url: '/ecus/get-fw-soft-group',
      method: 'POST',
      data: {
        count: DefaultTableOptions.DEFAULT_FILES_COUNT,
        page: DefaultTableOptions.DEFAULT_PAGE,
        fw_ecu_: filterByFwEcu,
      },
    });
    let fwSoftGroupList = data?.response?.softGroup || null;
    const totalSoftGroupCount = data?.response?.softGroupCount || null;
    yield all([
      put(updateFwSoftGroupDataAction({ expectAnswer: false, expectFwSoftGroupAnswer: false })),
      put(updateFwSoftGroupDataAction({ totalSoftGroupCount, fwSoftGroupList, fwEcuList })),
    ]);
  } catch (e) {
    yield put(updateFwSoftGroupDataAction({ expectAnswer: false, expectFwSoftGroupAnswer: false }));
  }
}

function* saveNewFwSoftGroupSaga() {
  const { newFwEcu, newFwKey, newConfig, newApproved } = yield select(fwSoftGroupSelector);
    
  yield put(updateFwSoftGroupDataAction({ expectFwSoftGroupAnswer: true }));
  try {
    yield call<any>(API, {
      url: '/ecus/save-fw-soft-group',
      method: 'POST',
      data: {
        fw_ecu_: newFwEcu,
        fw_key: newFwKey,
        config: newConfig || null,
        approved: newApproved,
      },
    });
    // trig other loading saga
    yield put(loadSoftGroupAction());
    yield all([
      put(updateFwSoftGroupDataAction({ expectFwSoftGroupAnswer: false })),
      put(
        updateFwSoftGroupDataAction({
          fwSoftGroupCreateSuccess: true,
          newFwEcu: null,
          newFwKey: '',
          newConfig: '',
          newApproved: false,
          createErrors: null,
        })
      ),
    ]);
  } catch (e) {
    yield put(updateFwSoftGroupDataAction({ expectFwSoftGroupAnswer: false, fwSoftGroupCreateError: true }));
  }
}

function* approveDeleteFwSoftGroupSaga() {
  const { fwSoftGroupIdDelete } = yield select(fwSoftGroupSelector);
  yield put(updateFwSoftGroupDataAction({ expectFwSoftGroupAnswer: true }));
  try {
    yield call<any>(API, {
      url: '/ecus/delete-fw-soft-group',
      method: 'DELETE',
      data: {
        fw_soft_group_: fwSoftGroupIdDelete,
      },
    });
    yield put(loadSoftGroupAction());
    yield all([
      put(updateFwSoftGroupDataAction({ expectFwSoftGroupAnswer: false })),
      put(
        updateFwSoftGroupDataAction({
          fwSoftGroupIdDelete: null,
          fwSoftGroupDeleteSuccess: true,
        })
      ),
    ]);
  } catch (e) {
    yield put(updateFwSoftGroupDataAction({ expectFwSoftGroupAnswer: false, fwSoftGroupDeleteError: true }));
  }
}

function* editFwSoftSaga() {
  const { editedFwEcu, editedFwKey, editedConfig, editedApproved, fwSoftGroupIdEdit } = yield select(fwSoftGroupSelector);
  yield put(updateFwSoftGroupDataAction({ expectFwSoftGroupAnswer: true }));
  try {
    yield call<any>(API, {
      url: '/ecus/update-fw-soft-group',
      method: 'POST',
      data: {
        fw_soft_group_: fwSoftGroupIdEdit,
        fw_ecu_: editedFwEcu,
        fw_key: editedFwKey,
        config: editedConfig,
        approved: editedApproved,
      },
    });
    yield put(loadSoftGroupAction());
    yield all([
      put(updateFwSoftGroupDataAction({ expectFwSoftGroupAnswer: false })),
      put(
        updateFwSoftGroupDataAction({
          fwSoftGroupEditSuccess: true,
          fwSoftGroupIdEdit: null,
          editedFwEcu: null,
          editedFwKey: '',
          editedConfig: '',
          editedApproved: false,
          editErrors: null,
        })
      ),
    ]);
  } catch (e) {
    yield put(updateFwSoftGroupDataAction({ expectFwSoftGroupAnswer: false, fwSoftGroupEditError: true }));
  }
}

/**
 * connect page sagas
 *
 * @private
 */
function* activityTasks() {
  yield takeLatest(TYPE.INITIALIZE_FILES, initializeFwSoftGroupSaga);
  yield takeLatest(TYPE.LOAD_SOFT_GROUP, loadSoftGroupsSaga);
  yield takeLatest(TYPE.SAVE_NEW_SOFT_GROUP, saveNewFwSoftGroupSaga);
  yield takeLatest(TYPE.APPROVE_DELETE, approveDeleteFwSoftGroupSaga);
  yield takeLatest(TYPE.EDIT_SOFT_GROUP, editFwSoftSaga);
}

export function* sagas() {
  yield fork(activityTasks);
}

export default sagas;
