import React, {useEffect, useState} from 'react';
import currentConfig from "../../../utils/config.utils";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { Link } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

interface IProps {
  clientFile: number,
  compareCallback: Function
}

const useStyles = makeStyles({
  cell: {
    padding: '0px 16px'
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline'
  }
});

function History({clientFile, compareCallback}: IProps) {
  const classes = useStyles();

  const [filesHistory, setHistory] = useState<any>([]);

  useEffect(() => {
    if (!clientFile) {
      return
    }
    const config = currentConfig();
    fetch(config.apiUrl + 'admin/files/history', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify({fileKey: clientFile})
    }).then(response => response.json())
      .then(data => {
        setHistory(data.response.filesHistory);
      })
      .catch(err => {
        console.log('error:', err)
      })
  }, [clientFile])

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Имя Файла</TableCell>
            <TableCell>Дата добавления</TableCell>
            <TableCell>Коммент</TableCell>
            <TableCell>Действия</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filesHistory.map((fileHistory: any) => {
            return (
              <TableRow style={{backgroundColor: "#FFFFFF"}} key={fileHistory.admin_upload_file_log_}>
                <TableCell className={classes.cell}>{fileHistory.name}</TableCell>
                <TableCell className={classes.cell}>{new Date((fileHistory.add_date + ' UTC').replace(/-/g, "/")).toLocaleString()}</TableCell>
                <TableCell className={classes.cell}>{fileHistory.admin_comment}</TableCell>
                <TableCell className={classes.cell}>
                  <div>
                    <Link className={classes.link} onClick={() => {compareCallback(fileHistory.admin_upload_file_log_)}} >Сравнить с оригиналом</Link>
                  </div>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default History