// outsource dependencies
import React, {ChangeEvent, useEffect, useState} from 'react';
// UI
import { makeStyles } from '@material-ui/core/styles';
// local dependencies
import { DefaultTableOptions } from 'private-layout/client-request-files/reducer';
// constants
import { FileStatus } from 'constants/file';
import {IconButton, TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ConfirmButton from "../../../components/button/ConfirmButton";
import CancelButton from "../../../components/button/CancelButton";
import CustomCheckbox from "../../../components/checkbox/Checkbox";
import CustomSelect from "../../../components/select/Select";
import {TextRotateUp, TextRotationDown} from "@material-ui/icons";
import {APP_COLOR} from "../../../constants/style";

const useStyles = makeStyles({
  container: {
    padding: '16px 16px 0 16px'
  },
  fileInfo: {
    width: "300px",
    marginLeft: "20px",
    marginBlock: "-8px"
  },
  typography: {
    marginBottom: "20px"
  },
  filerContainer: {
    display: "flex"
  },
  status: {
    width: "300px",
    marginLeft: "20px",
    marginBlock: "7px"
  },
  addIcon: {
    color: APP_COLOR.LIGHT_GREY,
  },
});

const SORT_FIELDS: {[key: string]: string} = {
  clientId: 'Client ID',
  username: 'Username',
  company: 'Company',
  fileId: 'File ID',
  fileName: 'File name',
  addDate: 'Add Date',
  updateDate: 'Update Date',
}

interface IProps {
  filesPerPage: number;
  // action
  updateFilesStatus?: (fileStatus: number) => void;
  loadFiles?: (count: number, page: number) => void;
  updateFilesInfo?: (fileInfo: string) => void;
  updateHiddenOnly?: (hiddenOnly: boolean) => void;
  updateSort?: (sort: string) => void;
  updateDirection?: (direction: string) => void;
}

function FilesHeader(props: IProps) {
  const initUrl = new URL(window.location.href);
  const status = initUrl.searchParams.get("status") || '0'
  const search = initUrl.searchParams.get("search") || ''
  const hidden = initUrl.searchParams.get("hidden") || '0'
  const sort = initUrl.searchParams.get("sort") || 'updateDate'
  const direction = initUrl.searchParams.get("direction") || 'desc'

  const { filesPerPage, updateFilesStatus, loadFiles, updateFilesInfo, updateHiddenOnly, updateSort, updateDirection } = props;
  const classes = useStyles();

  const [fileInfo, setFileInfo] = useState(search);
  const [hiddenOnly, setHiddenOnly] = useState(hidden === '1');
  const [sortValue, setSortValue] = useState(sort);
  const [directionValue, setDirectionValue] = useState(direction);

  const fileInfoChangeHandle = (event: any) => {
    setFileInfo(event.target.value)
    updateFilesInfo && updateFilesInfo(event.target.value);
  }

  const fileStatusList = Object.keys(FileStatus)
    .map((key: string) => ({ label: FileStatus[Number(key)], value: Number(key) }))
    .filter((status: { label?: string; value: number }) => Boolean(status.label));
  const handleFileStatus = (fileStatus: number) => () => {
    updateFilesStatus && updateFilesStatus(fileStatus);
  };

  const [defaultValue, setDefaultValue] = useState<number>(Number(status));

  useEffect(() => {
    setDefaultValue(Number(status))
    setHiddenOnly(hidden === '1')
    setSortValue(sort)
    setDirectionValue(direction)
  }, [status, hidden, sort, direction]);

  const handleOnChange = (e: ChangeEvent<{name?: string, value: unknown}>) => {
    const value = e.target.value as number
    setDefaultValue(value)
    handleFileStatus(value)()
    setTimeout(() => {
      const url = new URL(window.location.href);
      url.searchParams.set("search", fileInfo);
      url.searchParams.set("status", String(value));
      url.searchParams.set("hidden", hiddenOnly ? '1' : '0');
      window.history.replaceState(null, "", url.href);

      loadFiles && loadFiles(filesPerPage, DefaultTableOptions.DEFAULT_PAGE);
    }, 1000)
  }

  const handleOnClickFilter = () => {
    const url = new URL(window.location.href);
    url.searchParams.set("search", fileInfo);
    url.searchParams.set("status", String(defaultValue));
    url.searchParams.set("hidden", hiddenOnly ? '1' : '0');
    window.history.replaceState(null, "", url.href);

    loadFiles && loadFiles(filesPerPage, DefaultTableOptions.DEFAULT_PAGE);
  }

  const setFilterValues = (status: number, search: string, hidden: boolean) => {
    setDefaultValue(status)
    handleFileStatus(status)()

    setFileInfo(search)
    updateFilesInfo && updateFilesInfo(search);

    setHiddenOnly(hidden);
    updateHiddenOnly && updateHiddenOnly(hidden)

    handleOnClickFilter()
  }

  const handleOnClickCancelFilter = () => {
    setFilterValues(2, '', false)

    const url = new URL(window.location.href);
    url.searchParams.set("search", '');
    url.searchParams.set("status", '2');
    url.searchParams.set("hidden", '0');
    window.history.replaceState(null, "", url.href);
  }

  const handleOnHiddenOnly = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked
    setHiddenOnly(value)
    updateHiddenOnly && updateHiddenOnly(value)
    setTimeout(() => {
      const url = new URL(window.location.href);
      url.searchParams.set("search", fileInfo);
      url.searchParams.set("status", String(defaultValue));
      url.searchParams.set("hidden", value ? '1' : '0');
      window.history.replaceState(null, "", url.href);

      loadFiles && loadFiles(filesPerPage, DefaultTableOptions.DEFAULT_PAGE);
    }, 1000)
  }

  const handleSort = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSortValue(e.target.value)
    updateSort && updateSort(e.target.value)

    const url = new URL(window.location.href);
    url.searchParams.set("sort", e.target.value);
    window.history.replaceState(null, "", url.href);

    loadFiles && loadFiles(filesPerPage, DefaultTableOptions.DEFAULT_PAGE);
  }

  const handleDirection = () => {
    setDirectionValue(directionValue === 'desc' ? 'asc' : 'desc')
    updateDirection && updateDirection(directionValue === 'desc' ? 'asc' : 'desc')

    const url = new URL(window.location.href);
    url.searchParams.set("direction", directionValue === 'desc' ? 'asc' : 'desc');
    window.history.replaceState(null, "", url.href);

    loadFiles && loadFiles(filesPerPage, DefaultTableOptions.DEFAULT_PAGE);
  }

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      handleOnClickFilter()
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.filerContainer}>
        <CustomCheckbox checked={hiddenOnly}
                        key={"только скрытые"} label={"только скрытые"}
                        onChange={handleOnHiddenOnly}/>

        <Select onChange={handleOnChange} value={defaultValue} className={classes.status}>
          {Array.isArray(fileStatusList) &&
              fileStatusList.map((option: { label?: string; value: number }) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
              ))}
        </Select>

        <TextField className={classes.fileInfo} fullWidth={true}
                   value={fileInfo}
                   label={"Информация о файле"}
                   onKeyDown={handleKeyDown}
                   onChange={fileInfoChangeHandle}/>

        <ConfirmButton style={{marginLeft: "20px"}} onClick={handleOnClickFilter}>Фильтровать</ConfirmButton>
        <CancelButton style={{marginLeft: "20px"}} onClick={() => handleOnClickCancelFilter()}>Сбросить</CancelButton>

      </div>
      <div className={classes.filerContainer} style={{padding: '10px', width: '400px'}}>
        <CustomSelect
            onChange={handleSort}
            label='Сортировать по'
            options={Object.keys(SORT_FIELDS).map(key => ({
              key,
              name: SORT_FIELDS[key],
            }))}
            value={sortValue}
        />
        <div style={{padding: '5px 0 0 10px'}}>
            <IconButton onClick={handleDirection} className={classes.addIcon}>
                {directionValue === 'desc'
                    ? <TextRotateUp fontSize='large'/>
                    : <TextRotationDown fontSize='large'/>}
            </IconButton>
        </div>
      </div>
    </div>
  );
}

export default FilesHeader;
