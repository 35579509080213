// outsource dependencies
import { fork, takeEvery, takeLatest, call, select, cancel, put } from 'redux-saga/effects';
// local dependencies
import { TYPE, selector as loginSelector, updateExpectAnswerAction, updateErrorLogInAction } from 'public-layout/log-in/reducer';
import API from 'services/request.service';
// routing
import { API_VALIDATION } from 'constants/error';
import { historyPush } from 'store';
import { PRIVATE as ROUTE } from 'constants/routes';
// utils
import { getFlattenValidationErrors } from 'utils/response.utils';

function* loginSaga() {
  const { email, password } = yield select(loginSelector);
  console.log('loginSaga start', email, password);
  yield put(updateExpectAnswerAction(true));
  try {
    const { data } = yield call<any>(API, {
      url: '/admin/login',
      method: 'POST',
      data: {
        email,
        password,
      },
    });
    console.log('loginSaga data', data);
    yield put(updateExpectAnswerAction(false));
    historyPush(ROUTE.CLIENT_REQUEST_FILES);
    window.location.reload();
  } catch (e: any) {
    const errorKey = e?.response?.data?.errorKey || null;
    const errors = getFlattenValidationErrors(e?.response?.data.response);
    let userNotFound = {};
    if (errorKey === API_VALIDATION.username_not_found) {
      userNotFound = { email: 'пользователь не найден' };
    }
    if (errorKey === API_VALIDATION.password_not_valid) {
      userNotFound = { ...userNotFound, password: 'неверный пароль' };
    }
    yield put(updateErrorLogInAction({ ...errors, ...userNotFound }));
    yield put(updateExpectAnswerAction(false));
  }
}
/**
 * connect page sagas
 *
 * @private
 */
function* activityTasks() {
  yield takeLatest(TYPE.LOG_IN_SUBMIT, loginSaga);
}
/**
 * define activity behavior
 * on component un mount we fire action CLEAR to bring reducer data to default and here
 * we renew all sagas to prevent executing actions which does not finish yet
 */
export function* sagas() {

  // @ts-ignore
  let activity = yield fork(activityTasks);
  yield takeEvery(TYPE.CLEAR, function* () {
    yield cancel(activity);
    // @ts-ignore
    activity = yield fork(activityTasks);
  });
}

export default sagas;
