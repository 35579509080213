// types
export const TYPE = ((prefix) => ({
  PREFIX: new RegExp(prefix, 'i'),
  // complex actions
  DATA: `${prefix}DATA`,
  FILES_STATUS: `${prefix}FILES_STATUS`,
  FILES_INFO: `${prefix}FILES_INFO`,
  HIDDEN_ONLY: `${prefix}HIDDEN_ONLY`,
  SORT: `${prefix}SORT`,
  DIRECTION: `${prefix}DIRECTION`,
  // data view
  DTC_DISPLAY: `${prefix}DTC_DISPLAY`,
  // trigger
  LOAD_FILES: `${prefix}LOAD_FILES`,
  INITIALIZE_FILES: `${prefix}INITIALIZE_FILES`,
  UPLOAD_FILE: `${prefix}UPLOAD_FILE`,
  CLEAR: `${prefix}CLEAR`,
  ARCHIVE_FILE: `${prefix}ARCHIVE_FILE`,
}))('@client-request-files/');

// selector
export const selector = (state: any) => state.clientRequestFiles;
// UI display constants
const DEFAULT_FILES_COUNT = 20;
const DEFAULT_PAGE = 0;
const DEFAULT_DTC_LENGTH = 16;
const FILES_COUNT_OPTIONS = [DEFAULT_FILES_COUNT, 50, 100, 250];
const MAX_FILES_UPLOAD_COUNT = 1;
const DEFAULT_FILES_STATUS = [0];
const FILE_INFO = "";
const HIDDEN_ONLY = false;
const SORT = 'updateDate';
const DIRECTION = 'desc';
export const DefaultTableOptions = {
  DEFAULT_FILES_COUNT,
  DEFAULT_PAGE,
  FILES_COUNT_OPTIONS,
  DEFAULT_DTC_LENGTH,
  MAX_FILES_UPLOAD_COUNT,
  DEFAULT_FILES_STATUS: DEFAULT_FILES_STATUS,
  FILE_INFO,
  HIDDEN_ONLY,
  SORT,
  DIRECTION
};

export interface IDtc {
  show?: boolean;
  data?: string;
}

export interface IClientFile {
  client_request_file_ : number;
  client_: string | number;
  username: string;
  first_name: string | null;
  last_name: string | null;
  mobile_phone: string | null;
  email: string;
  telegram_: string | null;
  comment: string | null;
  company: string | null;
  key: string;
  name: string;
  dtc: IDtc | null;
  request_date: string;
  update_date: string;
  result_file_sha256: string | null;
  status: string | number;
  actions: string;
  brand_name: string;
  ecu_name: string;
  archived_by_admin: boolean;
  mms_key: string | null;
  mms_file_type: string | null;
  slave_sha256: string | null;
  history_count: number;
  ticket_count: number;
}

interface IState {
  clientFiles: IClientFile[] | null;
  totalFilesCount: number;

  expectAnswer: boolean;
  expectFilesAnswer: boolean;
  expectUploadFileAnswer: boolean;

  page: number;
  filesPerPage: number;
  fileInfo: string | null;
  filesStatus: number[] | null;
  hiddenOnly: boolean | null;
  currentFileKey: string | null;
  uploadedFile: File | null;
  sort: string | null;
  direction: string | null;

  uploadFinished: boolean;
  uploadSuccess: boolean;
  uploadError: boolean;
}

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}
// state flow
export const updateExpectAnswerAction = (expectAnswer: boolean) => typedAction(TYPE.DATA, { expectAnswer });
export const updateExpectFilesAnswerAction = (expectFilesAnswer: boolean) => typedAction(TYPE.DATA, { expectFilesAnswer });
export const updateExpectUploadFileAnswerAction = (expectUploadFileAnswer: boolean) => typedAction(TYPE.DATA, { expectUploadFileAnswer });

export const updateClientFilesAction = (clientFiles: IClientFile[] | null) => typedAction(TYPE.DATA, { clientFiles });
export const updateTotalFilesCountAction = (totalFilesCount: number) => typedAction(TYPE.DATA, { totalFilesCount });
export const updatePageAction = (page: number) => typedAction(TYPE.DATA, { page });
export const updateFilesPerPageAction = (filesPerPage: number) => typedAction(TYPE.DATA, { filesPerPage });
export const updateCurrentFileKeyAction = (currentFileKey: string | null) => typedAction(TYPE.DATA, { currentFileKey });
export const updateUploadedFileAction = (uploadedFile: File | null) => typedAction(TYPE.DATA, { uploadedFile });

export const updateUploadFinishedAction = (uploadFinished: boolean) => typedAction(TYPE.DATA, { uploadFinished });
export const updateUploadSuccessAction = (uploadSuccess: boolean) => typedAction(TYPE.DATA, { uploadSuccess });
export const updateUploadErrorAction = (uploadError: boolean) => typedAction(TYPE.DATA, { uploadError });

export const updateFilesStatusAction = (fileStatus: number) => typedAction(TYPE.FILES_STATUS, { fileStatus });
export const updateFilesInfoAction = (fileInfo: string) => typedAction(TYPE.FILES_INFO, {fileInfo});
export const updateHiddenOnlyAction = (hiddenOnly: boolean) => typedAction(TYPE.HIDDEN_ONLY, {hiddenOnly});
export const updateSortAction = (sort: string) => typedAction(TYPE.SORT, {sort});
export const updateDirectionAction = (direction: string) => typedAction(TYPE.DIRECTION, {direction});

export const displayDtcAction = (fileKey: string) => typedAction(TYPE.DTC_DISPLAY, { fileKey });
// triggers
export const loadFilesAction = (count: number, page: number, status?: number[]) => typedAction(TYPE.LOAD_FILES, { count, page, status });
export const initializeFilesAction = () => typedAction(TYPE.INITIALIZE_FILES);
export const uploadFileAction = (notifyClient: boolean, approvePayment: boolean, comment: string) => typedAction(TYPE.UPLOAD_FILE, { notifyClient, approvePayment, comment });
export const clearStateAction = () => typedAction(TYPE.CLEAR);
export const archiveFileAction = (fileKey: string) => typedAction(TYPE.ARCHIVE_FILE, { fileKey });

type UserAction = ReturnType<
  | typeof updateExpectAnswerAction
  | typeof updateClientFilesAction
  | typeof updateTotalFilesCountAction
  | typeof displayDtcAction
  | typeof loadFilesAction
  | typeof initializeFilesAction
  | typeof archiveFileAction
>;

const initialState: IState = {
  clientFiles: null,
  totalFilesCount: 0,

  expectAnswer: false,
  expectFilesAnswer: false,
  expectUploadFileAnswer: false,

  page: DefaultTableOptions.DEFAULT_PAGE,
  filesPerPage: DefaultTableOptions.DEFAULT_FILES_COUNT,
  filesStatus: DefaultTableOptions.DEFAULT_FILES_STATUS,
  fileInfo: DefaultTableOptions.FILE_INFO,
  hiddenOnly: DefaultTableOptions.HIDDEN_ONLY,
  currentFileKey: null,
  uploadedFile: null,
  sort: DefaultTableOptions.SORT,
  direction: DefaultTableOptions.DIRECTION,

  uploadFinished: false,
  uploadSuccess: false,
  uploadError: false,
};

export function clientRequestFiles(state = initialState, action: UserAction): IState {
  switch (action.type) {
    default:
      return state;
    case TYPE.DATA:
      return { ...state, ...action.payload };
    case TYPE.DTC_DISPLAY:
      return _handleDtcDisplay(state, action.payload);
    case TYPE.FILES_STATUS:
      return _handleFilesStatus(state, action.payload);
    case TYPE.FILES_INFO:
      return _handleFileInfo(state, action.payload);
    case TYPE.HIDDEN_ONLY:
      return _handleHiddenOnly(state, action.payload);
    case TYPE.SORT:
      return _handleSort(state, action.payload);
    case TYPE.DIRECTION:
      return _handleDirection(state, action.payload);
    case TYPE.ARCHIVE_FILE:
      return _handleArchiveFile(state, action.payload);
    case TYPE.CLEAR:
      return initialState;
  }
}

const  _handleArchiveFile = (state: IState, payload: { fileKey: string }) => {
  const { clientFiles } = state;
  if (Array.isArray(clientFiles)) {
    let fileIdx = clientFiles.findIndex((fileItem: IClientFile) => fileItem.key === payload.fileKey);
    if (fileIdx  > -1)
    clientFiles[fileIdx].archived_by_admin = true;
  }
  return { ...state, clientFiles: clientFiles };
}

const _handleDtcDisplay = (state: IState, payload: { fileKey: string }) => {
  const { clientFiles } = state;
  let updatedFiles: IClientFile[] | null = clientFiles;
  if (Array.isArray(clientFiles)) {
    let file = clientFiles.find((fileItem: IClientFile) => fileItem.key === payload.fileKey);
    file = file ? { ...file, dtc: { data: file.dtc?.data, show: !file.dtc?.show } } : file;
    updatedFiles = file
      ? clientFiles.map((fileItem: IClientFile) => {
          if (fileItem.key === payload.fileKey) {
            return {...fileItem, ...file};
          }
          return fileItem;
        })
      : clientFiles;
  }
  return { ...state, clientFiles: updatedFiles };
};

const _handleFilesStatus = (state: IState, payload: { fileStatus: number }) => {
  return { ...state, filesStatus: [payload.fileStatus] };
};

const _handleFileInfo = (state: IState, payload: {fileInfo: string}) => {
  return {...state, fileInfo: payload.fileInfo}
}

const _handleHiddenOnly = (state: IState, payload: {hiddenOnly: boolean}) => {
  return {...state, hiddenOnly: payload.hiddenOnly}
}

const _handleSort = (state: IState, payload: {sort: string}) => {
  return {...state, sort: payload.sort}
}

const _handleDirection = (state: IState, payload: {direction: string}) => {
  return {...state, direction: payload.direction}
}
