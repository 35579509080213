// outsource dependencies
import React, {useEffect} from 'react';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
// UI
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

// local dependencies
import {
  // constants
  DefaultTableOptions,
  // interface
  IClientFile,
} from 'private-layout/client-request-files/reducer';
// constants
import { FileStatus } from 'constants/file';
// UI
import Preloader from 'components/Preloader';
import FileDtc from 'private-layout/client-request-files/components/FileDtc';
import FilePaginationActions from 'private-layout/client-request-files/components/FilePaginationActions';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  row: {
    maxWidth: 250,
  },
  rowCenter: {
    textAlign: 'center',
  },
  status_1: {
    color: 'black',
    fontWeight: 'bold'
  },
  status_2: {
    color: 'red',
    fontWeight: 'bold'
  },
  status_3: {
    color: 'blue',
    fontWeight: 'bold'
  },
  status_4: {
    color: 'gray',
    fontWeight: 'bold'
  },
  archivedByDdmin: {
    opacity: 0.2
  },
  slaveEncoded: {
    color: 'red'
  },
  actionText: {
    cursor: "pointer",
    color: "blue",
    textDecoration: "underline"
  }
});
interface IProps {
  totalFilesCount: number;
  clientFiles?: IClientFile[] | null;
  expectFilesAnswer?: boolean;
  page: number;
  filesPerPage: number;
  uploadAllowed: boolean;
  previousFileName: string;
  //
  updatePage?: (page: number) => void;
  updateFilesPerPage?: (filesPerPage: number) => void;
  loadFiles?: (count: number, page: number) => void;
  displayDtc?: (fileKey: string) => void;
  updateCurrentFileKey?: (currentFileKey: string | null) => void;
  updateUploadedFile?: (uploadedFile: File | null) => void;
  //
  handleOpenFileDialog?: () => void;
  handleLocalUploadErrorMessage?: (message: string | null) => void;
  handleOpenLocalUploadErrorDialog?: () => void;
  handleUploadAllowed: () => void;
  handleArchiveFile?: (fileKey: string) => void;
}

export default function FilesTable(props: IProps) {
  const {
    totalFilesCount,
    clientFiles,
    page,
    filesPerPage,
    expectFilesAnswer,
    uploadAllowed,
    previousFileName,
    //
    updatePage,
    updateFilesPerPage,
    displayDtc,
    loadFiles,
    updateCurrentFileKey,
    updateUploadedFile,
    //
    handleOpenFileDialog,
    handleLocalUploadErrorMessage,
    handleOpenLocalUploadErrorDialog,
    handleUploadAllowed,
    handleArchiveFile
  } = props;
  const classes = useStyles();
  const { getRootProps, acceptedFiles, fileRejections } = useDropzone({
    maxFiles: DefaultTableOptions.MAX_FILES_UPLOAD_COUNT,
    noClick: true,
    noKeyboard: true,
    // onDrop: (files, props) => console.log('onDrop', files, props),
  });

  const handleChangePage = (e: unknown, newPage: number) => {
    window.scrollTo(0, 0);
    loadFiles && loadFiles(filesPerPage, newPage);
    updatePage && updatePage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    window.scrollTo(0, 0);
    loadFiles && loadFiles(Number(event.target.value), DefaultTableOptions.DEFAULT_PAGE);
    updateFilesPerPage && updateFilesPerPage(Number(event.target.value));
    updatePage && updatePage(DefaultTableOptions.DEFAULT_PAGE);
  };
  // files action
  const handleFileDrop = (fileKey: string) => () => {
    handleUploadAllowed();
    updateCurrentFileKey && updateCurrentFileKey(fileKey);
  };
  const handleFileClick = (fileKey: string) => () => {
    handleUploadAllowed();
    updateCurrentFileKey && updateCurrentFileKey(fileKey);
  };
  useEffect(() => {
    if (acceptedFiles.length && uploadAllowed && previousFileName !== acceptedFiles[0]?.name) {
      updateUploadedFile && updateUploadedFile(acceptedFiles[0]);
      handleOpenFileDialog && handleOpenFileDialog();
    }
    if (fileRejections.length) {
      let errors: any[] = [];
      fileRejections.forEach((fRejection) => {
        fRejection.errors.forEach((error) => {
          errors.push(error.message);
        });
      });
      const errorMessage = [...new Set(errors)].join(', ');
      handleLocalUploadErrorMessage && handleLocalUploadErrorMessage(errorMessage);
      handleOpenLocalUploadErrorDialog && handleOpenLocalUploadErrorDialog();
    }
  }, [
    acceptedFiles,
    fileRejections,
    handleOpenFileDialog,
    handleOpenLocalUploadErrorDialog,
    handleLocalUploadErrorMessage,
    updateUploadedFile,
    uploadAllowed,
    previousFileName,
  ]);

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Контактные данные клиента</TableCell>
              <TableCell>Файл</TableCell>
              <TableCell className={classes.rowCenter}>DTC</TableCell>
              <TableCell className={classes.rowCenter}>Дата загрузки<br/>обновления</TableCell>
              <TableCell className={classes.rowCenter}>Статус</TableCell>
              <TableCell className={classes.rowCenter}>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!expectFilesAnswer &&
              Array.isArray(clientFiles) &&
              clientFiles.map((file: IClientFile) => {
                return (
                  <Tooltip title='Вы можете вручную перетащить файл для загрузки' key={file.key} arrow placement='right' enterDelay={1000} enterNextDelay={5000} leaveDelay={1000}>
                    <TableRow
                      className={file.archived_by_admin ? classes.archivedByDdmin : ''}
                      hover
                      {...getRootProps({
                        onDrop: handleFileDrop(file.key),
                        onClick: handleFileClick(file.key),
                      })}
                    >
                      <TableCell className={classes.row}>
                        <span>
                          <strong>ID:</strong> {file.client_}
                        </span>
                        <br />
                        <span>
                          <strong>Username:</strong> {file.username} {file.first_name && file.last_name ? `${file.first_name} ${file.last_name}` : ''}
                        </span>
                        <br />
                        {file.mobile_phone && (
                          <>
                            <span>
                              <strong>Телефон:</strong> {file.mobile_phone}
                            </span>
                            <br />
                          </>
                        )}
                        <span>
                          <strong>Email:</strong> {file.email}
                        </span>
                        <br />
                        {file.company && (
                          <>
                            <span>
                              <strong>Компания:</strong> {file.company}
                            </span>
                            <br />
                          </>
                        )}
                        {file.mms_key && (
                          <>
                            <span>
                              <strong>MMS Key:</strong> {file.mms_key}
                            </span>
                            <br />
                          </>
                        )}
                      </TableCell>
                      <TableCell className={classes.row}>
                        <span>
                          <strong>Ключ:</strong> {file.key}
                        </span>
                        <br />
                        <span>
                          <strong>Название:</strong> {(file.name || '').length > 30 ? file.name.substr(0, 30) + '...' : file.name}
                        </span>
                        <br />
                        <span>
                          <strong>ECU:</strong> {file.brand_name} - {file.ecu_name}
                        </span>
                        <br />
                        <span>
                          <strong>Действия:</strong> {file.actions}
                        </span>
                        {file.comment && (
                          <>
                            <br />
                            <span>
                              <strong>Комментарий:</strong> {file.comment}
                            </span>
                            <br />
                          </>
                        )}
                        {file.mms_file_type && (
                          <>
                            <br/>
                            <span className={classes.slaveEncoded}>
                              <strong>MMS Type:</strong> {file.mms_file_type}
                            </span>
                            <br />
                          </>
                        )}
                      </TableCell>
                      <TableCell className={classes.row}>
                        <FileDtc dtc={file.dtc} fileKey={file.key} handleClick={displayDtc} />
                      </TableCell>
                      <TableCell className={classes.row}>
                        {new Date((file.request_date + ' UTC').replace(/-/g, "/")).toLocaleString()}<br/>{new Date((file.update_date + ' UTC').replace(/-/g, "/")).toLocaleString()}
                      </TableCell>
                      <TableCell
                      // eslint-disable-next-line
                        className={`${classes.row} ${eval('classes.status_' + file.status)} ${classes.rowCenter}`}
                      >
                        {FileStatus[Number(file.status)]}<br/>
                        {Boolean(file.result_file_sha256) ? 'Файл обработан' : 'Файл НЕ обработан'}
                      </TableCell>
                      <TableCell className={classes.rowCenter}>
                        <Link to={"/file/" + file.key}>
                          Info
                        </Link>
                        <br/>

                        <div className={classes.actionText} onClick={() => handleArchiveFile ? handleArchiveFile(file.key) : {}}>
                            Скрыть
                        </div>
                      </TableCell>
                    </TableRow>
                  </Tooltip>
                )})}
          </TableBody>
        </Table>
      </TableContainer>
      {expectFilesAnswer && <Preloader centered />}

      <TablePagination
        rowsPerPageOptions={DefaultTableOptions.FILES_COUNT_OPTIONS}
        component='div'
        count={totalFilesCount}
        rowsPerPage={filesPerPage}
        labelRowsPerPage={<strong>Файлов на странице: </strong>}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={FilePaginationActions}
      />
    </Paper>
  );
}
