// outsource dependencies
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// local dependencies
import { CONSTRAINT } from '../constants/style';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#969595',
    minHeight: CONSTRAINT.FOOTER,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16
  },
  content: {
    maxWidth: CONSTRAINT.MAX_CONTENT_SIZE,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  sn: {
    position: 'absolute',
    left: 0,
  },
  coreLink: {
    marginLeft: 20,
  },
});

const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.sn}>
        </div>
      </div>
    </div>
  );
};

export default Footer;
