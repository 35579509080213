// outsource dependencies
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// UI
import { ToastContainer, toast } from 'react-toastify';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// local dependencies
// state
import {
  selector as fwEcusSelector,
  // actions
  updateFwEcusDataAction,
  updateFwNameAction,
  updateFwEcuBrandAction,
  updateFwNiceNameAction,
  // trigger
  updateFwEcuAction,
  loadEcusAction,
  initializeFwEcusAction,
  clearStateAction,
  addNewFwEcuAction,
  // interface
  IFwEcu,
  IFwEcuBrand,
} from 'private-layout/fw-ecu/reducer';
// UI
import FwEcusTable from 'private-layout/fw-ecu/components/FwEcusTable';
import Layout from 'components/layout/Common';
import Preloader from 'components/Preloader';

const useStyles = makeStyles({
  container: {
    minHeight: '80vh',
  },
  title: {
    padding: '16px 0 0 16px',
  },
});
interface IProps {
  expectAnswer?: boolean;
  expectFwEcusAnswer?: boolean;

  fwEcuBrandsList?: IFwEcuBrand[] | null;
  fwEcusList?: IFwEcu[] | null;
  totalEcusCount?: number;

  page?: number;
  ecusPerPage?: number;

  fwUpdateSuccess?: boolean;
  fwUpdateError?: boolean;

  new_fw_name?: string;
  new_fw_ecu_brand_?: string;
  new_nice_name?: string;

  newFwAddSuccess?: boolean;
  newFwAddError?: boolean;
  // action
  updatePage?: (page: number) => void;
  updateEcusPerPage?: (ecusPerPage: number) => void;

  clearSuccessFeedback?: () => void;
  clearErrorFeedback?: () => void;

  updateFwName?: (fw_ecu_: number, fw_name: string) => void;
  updateFwEcuBrand?: (fw_ecu_: number, fw_ecu_brand_: string) => void;
  updateFwNiceName?: (fw_ecu_: number, nice_name: string) => void;

  clearSuccessAddNewFeedback?: () => void;
  clearErrorAddNewFeedback?: () => void;

  updateNewFwName?: (new_fw_name?: string) => void;
  updateNewEcuBrand?: (new_fw_ecu_brand_?: string) => void;
  updateNewNiceName?: (new_nice_name?: string) => void;
  // trigger
  loadEcus?: (count: number, page: number) => void;
  updateFwEcuAction?: (fw_ecu_: number) => void;
  addNewFwEcu?: () => void;
  initializeFwEcus?: () => void;
  clearState?: () => void;
}

function FwEcu(props: IProps) {
  const {
    expectAnswer,
    expectFwEcusAnswer,
    //
    fwEcuBrandsList,
    fwEcusList,
    totalEcusCount,
    //
    page,
    ecusPerPage,
    //
    fwUpdateSuccess,
    fwUpdateError,
    newFwAddSuccess,
    newFwAddError,
    //
    new_fw_name,
    new_fw_ecu_brand_,
    new_nice_name,
    // action
    updatePage,
    updateEcusPerPage,
    //
    clearSuccessFeedback,
    clearErrorFeedback,
    clearSuccessAddNewFeedback,
    clearErrorAddNewFeedback,
    //
    updateFwName,
    updateFwEcuBrand,
    updateFwNiceName,
    //
    updateNewFwName,
    updateNewEcuBrand,
    updateNewNiceName,
    // trigger
    updateFwEcuAction,
    loadEcus,
    addNewFwEcu,
    initializeFwEcus,
    clearState,
  } = props;
  const classes = useStyles();

  const handleUpdateSuccessFeedback = () => {
    toast.success('Изменения успешно сохранены');
  };
  const handleUpdateErrorFeedback = () => {
    toast.error('Не удалось сохранить изменения');
  };
  const handleAddNewSuccessFeedback = () => {
    toast.success('Файл успешно сохранен');
  };
  const handleAddNewErrorFeedback = () => {
    toast.error('Не удалось сохранить изменения');
  };
  // edit fw ecu watcher
  useEffect(() => {
    if (fwUpdateSuccess) {
      handleUpdateSuccessFeedback();
      clearSuccessFeedback && clearSuccessFeedback();
    }
    if (fwUpdateError) {
      handleUpdateErrorFeedback();
      clearErrorFeedback && clearErrorFeedback();
    }
  }, [fwUpdateSuccess, fwUpdateError, clearErrorFeedback, clearSuccessFeedback]);
  // add new fw ecu watcher
  useEffect(() => {
    if (newFwAddSuccess) {
      handleAddNewSuccessFeedback();
      clearSuccessAddNewFeedback && clearSuccessAddNewFeedback();
    }
    if (newFwAddError) {
      handleAddNewErrorFeedback();
      clearErrorAddNewFeedback && clearErrorAddNewFeedback();
    }
  }, [newFwAddSuccess, newFwAddError, clearErrorAddNewFeedback, clearSuccessAddNewFeedback]);
  // mount / un mount
  useEffect(() => {
    initializeFwEcus && initializeFwEcus();
    return () => {
      clearState && clearState();
    };
  }, [initializeFwEcus, clearState]);

  return (
    <>
      <Layout noHeightConstraint>
        {expectAnswer && <Preloader pageCentered />}
        {!expectAnswer && (
          <Card variant='outlined' className={classes.container}>
            <Typography className={classes.title} variant='h4'>
              FW ECU
            </Typography>
            <CardContent>
              <FwEcusTable
                page={Number(page)}
                filesPerPage={Number(ecusPerPage)}
                totalEcusCount={Number(totalEcusCount)}
                fwEcuBrandsList={fwEcuBrandsList}
                fwEcusList={fwEcusList}
                updatePage={updatePage}
                updateEcusPerPage={updateEcusPerPage}
                expectFwEcusAnswer={expectFwEcusAnswer}
                loadEcus={loadEcus}
                updateFwName={updateFwName}
                updateFwEcuBrand={updateFwEcuBrand}
                updateFwNiceName={updateFwNiceName}
                updateFwEcuAction={updateFwEcuAction}
                updateNewFwName={updateNewFwName}
                updateNewEcuBrand={updateNewEcuBrand}
                updateNewNiceName={updateNewNiceName}
                new_fw_name={new_fw_name}
                new_fw_ecu_brand_={new_fw_ecu_brand_}
                new_nice_name={new_nice_name}
                addNewFwEcu={addNewFwEcu}
              />
            </CardContent>
          </Card>
        )}
      </Layout>
      <ToastContainer />
    </>
  );
}

export default connect(
  (state) => fwEcusSelector(state),
  (dispatch) => ({
    updatePage: (page: number) => dispatch(updateFwEcusDataAction({ page })),
    updateEcusPerPage: (ecusPerPage: number) => dispatch(updateFwEcusDataAction({ ecusPerPage })),

    clearSuccessFeedback: () => dispatch(updateFwEcusDataAction({ fwUpdateSuccess: false })),
    clearErrorFeedback: () => dispatch(updateFwEcusDataAction({ fwUpdateError: false })),

    updateNewFwName: (new_fw_name?: string) => dispatch(updateFwEcusDataAction({ new_fw_name })),
    updateNewEcuBrand: (new_fw_ecu_brand_?: string) => dispatch(updateFwEcusDataAction({ new_fw_ecu_brand_ })),
    updateNewNiceName: (new_nice_name?: string) => dispatch(updateFwEcusDataAction({ new_nice_name })),

    clearSuccessAddNewFeedback: () => dispatch(updateFwEcusDataAction({ newFwAddSuccess: false })),
    clearErrorAddNewFeedback: () => dispatch(updateFwEcusDataAction({ newFwAddError: false })),

    updateFwName: (fw_ecu_: number, fw_name: string) => dispatch(updateFwNameAction(fw_ecu_, fw_name)),
    updateFwEcuBrand: (fw_ecu_: number, fw_ecu_brand_: string) => dispatch(updateFwEcuBrandAction(fw_ecu_, fw_ecu_brand_)),
    updateFwNiceName: (fw_ecu_: number, nice_name: string) => dispatch(updateFwNiceNameAction(fw_ecu_, nice_name)),

    loadEcus: (count: number, page: number) => dispatch(loadEcusAction(count, page)),
    updateFwEcuAction: (fw_ecu_: number) => dispatch(updateFwEcuAction(fw_ecu_)),
    addNewFwEcu: () => dispatch(addNewFwEcuAction()),
    initializeFwEcus: () => dispatch(initializeFwEcusAction()),
    clearState: () => dispatch(clearStateAction()),
  })
)(FwEcu);
