import React, {useEffect, useState} from 'react';
import currentConfig from "../../../utils/config.utils";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {toast} from "react-toastify";
import { Link } from 'react-router-dom';

interface IProps {
  fileKey: string
  changeCount?: (count: number) => void
  isSuperAdmin?: boolean
}

function History({fileKey, changeCount, isSuperAdmin}: IProps) {

  const [filesHistory, setHistory] = useState<any>([]);

  useEffect(() => {
    if (!fileKey) {
      return
    }
    const config = currentConfig();
    fetch(config.apiUrl + 'admin/files/history', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify({fileKey: fileKey})
    }).then(response => response.json())
      .then(data => {
        setHistory(data.response.filesHistory);
        changeCount && changeCount(data.response.filesHistory.length)
      })
      .catch(err => {
        console.log('error:', err)
        toast.error(err);
      })
  }, [fileKey, changeCount])

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Имя Файла</TableCell>
              <TableCell>Админ</TableCell>
              <TableCell>Дата добавления</TableCell>
              <TableCell>Коммент</TableCell>
              <TableCell>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filesHistory.map((fileHistory: any) => {
              const downloadLinkBin =
                currentConfig().apiUrl + "file/download/history/" + fileHistory.admin_upload_file_log_ + "/no/"

              const downloadLinkSlave = fileHistory.slave_sha256 ?
                currentConfig().apiUrl + "file/download/history/" + fileHistory.admin_upload_file_log_ + "/yes/" : null;

              return (
                <TableRow style={{backgroundColor: "#FFFFFF"}} key={fileHistory.admin_upload_file_log_}>
                  <TableCell>{fileHistory.name}</TableCell>
                  <TableCell>{fileHistory.username}</TableCell>
                  <TableCell>{new Date((fileHistory.add_date + ' UTC').replace(/-/g, "/")).toLocaleString()}</TableCell>
                  <TableCell>{fileHistory.admin_comment}</TableCell>
                  <TableCell>
                    <div>
                      <a href={downloadLinkBin} target={"_blank"} rel="noopener noreferrer">Скачать bin</a>
                    </div>
                    <div>
                      {downloadLinkSlave ?
                        <a href={downloadLinkSlave} target={"_blank"} rel="noopener noreferrer">Скачать slave</a> : null}
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isSuperAdmin && (
        <>
          <br/>
          <Link to={'/client-history-compare/' + fileKey}>Перейти на страницу сравнения</Link>
        </>
      )}
    </>
  )
}

export default History