import React, {useEffect, useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TableCell,
    TextField
} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import currentConfig from "../../../utils/config.utils";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ConfirmButton from "../../../components/button/ConfirmButton";
import CancelButton from "../../../components/button/CancelButton";
import {makeStyles} from "@material-ui/core/styles";

interface IProps {
    config: any;
    changeTableContent: () => void;
}
const useStyles = makeStyles({
   deleteIcon: {
       marginLeft: "20px",
       cursor: "pointer"
   }
});

const FwDtcConfigTableRow = (props: IProps) => {
    const {config, changeTableContent} = props;
    const classes = useStyles();

    const [configState, setConfigState] = useState<any>(null);
    const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false);


    const isEditedRow = () => {
        return JSON.stringify(configState) !== JSON.stringify(config);
    }

    const dtcChangeHandle = (event: any) => {
        setConfigState({
            ...configState,
            dtc: event.target.value
        })
    }

    const onSaveClickHandle = () => {
        if (isEditedRow()) {
            const config = currentConfig();
            fetch(config.apiUrl + `update_fw_dtc_config/${configState.fw_dtc_config_}`, {
                method: 'put',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify({
                    fw_dtc_config_: configState.fw_dtc_config_,
                    dtc: configState.dtc
                })
            })
                .then(response => response.json())
                .then(() => {
                    changeTableContent();
                })
                .catch(err => {
                    console.log('error:', err)
                })

        }
    }

    const removeFwDtcConfig = () => {
        const config = currentConfig();
        fetch(config.apiUrl + `delete_fw_dtc_config/${configState.fw_dtc_config_}`, {
            method: 'delete',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
        })
            .then(response => response.json())
            .then(() => {
                changeTableContent();
            })
            .catch(err => {
                console.log('error:', err)
            })
    }

    useEffect(() => {
        setConfigState(JSON.parse(JSON.stringify(config)));
    }, [config])


    return (
        <TableRow>
            <TableCell>
                {configState?.fw_dtc_config_}
            </TableCell>
            <TableCell>
                {configState?.fw_name}
            </TableCell>
            <TableCell>
                {configState?.name}
            </TableCell>
            <TableCell>
                <TextField multiline={true} onChange={dtcChangeHandle} value={configState?.dtc} fullWidth={true}/>
            </TableCell>
            <TableCell>
                <SaveIcon onClick={onSaveClickHandle} style={isEditedRow() ? {cursor: "pointer"} : {cursor: "auto"}} color={isEditedRow() ? 'primary' : 'disabled'}/>
                <RemoveCircleOutlineIcon className={classes.deleteIcon} color={"secondary"} onClick={() => setOpenRemoveDialog(true)}/>
            </TableCell>

                <Dialog open={openRemoveDialog}>
                    <DialogTitle>Подтвердите действие</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Вы действительно хотите удалить обьект?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <ConfirmButton onClick={removeFwDtcConfig}>Удалить</ConfirmButton>
                        <CancelButton style={{marginLeft: "20px"}}
                                      onClick={() => setOpenRemoveDialog(false)}>Отменить</CancelButton>
                    </DialogActions>
                </Dialog>
        </TableRow>
    )
}

export default FwDtcConfigTableRow;
