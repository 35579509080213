import React from 'react';
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    confirmButton: {
        width: "170px",
    }
});

interface IProps {
    children: any;
    disabled?: boolean;
    onClick: (event: any) => void;
    style?: any;
}

function CancelButton(props: IProps) {
    const {disabled, onClick, children, style} = props;
    const classes = useStyles();

    return <Button className={classes.confirmButton} variant={"contained"} color={"secondary"}
                   onClick={onClick} disabled={disabled} style={style}>{children}</Button>
}

export default CancelButton;
