import React, {useEffect, useState} from "react";
import ConfirmButton from "../../../components/button/ConfirmButton";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, MenuItem,
    TextField,
    Typography
} from "@material-ui/core";
import CancelButton from "../../../components/button/CancelButton";
import currentConfig from "../../../utils/config.utils";
import {makeStyles} from "@material-ui/core/styles";

interface IProps {
    changeTableContent: () => void;
}

const useStyles = makeStyles({
    headerActions: {
        marginLeft: "20px",
        display: "flex",
        columnGap: "20px"
    }
});

const FwDtcConfigHeader = (props: IProps) => {
    const {changeTableContent} = props;
    const classes = useStyles();

    const [configSettings, setConfigSettings] = useState<any>(null);
    const [openNewFwDtcConfig, setOpenNewFwDtcConfig] = useState<boolean>(false);
    const [newFwDtcConfig, setNewFwDtcConfig] = useState<any>({
        fw_ecu_: 0,
        st_action_: 0,
        dtc: "",
    });

    const initUrl = new URL(window.location.href);

    const [filterData, setFilterData] = useState<any>({
        fw_ecu_: initUrl.searchParams.get("fw_ecu_") === null ? "" : initUrl.searchParams.get("fw_ecu_"),
        st_action_: initUrl.searchParams.get("st_action_") === null ? "" : initUrl.searchParams.get("st_action_"),
        dtc: initUrl.searchParams.get("dtc") === null ? "" : initUrl.searchParams.get("dtc"),
    })

    const changeFilterDataHandle = (event: any, field: string) => {
        setFilterData({
            ...filterData,
            [field]: event.target.value
        })
    }

    const isBtnDisabled = () => {
        return newFwDtcConfig.fw_ecu_ === 0
            || newFwDtcConfig.st_action_ === 0
            || newFwDtcConfig.dtc === null
            || newFwDtcConfig.dtc.trim().length === 0;
    }

    const changeNewFwDtcConfigHandle = (event: any, field: string) => {
        setNewFwDtcConfig({
            ...newFwDtcConfig,
            [field]: event.target.value
        })
    }

    const handleOnClickFilter = () => {
        let url = new URL(window.location.href);
        url.searchParams.set("page", "1");
        url.searchParams.set("fw_ecu_", filterData.fw_ecu_.toString());
        url.searchParams.set("st_action_", filterData.st_action_.toString());
        url.searchParams.set("dtc", filterData.dtc);

        window.history.replaceState(null, "", url.href);
        changeTableContent();
    }

    const handleOnClickCancelFilter = () => {
        let url = new URL(window.location.href);
        url.searchParams.set("page", "1");
        url.searchParams.set("fw_ecu_", "");
        url.searchParams.set("st_action_", "");
        url.searchParams.set("dtc", "");

        window.history.replaceState(null, "", url.href);

        setFilterData({
            fw_ecu_: "",
            st_action_: "",
            dtc: "",
        })

        changeTableContent();
    }

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            handleOnClickFilter();
        }
    }

    const fetchConfigSettings = () => {
        const config = currentConfig();
        fetch(config.apiUrl + 'fw_dtc_config_settings', {
            method: 'get',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
        })
            .then(response => response.json())
            .then((response) => {
                setConfigSettings(response.response.settings);
            })
            .catch(err => {
                console.log('error:', err)
            })
    }

    const openNewFwEcuConfigDialogHandle = () => {
        setOpenNewFwDtcConfig(true)
    }

    const closeNewFwEcuConfigDialogHandle = () => {
        setNewFwDtcConfig({
            fw_ecu_: 0,
            st_action_: 0,
            dtc: "",
        })

        setOpenNewFwDtcConfig(false);
    }

    const addNewItemHandle = () => {
        const config = currentConfig();
        fetch(config.apiUrl + 'fw_dtc_config', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                fw_ecu_: newFwDtcConfig.fw_ecu_,
                st_action_: newFwDtcConfig.st_action_,
                dtc: newFwDtcConfig.dtc,
            })
        })
            .then(response => response.json())
            .then(() => {
                setOpenNewFwDtcConfig(false);
                setNewFwDtcConfig({
                    fw_ecu_: 0,
                    st_action_: 0,
                    dtc: "",
                })
                changeTableContent();
            })
            .catch(err => {
                console.log('error:', err)
            })
    }


    useEffect(() => {
        fetchConfigSettings();
    }, [])

    return (
        <div>
            <div className={classes.headerActions}>
                <TextField onKeyDown={handleKeyDown}
                           onChange={(event) => changeFilterDataHandle(event, "fw_ecu_")}
                           value={filterData.fw_ecu_} label="Fw Ecu"/>
                <TextField onKeyDown={handleKeyDown}
                           onChange={(event) => changeFilterDataHandle(event, "st_action_")}
                           value={filterData.st_action_} label="St Action"/>
                <TextField onKeyDown={handleKeyDown}
                           onChange={(event) => changeFilterDataHandle(event, "dtc")}
                           value={filterData.dtc} label="Dtc"/>

                <ConfirmButton onClick={handleOnClickFilter}>Фильтовать</ConfirmButton>
                <CancelButton onClick={handleOnClickCancelFilter}>Сбросить</CancelButton>
                <ConfirmButton onClick={openNewFwEcuConfigDialogHandle}>New</ConfirmButton>
            </div>

            <Dialog open={openNewFwDtcConfig}>
                <DialogTitle>Новый Fw Dtc Config</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Заполните поля:
                    </DialogContentText>
                    <div>
                        <Typography>Fw Ecu</Typography>
                        <TextField onChange={(event) => changeNewFwDtcConfigHandle(event, 'fw_ecu_')}
                                   value={newFwDtcConfig.fw_ecu_} fullWidth={true} select>
                            {configSettings?.fwEcuSettings?.map((config: any) => {
                                return (
                                    <MenuItem key={config.fw_ecu_} value={config.fw_ecu_}>
                                        {config.fw_name}
                                    </MenuItem>
                                )
                            })}
                        </TextField>

                        <Typography>St Action</Typography>
                        <TextField onChange={(event) => changeNewFwDtcConfigHandle(event, 'st_action_')}
                                   value={newFwDtcConfig.st_action_} fullWidth={true} select>
                            {configSettings?.stActionSettings?.map((config: any) => {
                                return (
                                    <MenuItem key={config.st_action_} value={config.st_action_}>
                                        {config.name}
                                    </MenuItem>
                                )
                            })}
                        </TextField>

                        <Typography>DTC</Typography>
                        <TextField onChange={(event) => changeNewFwDtcConfigHandle(event, 'dtc')}
                                   value={newFwDtcConfig.dtc} fullWidth={true} multiline={true}/>
                    </div>
                </DialogContent>
                <DialogActions disableSpacing={true}>
                    <ConfirmButton disabled={isBtnDisabled()} onClick={addNewItemHandle}>Создать</ConfirmButton>
                    <CancelButton style={{marginLeft: "20px"}}
                                  onClick={closeNewFwEcuConfigDialogHandle}>Отменить</CancelButton>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default FwDtcConfigHeader;


