import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from '@material-ui/core/TableBody';
import TableContainer from "@material-ui/core/TableContainer";
import {TableCell, ThemeProvider} from '@material-ui/core'
import {createTheme} from '@material-ui/core/styles';
import StActionTableRow from "./StActionTableRow";

const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '16px 6px 16px 8px',
                fontSize: "0.82rem",
                letterSpacing: "0.01061em"
            },
        },
    },
});


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    row: {
        maxWidth: 250,
    },
    rowCenter: {
        textAlign: 'center',
    },
    status_1: {
        color: 'black',
        fontWeight: 'bold'
    },
    status_2: {
        color: 'red',
        fontWeight: 'bold'
    },
    status_3: {
        color: 'blue',
        fontWeight: 'bold'
    },
    status_4: {
        color: 'gray',
        fontWeight: 'bold'
    },
    archivedByDdmin: {
        opacity: 0.2
    },
    slaveEncoded: {
        color: 'red'
    },
    checkCircleIcon: {
        marginLeft: '5px',
        width: '16px',
        height: '16px',
        color: '#4caf50',
        marginBlock: '-3px'
    },
    telegramIcon: {
        marginLeft: '5px',
        width: '25px',
        height: '25px',
        color: '#115293',
        marginBlock: '-6px'
    },
    editIcon: {
        marginRight: '10px',
        "&:hover": {
            cursor: "pointer"
        }
    },
    blockUnblockIcon: {
        "&:hover": {
            cursor: "pointer"
        }
    }
});

export interface IAction {
    approved: boolean
    config: string
    dtc_config: string
    grabber_key: string
    key: string
    link: string
    name: string
    st_action_: number
}

interface IProps {
    actions: {
        actions: IAction[]
    }
    changeTableContent: () => void
}

function StActionTable(props: IProps) {
    const {actions, changeTableContent} = props;

    const classes = useStyles();

    return (
        <div>
            {actions ?
                <Paper className={classes.root}>
                    <TableContainer>
                        <ThemeProvider theme={theme}>
                            <Table stickyHeader={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="30px">
                                            #
                                        </TableCell>
                                        <TableCell width="100px">
                                            name
                                        </TableCell>
                                        <TableCell width="100px">
                                            key
                                        </TableCell>
                                        <TableCell width="100px">
                                            link
                                        </TableCell>
                                        <TableCell width="50px">
                                            approved
                                        </TableCell>
                                        <TableCell width="150px">
                                            grabber_key
                                        </TableCell>
                                        <TableCell width="150px">
                                            config
                                        </TableCell>
                                        <TableCell>
                                            dtc_config
                                        </TableCell>

                                        <TableCell width="90px">
                                            -
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {actions.actions.map((action) => {
                                        return (
                                            <StActionTableRow key={action.st_action_} changeTableContent={changeTableContent} action={action}/>
                                        )
                                    })}

                                </TableBody>
                            </Table>
                        </ThemeProvider>
                    </TableContainer>

                </Paper> : null}
        </div>
    )
}


export default StActionTable;
