import localConf from 'environment/local';
import devConf from 'environment/development';
import conf from 'environment/production';

function currentConfig() {
    let processEnv = process.env.REACT_APP_ENV;
    processEnv = processEnv ? processEnv.trim() : processEnv;

    if (processEnv === conf.appEnv) {
        return conf;
    } else if (processEnv === devConf.appEnv) {
        return devConf;
    } else {
        return localConf;
    }
}

export default currentConfig;
