// outsource dependencies
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
// UI
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
// local dependencies
import { selector as app, IUser, userLogOutAction, appNavigateAction } from 'reducers';
// UI
// constants
import { CONSTRAINT, APP_COLOR } from 'constants/style';
import { PUBLIC as PUBLIC_ROUTE, ROUTE_LIST } from 'constants/routes';
import {getPermission} from "../utils/permission.utils";

const useStyles = makeStyles({
  container: {
    minHeight: CONSTRAINT.HEADER,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3E3E3E',
  },
  content: {
    maxWidth: CONSTRAINT.MAX_CONTENT_SIZE,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    color: APP_COLOR.WHITE,
    fontSize: 16,
    '&:hover': {
      color: APP_COLOR.WHITE,
    },
    marginRight: 8,
    textDecoration: 'none',
  },
  logo: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
  },
  actionBtn: {
    color: APP_COLOR.WHITE,
  },
  rightIndent8: {
    marginRight: 8,
  },
  separator: {
    fontSize: 26,
    marginRight: 8,
  },
  menuOpen: {
    color: APP_COLOR.WHITE,
  },
});

interface IProps {
  handleLoginOpen: () => void;
  handleSignUpOpen: () => void;
  appNavigate?: () => void;
  user?: IUser | null;
  userLogOut?: () => void;
}

const Header: React.FC<any> = (props: IProps) => {
  const { user, userLogOut, appNavigate } = props;
  const classes = useStyles();
  const history = useHistory();
  const isUserLoggedIn = Boolean(user);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleNavigation = (route: string) => () => {
    appNavigate && appNavigate();
    history.push(route);
  };
  const handleUserLogOut = () => {
    userLogOut && userLogOut();
  };
  return (
    <AppBar className={classes.container} position='sticky'>
      <div className={classes.content}>
        <div className={classes.logo}>
          <Link className={classes.link} to={PUBLIC_ROUTE.LOG_IN} onClick={appNavigate}>
            <img src='/LOGO-02.svg' alt='Home' height='50px' style={{ marginTop: '5px' }} />
          </Link>{' '}
          <Typography variant='h4'>Admin</Typography>
        </div>
        <div className={classes.navigation}>
          {isUserLoggedIn && (
            <>
              <Button onClick={handleMenuOpen} className={classes.menuOpen}>
                <MenuIcon />
              </Button>
              <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {ROUTE_LIST.map((rlItem, index) => (
                  getPermission(user?.role, rlItem.permissions) ? <MenuItem key={index} onClick={handleNavigation(rlItem.route)}>
                    {rlItem.title}
                  </MenuItem> : null
                ))}

                <MenuItem onClick={handleUserLogOut}>Выйти</MenuItem>
              </Menu>
            </>
          )}
          {!isUserLoggedIn && <></>}
        </div>
      </div>
    </AppBar>
  );
};

export default connect(
  (state) => ({ user: app(state).user }),
  (dispatch) => ({
    userLogOut: () => dispatch(userLogOutAction()),
    appNavigate: () => dispatch(appNavigateAction()),
  })
)(Header);
