// types
export const TYPE = ((prefix) => ({
  PREFIX: new RegExp(prefix, 'i'),
  // complex actions
  DATA: `${prefix}DATA`,
  // state
  ERROR_CREATE: `${prefix}ERROR_CREATE`,
  RESET_CREATE_ERROR: `${prefix}RESET_CREATE_ERROR`,
  ERROR_EDIT: `${prefix}ERROR_EDIT`,
  RESET_EDIT_ERROR: `${prefix}RESET_EDIT_ERROR`,
  // trigger
  LOAD_SOFT_GROUP: `${prefix}LOAD_SOFT_GROUP`,
  INITIALIZE_FILES: `${prefix}INITIALIZE_FILES`,
  CLEAR: `${prefix}CLEAR`,
  SAVE_NEW_SOFT_GROUP: `${prefix}SAVE_NEW_SOFT_GROUP`,
  APPROVE_DELETE: `${prefix}APPROVE_DELETE`,
  EDIT_SOFT_GROUP: `${prefix}EDIT_SOFT_GROUP`,
}))('@fw-soft-group/');

// selector
export const selector = (state: any) => state.fwSoftGroup;
// UI display constants
const DEFAULT_FILES_COUNT = 50;
const DEFAULT_PAGE = 0;
const FILES_COUNT_OPTIONS = [DEFAULT_FILES_COUNT, 150, 250, 500];
export const DefaultTableOptions = {
  DEFAULT_FILES_COUNT,
  DEFAULT_PAGE,
  FILES_COUNT_OPTIONS,
};
export const CREATE_FIELD_KEY = {
  newFwEcu: 'newFwEcu',
  newFwKey: 'newFwKey',
  newConfig: 'newConfig',
  newApproved: 'newApproved',
};
export const EDIT_FIELD_KEY = {
  editedFwEcu: 'editedFwEcu',
  editedFwKey: 'editedFwKey',
  editedConfig: 'editedConfig',
  editedApproved: 'editedApproved',
};
export interface ICreateFormError {
  newFwEcu?: string | null;
  newFwKey?: string | null;
  newConfig?: string | null;
  newApproved?: string | null;
}
export interface ICreateForm {
  newFwEcu?: string | null;
  newFwKey?: string | null;
  newConfig?: string | null;
  newApproved?: boolean | null;
}
export interface IEditFormError {
  editedFwEcu?: string | null;
  editedFwKey?: string | null;
  editedConfig?: string | null;
  editedApproved?: string | null;
}
export interface IEditForm {
  editedFwEcu?: string | null;
  editedFwKey?: string | null;
  editedConfig?: string | null;
  editedApproved?: string | null;
}
export interface IFwSoftGroup {
  fw_soft_group_: number;
  fw_ecu_: number;
  fw_key: string;
  config: string;
  approved: boolean;
}
export interface IFwEcu {
  key: string;
  name: string;
}
interface IState {
  fwSoftGroupList?: IFwSoftGroup[] | null;
  totalSoftGroupCount?: number;
  fwEcuList?: IFwEcu[] | null;

  expectAnswer?: boolean;
  expectFwSoftGroupAnswer?: boolean;

  fwSoftGroupIdDelete?: number | null;
  fwSoftGroupIdEdit?: number | null;

  page?: number;
  filesPerPage?: number;
  fwSearchVal?: string;
  filterByFwEcu?: number | null;

  newFwEcu?: string | number | null;
  newFwKey?: string;
  newConfig?: string;
  newApproved?: boolean;

  editedFwEcu?: number | null;
  editedFwKey?: string;
  editedConfig?: string;
  editedApproved?: boolean;

  fwSoftGroupCreateSuccess?: boolean;
  fwSoftGroupCreateError?: boolean;
  fwSoftGroupDeleteSuccess?: boolean;
  fwSoftGroupDeleteError?: boolean;
  fwSoftGroupEditSuccess?: boolean;
  fwSoftGroupEditError?: boolean;

  createErrors?: ICreateFormError | null;
  editErrors?: IEditFormError | null;
}

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}
// state flow
export const updateFwSoftGroupDataAction = (data: IState) => typedAction(TYPE.DATA, data);

export const resetErrorCreateAction = (fieldKey: string) => typedAction(TYPE.RESET_CREATE_ERROR, { fieldKey });
export const updateErrorCreateAction = (createErrors: ICreateFormError) => typedAction(TYPE.ERROR_CREATE, createErrors);
export const resetErrorEditAction = (fieldKey: string) => typedAction(TYPE.RESET_EDIT_ERROR, { fieldKey });
export const updateErrorEditAction = (editErrors: IEditFormError) => typedAction(TYPE.ERROR_EDIT, editErrors);
// triggers
export const loadSoftGroupAction = () => typedAction(TYPE.LOAD_SOFT_GROUP);
export const initializeFwEcusAction = () => typedAction(TYPE.INITIALIZE_FILES);
export const clearStateAction = () => typedAction(TYPE.CLEAR);
export const saveNewSoftGroupAction = () => typedAction(TYPE.SAVE_NEW_SOFT_GROUP);
export const approveDeleteAction = () => typedAction(TYPE.APPROVE_DELETE);
export const editSoftGroupAction = () => typedAction(TYPE.EDIT_SOFT_GROUP);

type UserAction = ReturnType<
  | typeof updateFwSoftGroupDataAction
  //
  | typeof loadSoftGroupAction
  | typeof initializeFwEcusAction
  | typeof clearStateAction
>;

const initialState: IState = {
  fwSoftGroupList: null,
  totalSoftGroupCount: 0,

  expectAnswer: false,
  expectFwSoftGroupAnswer: false,

  fwSoftGroupIdDelete: null,
  fwSoftGroupIdEdit: null,

  page: DefaultTableOptions.DEFAULT_PAGE,
  filesPerPage: DefaultTableOptions.DEFAULT_FILES_COUNT,
  fwSearchVal: '', // fw_key or config
  filterByFwEcu: null,

  newFwEcu: null,
  newFwKey: '',
  newConfig: '',
  newApproved: false,

  editedFwEcu: null,
  editedFwKey: '',
  editedConfig: '',
  editedApproved: false,

  fwSoftGroupCreateSuccess: false,
  fwSoftGroupCreateError: false,
  fwSoftGroupDeleteSuccess: false,
  fwSoftGroupDeleteError: false,
  fwSoftGroupEditSuccess: false,
  fwSoftGroupEditError: false,

  createErrors: null,
  editErrors: null,
};

export function fwSoftGroup(state = initialState, action: UserAction): IState {
  switch (action.type) {
    default:
      return state;
    case TYPE.DATA:
      return { ...state, ...action.payload };
    case TYPE.CLEAR:
      return initialState;
    case TYPE.RESET_CREATE_ERROR:
      return _resetCreateError(state, action.payload);
    case TYPE.ERROR_CREATE:
      return _setCreateError(state, action.payload);
    case TYPE.RESET_EDIT_ERROR:
      return _resetEditError(state, action.payload);
    case TYPE.ERROR_EDIT:
      return _setEditError(state, action.payload);
  }
}

const _resetCreateError = (state: IState, error: { fieldKey: string }) => {
  const newErrors = state.createErrors ? { ...state.createErrors, [error.fieldKey]: null } : { [error.fieldKey]: null };
  return { ...state, createErrors: newErrors };
};
const _setCreateError = (state: IState, errors: ICreateFormError) => {
  const newErrors = state.createErrors ? { ...state.createErrors, ...errors } : errors;
  return { ...state, createErrors: newErrors };
};
const _resetEditError = (state: IState, error: { fieldKey: string }) => {
  const newErrors = state.editErrors ? { ...state.editErrors, [error.fieldKey]: null } : { [error.fieldKey]: null };
  return { ...state, editErrors: newErrors };
};
const _setEditError = (state: IState, errors: IEditFormError) => {
  const newErrors = state.editErrors ? { ...state.editErrors, ...errors } : errors;
  return { ...state, editErrors: newErrors };
};
