// outsource dependencies
import React, {useEffect, useMemo} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
// local dependencies
import { selector as app, userInitializeAction, IUser } from 'reducers';
import { history } from 'store';
// public pages
import LogInPage from 'public-layout/log-in';
// private pages
import FwEcuPage from 'private-layout/fw-ecu';
import ClientRequestFilesPage from 'private-layout/client-request-files';
import FwSoftPage from 'private-layout/fw-soft';
import FwSoftGroupPage from 'private-layout/fw-soft-group';
import Clients from 'private-layout/clients';
import ClientEdit from "../private-layout/client-edit";
// common pages
// UI
import Preloader from 'components/Preloader';
// constants
import { PUBLIC as PUBLIC_ROUTE, PRIVATE as PRIVATE_ROUTE } from 'constants/routes';
import FwDtcConfig from "../private-layout/fw_dtc_config";
import StAction from "../private-layout/st_action";
import {getPermission} from "../utils/permission.utils";
import PermissionDenied from "../components/PermissionDenied";
import ClientHistoryCompare from "../private-layout/client-history-compare";
import StActionBrandEcu from "../private-layout/st_action_brand_ecu";
import Ticket from "../private-layout/ticket";
import File from "../private-layout/file";
import PriceGroup from "../private-layout/price_group";
import RemoveDTC from 'private-layout/remove-dtc';
import StEcu from "../private-layout/st-ecu";
import Contacts from "../private-layout/contacts";
interface IProps {
  initialized?: boolean;
  userInitialize?: () => void;
  user?: IUser | null;
}

const Routes = (props: IProps) => {
  const { userInitialize, initialized, user } = props;
  const isUserLoggedIn = Boolean(user);

  useEffect(() => {
    userInitialize && userInitialize();
  }, [userInitialize]);

    const link = useMemo(() => {
        if (!isUserLoggedIn) {
            return PUBLIC_ROUTE.LOG_IN
        }
        const deepLink = localStorage.getItem('deep_link')
        if (!deepLink) {
            return PRIVATE_ROUTE.CLIENT_REQUEST_FILES
        }
        localStorage.removeItem('deep_link')
        const [pathname] = deepLink.split('?')
        const [, deepRoute] = pathname.split('/')
        const privateRoutes = Object.values(PRIVATE_ROUTE).map((route) => {
            const [, privateRoute] = route.split('/')
            return privateRoute
        })
        localStorage.removeItem('deep_link')
        if (privateRoutes.includes(deepRoute)) {
            return deepLink
        }
        return PRIVATE_ROUTE.CLIENT_REQUEST_FILES
    }, [isUserLoggedIn]);

    return (
    <ConnectedRouter history={history}>
      <>
        {initialized && isUserLoggedIn && (
          <Switch>
            <Route exact path={PRIVATE_ROUTE.FW_ECU}
                   component={() => getPermission(user?.role, ["superadmin"]) ? <FwEcuPage /> : <PermissionDenied/>} />
            <Route exact path={PRIVATE_ROUTE.CLIENT_REQUEST_FILES}
                   component={() => getPermission(user?.role,["superadmin", "filemanager"]) ?  <ClientRequestFilesPage /> : <PermissionDenied/>} />
            <Route exact path={PRIVATE_ROUTE.FW_SOFT}
                   component={() => getPermission(user?.role,["superadmin"]) ?  <FwSoftPage /> : <PermissionDenied/>} />
            <Route exact path={PRIVATE_ROUTE.FW_SOFT_GROUP}
                   component={() => getPermission(user?.role,["superadmin"]) ?  <FwSoftGroupPage /> : <PermissionDenied/>} />
            <Route exact path={PRIVATE_ROUTE.CLIENTS}
                   component={() => getPermission(user?.role,["superadmin"]) ?  <Clients/> : <PermissionDenied/>} />
            <Route exact path={PRIVATE_ROUTE.CLIENT_EDIT}
                   component={() => getPermission(user?.role,["superadmin"]) ?  <ClientEdit/> : <PermissionDenied/> }/>
            <Route exact path={PRIVATE_ROUTE.FW_DTC_CONFIG}
                   component={() => getPermission(user?.role,["superadmin"]) ?  <FwDtcConfig/> : <PermissionDenied/>} />
            <Route exact path={PRIVATE_ROUTE.ST_ACTION_BRAND_ECU}
                   component={() => getPermission(user?.role,["superadmin"]) ?  <StActionBrandEcu/> : <PermissionDenied/>} />
            <Route exact path={PRIVATE_ROUTE.TICKET}
                   component={() => getPermission(user?.role,["superadmin", "filemanager"]) ?  <Ticket/> : <PermissionDenied/>} />
            <Route exact path={PRIVATE_ROUTE.FILE}
                 component={() => getPermission(user?.role,["superadmin", "filemanager"]) ?  <File/> : <PermissionDenied/>} />
            <Route exact path={PRIVATE_ROUTE.ST_ACTION}
                   component={() => getPermission(user?.role,["superadmin"]) ?  <StAction/> : <PermissionDenied/>} />
            <Route exact path={PRIVATE_ROUTE.PRICE_GROUP}
                   component={() => getPermission(user?.role,["superadmin"]) ?  <PriceGroup/> : <PermissionDenied/>} />
            <Route exact path={PRIVATE_ROUTE.ST_ECU}
                   component={() => getPermission(user?.role,["superadmin"]) ?  <StEcu/> : <PermissionDenied/>} />
            <Route exact path={PRIVATE_ROUTE.CLIENT_HISTORY_COMPARE}
                   component={() => getPermission(user?.role,["superadmin"]) ?  <ClientHistoryCompare/> : <PermissionDenied/>} />
            <Route exact path={PRIVATE_ROUTE.REMOVE_DTC}
                   component={() => getPermission(user?.role,["superadmin"]) ?  <RemoveDTC/> : <PermissionDenied/>} />
            <Route exact path={PRIVATE_ROUTE.CONTACTS}
                   component={() => getPermission(user?.role,["superadmin"]) ?  <Contacts/> : <PermissionDenied/>} />

            <Route path='/'>
              <Redirect to={link} />
            </Route>
          </Switch>
        )}
        {initialized && !isUserLoggedIn && (
          <Switch>
            <Route exact path={PUBLIC_ROUTE.LOG_IN} component={() => <LogInPage />} />
            <Route path='/'>
              <Redirect to={PUBLIC_ROUTE.LOG_IN} />
            </Route>
          </Switch>
        )}
        {!initialized && <Preloader centered />}
      </>
    </ConnectedRouter>
  );
};

export default connect(
  (state) => app(state),
  (dispatch) => ({
    userInitialize: () => dispatch(userInitializeAction()),
  })
)(Routes);
