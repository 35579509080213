import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";

interface IProps {
    hex: string,
    hexDiff?: string,
    extClasses?: any,
    baseAddress?: string
}

const useStyles = makeStyles({
    span: {
        padding: '0px 2px',
        "&:hover, &:focus": {
            backgroundColor: 'yellow'
        }
    },
    matchHex: {
        
    },
    missmatchHex: {
        fontWeight: 'bold',
        color: '#5c5b5b !important'
    }
});

function PrettyHex({hex, hexDiff, extClasses, baseAddress}: IProps) {
    const classes = useStyles()
    let sHexDiff:any = hexDiff ? hexDiff.match(/.{2}/g) : null
    const baseAddressInt = baseAddress ? parseInt(baseAddress) : null
    const [hexStr, setHexStr] = useState<any>([])

    useEffect(() => {
        let sHex = hex.match(/.{2}/g)
        setHexStr(sHex)
    }, [hex])

    return (
        <>{hexStr.map((hex:string, idx:number) => <span key={idx} className={[classes.span, extClasses, (sHexDiff && sHexDiff[idx] !== hex) ? classes.missmatchHex : classes.matchHex].join(' ')} title={baseAddressInt ? '0x' + (baseAddressInt + idx).toString(16).toUpperCase().padStart(8, '0') : ''}>{hex}</span>)}</>
    )
}

export default PrettyHex