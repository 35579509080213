// outsource dependencies
import React, { useState } from 'react';
// UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
// local dependencies
import { IClientFile } from 'private-layout/client-request-files/reducer';
// constants
import { APP_COLOR } from 'constants/style';
// UI
import CustomCheckbox from 'components/checkbox/Checkbox';
import Preloader from 'components/Preloader';
import CustomTextareaAutosize from 'components/input/Textarea';

const useStyles = makeStyles({
  root: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  paperFullWidth: {
    width: 800,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 24px',
    backgroundColor: APP_COLOR.GREY,
    color: APP_COLOR.WHITE,
  },
  close: {
    padding: 6,
    color: APP_COLOR.WHITE,
    cursor: 'pointer',
  },
  titleContent: {
    lineHeight: 2,
  },
  infoTitle: {
    marginBottom: 16
  },
  container: {
    display: 'flex',
    padding: 12,
    width: '100%',
    flexDirection: 'column'
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  infoSection: {
    display: 'flex',
    width: '48%',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  continueAction: {
    backgroundColor: APP_COLOR.LIGHT_GREY,
    color: APP_COLOR.WHITE,
    '&:hover': {
      color: APP_COLOR.BLACK,
    },
    transition: '0.6s',
  },
  slaveEncoded: {
    color: 'red'
  }
});

interface IProps {
  open: boolean;
  currentFile?: IClientFile | null;
  uploadedFile?: File | null;
  expectUploadFileAnswer?: boolean;
  uploadFinished?: boolean;
  //
  handleClose: () => void;
  uploadFile?: (notifyClient: boolean, approvePayment: boolean, comment: string) => void;
  updateUploadFinished?: (uploadFinished: boolean) => void;
}

function FileInfoDialog(props: IProps) {
  const {
    open,
    currentFile,
    uploadedFile,
    expectUploadFileAnswer,
    uploadFinished,
    //
    handleClose,
    uploadFile,
  } = props;
  const classes = useStyles();

  const [approvePayment, setApprovePayment] = useState<boolean>(true);
  const [notifyClient, setNotifyClient] = useState<boolean>(true);
  const [fileComment, setFileComment] = useState<string>('');

  const handleApprovePayment = () => {
    setApprovePayment(!approvePayment);
  };
  const handleNotifyClient = () => {
    setNotifyClient(!notifyClient);
  };

  const handleFileComment = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileComment && setFileComment(e.target.value);
  };

  const handleFileUpload = () => {
    uploadFile && uploadFile(notifyClient, approvePayment, fileComment);
  };

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paperFullWidth: classes.paperFullWidth }} className={classes.root} fullWidth maxWidth='md'>
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <Typography variant='h6' className={classes.titleContent}>
          Загрузка файла
        </Typography>
        <CloseIcon onClick={handleClose} className={classes.close} />
      </DialogTitle>
      <DialogContent>
        <div className={classes.container}>
          <div className={classes.content}>
            {Boolean(currentFile) && (
              <div className={classes.infoSection}>
                <Typography variant='h6' className={classes.infoTitle}>
                  <strong>Данные пользователя</strong>
                </Typography>
                <span>
                  <strong>ID</strong> - {currentFile?.client_}
                </span>
                <br />
                <span>
                  <strong>Username</strong> - {currentFile?.username}
                  {currentFile?.first_name && currentFile?.last_name ? `${currentFile?.first_name} ${currentFile?.last_name}` : ''}
                </span>
                <br />
                {currentFile?.mobile_phone && (
                  <>
                    <span>
                      <strong>Телефон</strong> - {currentFile?.mobile_phone}
                    </span>
                    <br />
                  </>
                )}
                <span>
                  <strong>Email</strong> - {currentFile?.email}
                </span>
                <br />
                {currentFile?.company && (
                  <>
                    <span>
                      <strong>Компания</strong> - {currentFile?.company}
                    </span>
                    <br />
                  </>
                )}
                <Typography variant='h6' className={classes.infoTitle}>
                  <strong>Данные файла</strong>
                </Typography>
                <span>
                  <strong>Ключ</strong> - {currentFile?.key}
                </span>
                <br />
                <span>
                  <strong>Название</strong> - {currentFile?.name}
                </span>
                <br />
                <span>
                  <strong>Файл обработан</strong> - {Boolean(currentFile?.result_file_sha256) ? 'Да' : 'Нет'}
                </span>
                <br />
                {currentFile?.comment && (
                  <>
                    <span>
                      <strong>Комментарий</strong> - {currentFile?.comment}
                    </span>
                    <br />
                  </>
                )}
              </div>
            )}
            {Boolean(uploadedFile) && (
              <div className={classes.infoSection}>
                <Typography variant='h6' className={classes.infoTitle}>
                  <strong>Данные загружаемого файла</strong>
                </Typography>
                <span>
                  <strong>Имя файла</strong> - {uploadedFile?.name}
                </span>
                <br />
                <span>
                  <strong>Размер</strong> - {uploadedFile?.size} bytes
                </span>
                <br />
                <CustomTextareaAutosize name='fileComment' label='Comment' onChange={handleFileComment} />
                <CustomCheckbox label='Разрешить клиенту скачивать файл' onChange={handleApprovePayment} checked={approvePayment} />
                <CustomCheckbox label='Уведомить клиента' onChange={handleNotifyClient} checked={notifyClient} />
                <br/>
                {currentFile?.mms_file_type && (
                  <span className={classes.slaveEncoded}> Внимание! Файл будет закодирован ключом  <b>{currentFile?.mms_key}</b>!</span>
                )}
              </div>
            )}
          </div>
          <div className={classes.actions}>
            {expectUploadFileAnswer && <Preloader />}
            {!expectUploadFileAnswer && (
              <>
                {uploadFinished && (
                  <Button onClick={handleClose} className={classes.continueAction}>
                    Закрыть
                  </Button>
                )}
                {!uploadFinished && (
                  <Button onClick={handleFileUpload} className={classes.continueAction}>
                    Загрузить
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default FileInfoDialog;
