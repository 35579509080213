import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import CustomCheckbox from "../../../components/checkbox/Checkbox";
import {makeStyles} from "@material-ui/core/styles";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    TextField
} from "@material-ui/core";
import ConfirmButton from "../../../components/button/ConfirmButton";
import CancelButton from "../../../components/button/CancelButton";
import currentConfig from "../../../utils/config.utils";

const useStyles = makeStyles({
    container: {
        padding: '16px 16px 0 16px'
    },
    filerContainer: {
        display: "flex"
    },
    typography: {
        marginBottom: "20px"
    },
    status: {
        width: "250px",
        marginLeft: "20px",
        marginBlock: "7px"
    },
    clientInfo: {
        width: "250px",
        marginLeft: "20px",
        marginBlock: "-8px"
    },
    filterIcon: {
        width: "30px",
        height: "30px",
        marginLeft: "auto",
        marginTop: "10px",
        "&:hover": {
            cursor: "pointer"
        }
    },
    checkboxContainer: {
        display: "grid"
    }
});

interface IProps {
    changeTableContent: () => void;
}

interface IClientHeaderState {
    mmsKey: boolean,
    bonus: boolean,
    status: string,
    clientInfo: string | null
}

const strToBoolean = (str: string | null) => {
    if (str === null) {
        return false;
    } else if (str.toLowerCase() === "true") {
        return true;
    }
    return false;
}

function ClientsHeader(props: IProps) {
    const {changeTableContent} = props;
    const classes = useStyles();

    const [openCsvModal, setOpenCsvModal] = useState<boolean>(false);
    const [exportState, setExportState] = useState<any>({
        id: true,
        username: true,
        last_name: true,
        first_name: true,
        company: true,
        email: true,
        mobile_phone: true,
        status: true,
        plan: true,
        mmsKey: true,
        telegram: true,
        bonus: true,
        date: true
    })

    let url = new URL(window.location.href);
    const urlStatus = url.searchParams.get("status");

    const [headerState, setHeaderState] = useState<IClientHeaderState>({
        mmsKey: strToBoolean(url.searchParams.get("mmskey")),
        bonus: strToBoolean(url.searchParams.get("bonus")),
        status: urlStatus === null ? "0" : urlStatus,
        clientInfo: url.searchParams.get("info"),
    })

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            handleOnClickFilter();
        }
    }

    const handleOnChangeMmsKey = (event: any) => {
        const changedHeaderState = {...headerState};
        changedHeaderState.mmsKey = event.target.checked;
        setHeaderState(changedHeaderState);
    }

    const handleOnChangeBonus = (event: any) => {
        const changedHeaderState = {...headerState};
        changedHeaderState.bonus = event.target.checked;
        setHeaderState(changedHeaderState);
    }

    const handleOnChangeStatus = (event: any) => {
        const changedHeaderState = {...headerState};
        changedHeaderState.status = event.target.value;
        setHeaderState(changedHeaderState);
    }

    const handleOnChangeClientInfo = (event: any) => {
        const changedHeaderState = {...headerState};
        changedHeaderState.clientInfo = event.target.value === "" ? null : event.target.value;
        setHeaderState(changedHeaderState);
    }

    const handleCloseModal = () => {
        setOpenCsvModal(false);
    }

    const handleOpenModal = () => {
        setOpenCsvModal(true);
    }

    const isChecked = (fieldName: string) => {
        return exportState[fieldName];
    }

    const handleCheckUncheck = (event: any, fieldName: string) => {
        const changedExportState = {...exportState};
        changedExportState[fieldName] = event.target.checked;
        setExportState(changedExportState);
    }

    const isExportBtnDisabled = () => {
        let disabled = true;
        Object.keys(exportState).forEach((key) => {
            if (exportState[key]) {
                disabled = false;
            }
        });
        return disabled;
    }

    const exportCsv = () => {
        const config = currentConfig();
        fetch(config.apiUrl + 'client/exportCsv', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: "include",
            body: JSON.stringify({
                data: exportState
            })
        })
            .then(response => response.blob())
            .then((response) => {
                const data = new Blob([response], {type: 'text/csv'});
                const csvURL = window.URL.createObjectURL(data);
                const tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', 'clients.csv');
                tempLink.click();
            });
    }

    const handleOnClickFilter = () => {
        let url = new URL(window.location.href);
        url.searchParams.set("page", "1");
        url.searchParams.set("mmskey", headerState.mmsKey.toString());
        url.searchParams.set("bonus", headerState.bonus.toString());
        url.searchParams.set("status", headerState.status);
        if (headerState.clientInfo !== null) {
            url.searchParams.set("info", headerState.clientInfo)
        } else {
            url.searchParams.set("info", "");
        }

        window.history.replaceState(null, "", url.href);
        changeTableContent();
    }

    const handleOnClickCancelFilter = () => {
        let url = new URL(window.location.href);
        url.searchParams.set("page", "1");
        url.searchParams.set("mmskey", "");
        url.searchParams.set("bonus", "");
        url.searchParams.set("status", "");
        url.searchParams.set("info", "");
        window.history.replaceState(null, "", url.href);
        changeTableContent();
    }

    return (
        <div className={classes.container}>
            <Typography className={classes.typography} variant='h5'>Фильтр по статусам</Typography>
            <div className={classes.filerContainer}>
                <CustomCheckbox checked={headerState.mmsKey}
                                key={"MMS_KEY"} label={"MMS ключ"}
                                onChange={handleOnChangeMmsKey}/>
                <CustomCheckbox checked={headerState.bonus}
                                key={"Bonus"} label={"Бонус"}
                                onChange={handleOnChangeBonus}/>

                <TextField fullWidth={true} className={classes.status}
                           id="status" value={headerState.status} select
                           onChange={handleOnChangeStatus}>
                    <MenuItem value="0">Статус</MenuItem>
                    <MenuItem value="1">New</MenuItem>
                    <MenuItem value="2">Confirmed</MenuItem>
                    <MenuItem value="3">Blocked</MenuItem>
                </TextField>

                <TextField value={headerState.clientInfo === null ? "" : headerState.clientInfo}
                           onKeyDown={handleKeyDown} className={classes.clientInfo} fullWidth={true}
                           label={"Информация о клиенте"}
                           onChange={handleOnChangeClientInfo}/>

                <ConfirmButton style={{marginLeft: "20px"}} onClick={handleOnClickFilter}>Фильтровать</ConfirmButton>
                <CancelButton style={{marginLeft: "20px"}} onClick={() => handleOnClickCancelFilter()}>Сбросить</CancelButton>
                <ConfirmButton style={{marginLeft: "20px"}} onClick={handleOpenModal}>Экспорт CSV</ConfirmButton>

                <Dialog open={openCsvModal}>
                    <DialogTitle>Экспорт CSV</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Выберите нужные колонки:
                        </DialogContentText>
                        <div className={classes.checkboxContainer}>
                            <CustomCheckbox checked={isChecked("id")} label={"ID"} onChange={(event) => handleCheckUncheck(event, "id")}/>
                            <CustomCheckbox checked={isChecked("username")} label={"Имя пользователя"}  onChange={(event) => handleCheckUncheck(event, "username")}/>
                            <CustomCheckbox checked={isChecked("last_name")} label={"Фамилия"}  onChange={(event) => handleCheckUncheck(event, "last_name")}/>
                            <CustomCheckbox checked={isChecked("first_name")} label={"Имя"}  onChange={(event) => handleCheckUncheck(event, "first_name")}/>
                            <CustomCheckbox checked={isChecked("company")} label={"Компания"}  onChange={(event) => handleCheckUncheck(event, "company")}/>
                            <CustomCheckbox checked={isChecked("email")} label={"Email"}  onChange={(event) => handleCheckUncheck(event, "email")}/>
                            <CustomCheckbox checked={isChecked("mobile_phone")} label={"Телефон"}  onChange={(event) => handleCheckUncheck(event, "mobile_phone")}/>
                            <CustomCheckbox checked={isChecked("status")} label={"Статус"}  onChange={(event) => handleCheckUncheck(event, "status")}/>
                            <CustomCheckbox checked={isChecked("plan")} label={"План"}  onChange={(event) => handleCheckUncheck(event, "plan")}/>
                            <CustomCheckbox checked={isChecked("mmsKey")} label={"MMS KEY"}  onChange={(event) => handleCheckUncheck(event, "mmsKey")}/>
                            <CustomCheckbox checked={isChecked("telegram")} label={"Телеграм"}  onChange={(event) => handleCheckUncheck(event, "telegram")}/>
                            <CustomCheckbox checked={isChecked("bonus")} label={"Бонус"}  onChange={(event) => handleCheckUncheck(event, "bonus")}/>
                            <CustomCheckbox checked={isChecked("date")} label={"Дата регистрации"}  onChange={(event) => handleCheckUncheck(event, "date")}/>
                        </div>
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <ConfirmButton disabled={isExportBtnDisabled()} style={{marginRight: "10px"}} onClick={exportCsv}>Экспорт CSV</ConfirmButton>
                        <CancelButton  onClick={handleCloseModal}>Отменить</CancelButton>
                    </DialogActions>
                </Dialog>

            </div>
        </div>
    )
}


export default ClientsHeader;