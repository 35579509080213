// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, InjectedFormProps } from 'redux-form';
// UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
// constants
import { APP_COLOR } from 'constants/style';
// local dependencies
import {
  selector as fwSoftGroupSelector,
  // actions
  updateFwSoftGroupDataAction,
  resetErrorEditAction,
  updateErrorEditAction,
  editSoftGroupAction,
  // interface
  IFwEcu,
  IEditFormError,
  IEditForm,
  // constants
  EDIT_FIELD_KEY,
} from 'private-layout/fw-soft-group/reducer';
// UI
import CustomTextField from 'components/input/TextField';
import CustomAutocomplete, {ICustomAutocompleteOption} from 'components/input/Autocomplete';
import CustomTextareaAutosize from 'components/input/Textarea';
import CustomCheckbox from 'components/checkbox/Checkbox';
import Preloader from 'components/Preloader';
// services
import ValidationService from 'services/validation.service';

// configurations
export const FORM_NAME = 'editFwSoftGroupForm';

const useStyles = makeStyles({
  paperFullWidth: {
    width: 500,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 24px',
    backgroundColor: APP_COLOR.GREY,
    color: APP_COLOR.WHITE,
  },
  close: {
    padding: 6,
    color: APP_COLOR.WHITE,
    cursor: 'pointer',
  },
  titleContent: {
    lineHeight: 2,
  },
  content: {
    display: 'flex',
    padding: 12,
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  continueAction: {
    backgroundColor: APP_COLOR.LIGHT_GREY,
    color: APP_COLOR.WHITE,
    '&:hover': {
      color: APP_COLOR.BLACK,
    },
    transition: '0.6s',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  configContainer: {
    width: '100%',
    paddingTop: 8,
  },
});

interface IProps {
  open: boolean;
  fwEcuList?: IFwEcu[] | null;

  expectFwSoftGroupAnswer?: boolean;

  editedFwEcu?: number | null;
  editedFwKey?: string;
  editedConfig?: string;
  editedApproved?: boolean;

  editErrors?: IEditForm | null;
  // state action
  updateEditedFwEcu?: (editedFwEcu: number | null) => void;
  updateEditedFwKey?: (editedFwKey: string) => void;
  updateEditedApproved?: (editedConfig: boolean) => void;
  updateEditedConfig?: (editedApproved: string) => void;

  resetErrorEdit?: (fieldKey: string) => void;
  updateErrorEdit?: (editErrors: IEditFormError) => void;

  updateEditedSoftGroup?: () => void;
  //
  handleClose: () => void;
  // redux
  handleSubmit?: any;
  validate?: any;
}

const CreateFwSoftGroupDialog: React.FC<IProps & InjectedFormProps<{}, IProps>> = (props: IProps) => {
  const {
    open,
    // state
    fwEcuList,
    expectFwSoftGroupAnswer,
    editedFwEcu,
    editedFwKey,
    editedConfig,
    editedApproved,
    editErrors,
    //
    handleClose,
    // state action
    updateEditedFwEcu,
    updateEditedFwKey,
    updateEditedApproved,
    updateEditedConfig,
    resetErrorEdit,
    updateErrorEdit,
    //
    updateEditedSoftGroup,
    //
    handleSubmit,
    validate,
  } = props;
  const classes = useStyles();
  const isEditedFwEcu = Boolean(editErrors?.editedFwEcu);
  const isEditedFwKey = Boolean(editErrors?.editedFwKey);
  const editedFwEcuError = typeof editErrors?.editedFwEcu === 'string' ? editErrors.editedFwEcu : '';
  const editedFwKeyError = typeof editErrors?.editedFwKey === 'string' ? editErrors.editedFwKey : '';

  const isSubmitNotAvailable = editErrors && Boolean(Object.values(editErrors).filter((error) => Boolean(error)).length);

  const handleOnFocus = (fieldKey: string) => () => {
    const isErrorInState =
      editErrors && Object.keys(editErrors).some((errorKey: string, index) => Object.values(editErrors)[index] && fieldKey === errorKey);
    if (isErrorInState) {
      resetErrorEdit && resetErrorEdit(fieldKey);
    }
  };
  const handleNewSoftEcuId = (value: ICustomAutocompleteOption | null) => {
    const flattenValue = value?.key || '';
    updateEditedFwEcu && updateEditedFwEcu(Number(flattenValue) || null);
  };
  const handleEditedFwKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateEditedFwKey && updateEditedFwKey(e.target.value);
  };
  const handleEditedConfig = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateEditedApproved && updateEditedApproved(e.target.checked);
  };

  const handleEditedApproved = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateEditedConfig && updateEditedConfig(e.target.value);
  };
  const handleFormSubmit = () => {
    const localErrors =
      validate &&
      validate({
        editedFwEcu,
        editedFwKey,
        editedConfig,
        editedApproved,
      });
    if (!localErrors && !isSubmitNotAvailable) {
      updateEditedSoftGroup && updateEditedSoftGroup();
    }
    if (localErrors) {
      updateErrorEdit && updateErrorEdit(localErrors);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paperFullWidth: classes.paperFullWidth }} fullWidth maxWidth='md'>
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <Typography variant='h6' className={classes.titleContent}>
          Редактировать FW soft group
        </Typography>
        <CloseIcon onClick={handleClose} className={classes.close} />
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <Form onSubmit={handleSubmit(handleFormSubmit)} className={classes.form}>
            {Array.isArray(fwEcuList) && (
                <CustomAutocomplete
                    options={fwEcuList}
                    onChange={handleNewSoftEcuId}
                    error={isEditedFwEcu}
                    onFocus={handleOnFocus(EDIT_FIELD_KEY.editedFwEcu)}
                    helperText={editedFwEcuError}
                    label='Edit Fw Soft Ecu'
                    placeholder='Exit Fw Soft Ecu'/>
            )}
            <Field
              type='text'
              component={CustomTextField}
              name='editedFwKey'
              placeholder='Edit Fw Key'
              label='Edit Fw Key'
              onChange={handleEditedFwKey}
              error={isEditedFwKey}
              onFocus={handleOnFocus(EDIT_FIELD_KEY.editedFwKey)}
              helperText={editedFwKeyError}
            />
            <div className={classes.configContainer}>
              <Field
                type='text'
                component={CustomTextareaAutosize}
                name='editedConfig'
                placeholder='Edit Config'
                label='Edit Config'
                onChange={handleEditedApproved}
              />
            </div>
            <CustomCheckbox label='Approved' checked={Boolean(editedApproved)} onChange={handleEditedConfig} />
          </Form>
          <div className={classes.actions}>
            {expectFwSoftGroupAnswer && <Preloader />}
            {!expectFwSoftGroupAnswer && (
              <Button onClick={handleFormSubmit} className={classes.continueAction} disabled={Boolean(isSubmitNotAvailable)}>
                Редактировать
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect(
  (state) => ({
    editedFwEcu: fwSoftGroupSelector(state).editedFwEcu,
    editedFwKey: fwSoftGroupSelector(state).editedFwKey,
    editedConfig: fwSoftGroupSelector(state).editedConfig,
    editedApproved: fwSoftGroupSelector(state).editedApproved,
    editErrors: fwSoftGroupSelector(state).editErrors,
    expectFwSoftGroupAnswer: fwSoftGroupSelector(state).expectFwSoftGroupAnswer,
    initialValues: {
      editedFwEcu: fwSoftGroupSelector(state).editedFwEcu,
      editedFwKey: fwSoftGroupSelector(state).editedFwKey,
      editedConfig: fwSoftGroupSelector(state).editedConfig,
      editedApproved: fwSoftGroupSelector(state).editedApproved,
    },
  }),
  (dispatch) => ({
    updateEditedFwEcu: (editedFwEcu: number | null) => dispatch(updateFwSoftGroupDataAction({ editedFwEcu })),
    updateEditedFwKey: (editedFwKey: string) => dispatch(updateFwSoftGroupDataAction({ editedFwKey })),
    updateEditedApproved: (editedApproved: boolean) => dispatch(updateFwSoftGroupDataAction({ editedApproved })),
    updateEditedConfig: (editedConfig: string) => dispatch(updateFwSoftGroupDataAction({ editedConfig })),

    resetErrorEdit: (fieldKey: string) => dispatch(resetErrorEditAction(fieldKey)),
    updateErrorEdit: (editErrors: IEditFormError) => dispatch(updateErrorEditAction(editErrors)),

    updateEditedSoftGroup: () => dispatch(editSoftGroupAction()),
  })
)(
  reduxForm<{}, IProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values: IEditForm): any => {
      const errors: IEditFormError = {
        editedFwEcu: null,
        editedFwKey: null,
        editedConfig: null,
        editedApproved: null,
      };
      const editedFwEcuInvalid = !values.editedFwEcu ? 'Поле обязательно' : false;
      const editedFwKeyInvalid = typeof values.editedFwKey === 'string' ? ValidationService.getStringInvalidReason(values.editedFwKey) : 'Поле обязательно';

      if (editedFwEcuInvalid) {
        errors.editedFwEcu = editedFwEcuInvalid;
      }
      if (editedFwKeyInvalid) {
        errors.editedFwKey = editedFwKeyInvalid;
      }

      // if at least one error were found
      if (Object.values(errors).filter((error) => Boolean(error)).length) return errors;
      return null;
    },
  })(CreateFwSoftGroupDialog)
);
