import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Button, FormControl, TextField, Typography} from "@material-ui/core";
import currentConfig from "../../../utils/config.utils";
import PrettifyMasks from './PrettifyMasks';
import AddIcon from '@material-ui/icons/Add';
import Preloader from 'components/Preloader';
import {toast} from "react-toastify";

const useStyles = makeStyles({
  t1: {
    marginLeft: '20px',
    width: '180px',
    marginBlock: "7px"
  },
  tLen: {
    marginLeft: '20px',
    width: '100px',
    marginBlock: "7px"
  },
  m1: {
    marginLeft: '20px',
    width: '150px',
    marginBlock: "7px"
  },
  m2: {
    marginLeft: '20px',
    width: '150px',
    marginBlock: "7px"
  },
  dtc: {
    marginLeft: '20px',
    width: '350px',
    marginBlock: "7px"
  },
  checkBtn: {
    marginLeft: '20px',
    marginTop: "20px"
  },
  dtcContainer: {
    marginLeft: '20px',
    marginTop: '20px'
  },
  dataTitle: {
    marginTop: '10px'
  },
  dataBox: {
    marginLeft: '20px'
  },
  maskBlock: {
    display: 'inline-flex',
    position: 'relative',
    flexDirection: 'column',
    verticalAlign: 'top',
    width: '200px'
  },
  bitsBox: {
    marginLeft: '175px',
    marginTop: '25px',
    textAlign: 'center',
    lineHeight: '1.4',
    width: '20px',
    position: 'absolute',
    fontSize: '12px'
  },
  bits8: {
    fontWeight: 'bolder',
    cursor: 'pointer'
  },
  bits16: {
    fontWeight: 'bolder',
    cursor: 'pointer'
  },
  bitsUnselect: {
    cursor: 'pointer',
    color: '#949494'
  },
  replaceInput: {
    fontSize: '10px',
    width: '50px',
    marginLeft: '10px'
  },
  replaceInputError: {
    fontWeight: 'bold',
    color: 'red'
  },
  replaceLabel: {
    fontSize: '10px',
    marginLeft: '55px'
  },
  addMaskBtn: {
    width: '30px',
    marginLeft: '20px',
    marginTop: '20px'
  }
});

interface IResponseMask {
  bits: number
  hex: string
}

interface IResponseData {
  t: string
  masks: IResponseMask[]
  fdi: number[]
}

interface IProps {
  fileKey: string
}

enum Bits {
  byte = 8,
  word = 16
}

enum ReplaceTo {
  byte = '0x00',
  word = '0xFFFF'
}

interface IMask {
  address: string
  bits: Bits
  replace: string
  error: boolean
  replaceError?: boolean
}

export default function BoschAlgForm({fileKey}: IProps) {
  const classes = useStyles();

  const [expectCheckAnswer, setExpectCheckAnswer] = useState<boolean>(false);
  const [expectRemoveAnswer, setExpectRemoveAnswer] = useState<boolean>(false);
  const [removeResponseMessage, setRemoveResponseMessage] = useState<string>();
  const [t, setT] = useState<string>('');//0x18F818 for test on e0a98a
  const [tLen, setTLen] = useState<string>('');//1057 for test on e0a98a
  const [dtc, setDTC] = useState<Array<string>>([]);
  const [mask, setMask] = useState<Array<IMask>>([
    {
      address: '',
      bits: Bits.byte,
      replace: ReplaceTo.byte,
      error: false
    },
    {
      address: '',
      bits: Bits.word,
      replace: ReplaceTo.word,
      error: false
    }
  ]);

  const [terror, setTerror] = useState<boolean>(false);
  const [tlenerror, setTLenerror] = useState<boolean>(false);
  const [DTCerror, setDTCerror] = useState<boolean>(false);
//  const [DTCserror, setDTCserror] = useState<Array<string>>([]);
  const [responseFoundDTCs, setResponseFoundDTCs] = useState<string>('');
  const [responseData, setResponseData] = useState<IResponseData>({t: '', masks: [], fdi: []});
  const [adminComment, setAdminComment] = useState<string>();


  async function checkHandler() {
    const _mask = mask.filter(m => m.address.trim())
    setMask(_mask)
    console.log(t, tLen, dtc)
    setTerror(!t)
    setTLenerror(!tLen)
    setDTCerror(dtc.length === 0)
    const checkMasks = true
    if (t && tLen && checkMasks && dtc.length) {
      const config = currentConfig();
      setExpectCheckAnswer(true)
      try {
        const res = await fetch(config.apiUrl + 'admin/remove-dtc/check', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                a: 'BOSCH',
                fileKey: fileKey,
                t: t,
                tLen: tLen,
                dtc: dtc,
                masks: mask
            })
        })
        if (res.ok) {
          const result = await res.json()
          if (result.response && result.response.data) {
            const foundDTCs = result.response.data.found || []
            let foundDTCsIdx = []
            for (let i = 0; i < result.response.data.t.length; i += 4) {
              if (foundDTCs.indexOf(result.response.data.t.slice(i, i + 4)) > -1) {
                foundDTCsIdx.push(i/4)
              }
            }
            setResponseFoundDTCs(foundDTCs.join(', '))
            setResponseData({
              t: result.response.data.t,
              masks: result.response.data.masks,
              fdi: foundDTCsIdx
            })
          } else {
            setResponseFoundDTCs('Ничего не найдено')
            setResponseData({
              t: '',
              masks: [],
              fdi: []
            })
          }
        }
      } catch (e) {
        toast.error('Произошла ошибка. Обратитесь к создателю.')
      }
      setExpectCheckAnswer(false)
    }
  }

  async function removeHandler() {
    setTerror(!t)
    setTLenerror(!tLen)
    //setM1error(!m1)
    //setM2error(!m2)
    if (t && tLen && dtc) {
      const config = currentConfig();
      setExpectRemoveAnswer(true)
      try {
        const res = await fetch(config.apiUrl + 'admin/remove-dtc/remove', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                a: 'BOSCH',
                fileKey: fileKey,
                t: t,
                tLen: tLen,
                dtc: dtc,
                masks: mask,
                comment: adminComment
            })
        })
        console.log(res)
        if (res.ok) {
          const response = await res.json()
          if (response && response.result === 'success')  {
            setRemoveResponseMessage('Удалено успешно')
            checkHandler()
          }
        }
      } catch (e) {
        toast.error(' Произошла ошибка. Обратитесь к создателю.')
      }
      setExpectRemoveAnswer(false)
    }
  }

  function changeTHandler(e:React.ChangeEvent<{ value: unknown }>) {
    const strRawAddress = (e.target.value as string).trim()
    const errors = !/^(0x)?([0-9a-fA-F]{4,8})$/gm.test(strRawAddress)
    setTerror(errors)
    setT(strRawAddress)
  }

  function changeTLenHandler(e:React.ChangeEvent<{ value: unknown }>) {
    const strLen = (e.target.value as string).trim()
    const errors = !/^\d{1,4}$/gm.test(strLen)
    setTLenerror(errors)
    setTLen(strLen)
  }

  function changeMaskHandler(e:React.ChangeEvent<{ value: unknown }>, idx:number) {
    const strRawAddress = (e.target.value as string).trim()
    let _mask = [...mask]
    _mask[idx].address = strRawAddress
    if (strRawAddress) {
      const errors = !/^(0x)?([0-9a-fA-F]{4,8})$/gm.test(strRawAddress)
      _mask[idx].error = errors
    } else {
      _mask[idx].error = false
    }
    setMask(_mask)
  }

  function setBits(e:React.MouseEvent<HTMLDivElement, MouseEvent>, idx:number, bits:number) {
    let _mask = [...mask]
    _mask[idx].bits = bits
    _mask[idx].replace = bits === 8 ? ReplaceTo.byte : ReplaceTo.word

    setMask(_mask)
  }

  function changeDTCHandler(e:React.ChangeEvent<{ value: unknown }>) {
    const DTCs = (e.target.value as string).split(',').map(dtc => dtc.trim())
    const notValidDTCs = DTCs.filter(dtc => !/^[0-9a-fA-F]{4}$/gm.test(dtc))
    //const validDTCs = DTCs.filter(dtc => /^[0-9a-fA-F]{4}$/gm.test(dtc))
//    setDTCserror(notValidDTCs)
    setDTCerror(notValidDTCs.length > 0 || (e.target.value as string).trim().length === 0)
    if (!notValidDTCs.length)
      setDTC(DTCs)
    //setDTC(validDTCs)
  }

  function addNewMask() {
    let _mask = [...mask]
    _mask.push({
      address: '',
      bits: Bits.word,
      replace: ReplaceTo.word,
      error: false
    })
    setMask(_mask)
  }

  function replaceToHandler(e:React.ChangeEvent<{ value: unknown }>, idx:number) {
    const strRawValue = (e.target.value as string).trim()
    let _mask = [...mask]
    _mask[idx].replace = strRawValue
    if (strRawValue) {
      let errors = false
      if (_mask[idx].bits === 8)
        errors = !/^(0x)?([0-9a-fA-F]{2})$/gm.test(strRawValue)
      else
        errors = !/^(0x)?([0-9a-fA-F]{4})$/gm.test(strRawValue)
      _mask[idx].replaceError = errors
    } else {
      _mask[idx].replaceError = false
    }
    setMask(_mask)
  }

  return (
    <>
      <FormControl className={classes.t1}>
        <TextField 
          value={t}
          error={terror}
          label="Адрес таблицы в HEX"
          onChange={changeTHandler}
          />
      </FormControl>
      <FormControl className={classes.tLen}>
        <TextField 
          error={tlenerror}
          label="Длинна"
          onChange={changeTLenHandler}
          value={tLen}
          />
      </FormControl>
      {mask.map((mask, idx) => {
        return (
          <div key={idx} className={classes.maskBlock}>
            <FormControl className={mask.bits === Bits.byte ? classes.m1 : classes.m2}>
              <TextField 
                error={mask.error}
                label={`Адрес M${idx+1} в HEX`}
                onChange={(e) => { changeMaskHandler(e, idx) }}
                value={mask.address}
                key={idx}
                />
            </FormControl>
            <div className={classes.bitsBox}>
              <div onClick={(e) => { setBits(e, idx, 8) }} className={mask.bits === 8 ? classes.bits8 : classes.bitsUnselect}>8b</div>
              <div onClick={(e) => { setBits(e, idx, 16) }} className={mask.bits === 16 ? classes.bits16 : classes.bitsUnselect}>16b</div>
            </div>
            <div>
              <span className={classes.replaceLabel}>Замена на</span>
              <input value={mask.replace} className={[classes.replaceInput, (mask.replaceError ? classes.replaceInputError : '')].join(' ')} onChange={(e) => { replaceToHandler(e, idx) }}/>
            </div>
          </div>
          )
      })}
      <FormControl>
        <Button aria-label="add" color="primary" variant={"contained"} className={classes.addMaskBtn} onClick={addNewMask}>
          <AddIcon />
        </Button>
      </FormControl>
      <br/>
      <FormControl className={classes.dtc}>
        <TextField 
          error={DTCerror}
          label="DTC"
          onChange={changeDTCHandler}
          multiline
          />
      </FormControl>
      <Button className={classes.checkBtn} variant={"contained"} color={"primary"}
                   onClick={checkHandler}>Проверить</Button>
      {expectCheckAnswer && <Preloader pageCentered/>}
      {!expectCheckAnswer && responseData && responseData.t && (
        <>
          <div className={classes.dtcContainer}>
            <Typography variant='h6' className={classes.dataTitle}>
              Найденые DTCs:
            </Typography>
            <div className={classes.dataBox}>{responseFoundDTCs}</div>
            <Typography variant='h6' className={classes.dataTitle}>Таблица:</Typography>
            <div className={classes.dataBox}><PrettifyMasks data={responseData.t} bitness={16} highlightPos={responseData.fdi} /></div>
            {responseData.masks.map((mask, idx) => {
              return (
                  <div key={idx}>
                    <Typography variant='h6' className={classes.dataTitle}>Маска {idx+1}:</Typography>
                    <div className={classes.dataBox}><PrettifyMasks data={mask.hex} bitness={mask.bits} highlightPos={responseData.fdi} /></div>
                  </div>
              )
            })}
          </div>
          {responseFoundDTCs.length > 0 && (
            <div>
              {expectRemoveAnswer && <Preloader pageCentered/>}
              {!expectRemoveAnswer && (
                <>
                  <TextField 
                    label={'Комментарий'}
                    onChange={e => setAdminComment(e.target.value)}
                    />
                  <Button className={classes.checkBtn} variant={"contained"} color={"primary"}
                    onClick={removeHandler}>Удалить</Button>
                  <br/><span>{removeResponseMessage}</span>
                </>
              )}
          </div>
          )}
          {responseFoundDTCs.length === 0 && <div>Коды ошибок не найдены, нечего удалить</div>}
        </>
      )}
    </>
  )
}
