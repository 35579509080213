import React, {useEffect, useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TableCell,
    TextField
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import currentConfig from "../../../utils/config.utils";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ConfirmButton from "../../../components/button/ConfirmButton";
import CancelButton from "../../../components/button/CancelButton";
import {makeStyles} from "@material-ui/core/styles";
import {IStEcu} from "../types";
import {toast} from "react-toastify";
import { QUERY, PRIVATE as ROUTE } from 'constants/routes';

interface IProps {
    ecu: IStEcu;
    changeTableContent: () => void;
}
const useStyles = makeStyles({
   deleteIcon: {
       marginLeft: "20px",
       cursor: "pointer"
   },
   rowCenter: {
       textAlign: 'center'
   }
});

const StEcuTableRow = (props: IProps) => {
    const {ecu, changeTableContent} = props;
    const classes = useStyles();

    const [rowState, setrowState] = useState<IStEcu | null>(null);
    const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false);


    const isEditedRow = () => {
        return JSON.stringify(rowState) !== JSON.stringify(ecu);
    }

    const ecuChangeHandle = (event: any, key: keyof IStEcu) => {
        if (!rowState) {
            return
        }
        setrowState({
            ...rowState,
            [key]: event.target.value
        })
    }

    const onSaveClickHandle = () => {
        if (!rowState) {
            return
        }
        if (isEditedRow()) {
            const config = currentConfig();
            fetch(config.apiUrl + `st-ecu/${rowState.st_ecu_}`, {
                method: 'put',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify(rowState)
            })
                .then(response => response.json())
                .then((res) => {
                    if (res.result === 'error') {
                        toast.error(res.errorMessage);
                    } else {
                        toast.success('Блок отредактирован');
                    }
                    // changeTableContent();
                })
                .catch(err => {
                    console.log('error:', err)
                })

        }
    }

    const removeHandle = () => {
        if (!rowState) {
            return
        }
        const config = currentConfig();
        fetch(config.apiUrl + `st-ecu/${rowState.st_ecu_}`, {
            method: 'delete',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
        })
            .then(response => response.json())
            .then(() => {
                toast.success('Блок удален');
                changeTableContent();
            })
            .catch(err => {
                console.log('error:', err)
            })
    }

    useEffect(() => {
        setrowState(JSON.parse(JSON.stringify(ecu)));
    }, [ecu])


    return (
        <TableRow>
            <TableCell>
                {rowState?.st_ecu_}
            </TableCell>
            <TableCell>
                {rowState?.key}
            </TableCell>
            <TableCell>
                <TextField onChange={(event => ecuChangeHandle(event, 'name'))} value={rowState?.name || ''} fullWidth={true}/>
            </TableCell>
            <TableCell>
                <TextField onChange={(event => ecuChangeHandle(event, 'original_name'))} value={rowState?.original_name || ''} fullWidth={true}/>
            </TableCell>
            <TableCell>
                <TextField onChange={(event => ecuChangeHandle(event, 'config'))} value={rowState?.config || ''} fullWidth={true}/>
            </TableCell>
            <TableCell className={classes.rowCenter}>
                <Link to={`${ROUTE.ST_ACTION_BRAND_ECU}${QUERY.ST_ACTION_BRAND_ECU}${rowState?.st_ecu_}`}>
                    {rowState?.actions_cnt}
                </Link>
            </TableCell>
            <TableCell>
                <SaveIcon onClick={onSaveClickHandle} style={isEditedRow() ? {cursor: "pointer"} : {cursor: "auto"}} color={isEditedRow() ? 'primary' : 'disabled'}/>
                {rowState?.actions_cnt === 0 && <RemoveCircleOutlineIcon className={classes.deleteIcon} color={"secondary"} onClick={() => setOpenRemoveDialog(true)}/>}
            </TableCell>

            <Dialog open={openRemoveDialog}>
                <DialogTitle>Подтвердите действие</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы действительно хотите удалить обьект?
                    </DialogContentText>
                </DialogContent>
                <DialogActions disableSpacing={true}>
                    <ConfirmButton onClick={removeHandle}>Удалить</ConfirmButton>
                    <CancelButton style={{marginLeft: "20px"}}
                                    onClick={() => setOpenRemoveDialog(false)}>Отменить</CancelButton>
                </DialogActions>
            </Dialog>
        </TableRow>
    )
}

export default StEcuTableRow;
