// outsource dependencies
import { fork } from 'redux-saga/effects';
// local dependencies
import { sagas as clientRequestFilesSagas } from 'private-layout/client-request-files/saga';
import { sagas as fwEcuSagas } from 'private-layout/fw-ecu/saga';
import { sagas as fwSoftSagas } from 'private-layout/fw-soft/saga';
import { sagas as fwSoftGroupSagas } from 'private-layout/fw-soft-group/saga';

export function* sagas() {
  yield fork(clientRequestFilesSagas);
  yield fork(fwEcuSagas);
  yield fork(fwSoftSagas);
  yield fork(fwSoftGroupSagas);
}

export default sagas;
