import React, {useEffect, useState} from 'react';
import currentConfig from "../../../utils/config.utils";
import {useParams} from "react-router";
import {toast} from "react-toastify";

interface IPayment {
  id: number
}

export default function Payments() {
  const {key} = useParams<{key: string}>();

  const [payments, setPayments] = useState<IPayment[]>([]);

  useEffect(() => {
    const config = currentConfig();
    fetch(config.apiUrl + 'admin/payments', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify({key})
    }).then(response => response.json())
      .then(data => {
        setPayments(data.response.data);
      })
      .catch(err => {
        console.log('error:', err)
        toast.error(err);
      })
  }, [key]);


  return (
    <>
      {payments.map(payment => {
        return <p key={payment.id}>
          {payment.id}
        </p>
      })}
    </>
  )
}