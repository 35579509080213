// outsource dependencies
import { fork, takeLatest, call, put, all, select } from 'redux-saga/effects';
// local dependencies
import {
  TYPE,
  selector as fwSoftSelector,
  // action
  updateFwSoftDataAction,
  loadSoftAction,
  loadAllSoftAction,
  // constants
  DefaultTableOptions,
  // interface
  IFwSoft,
} from 'private-layout/fw-soft/reducer';
import API from 'services/request.service';

function* loadSoftSaga() {
  const { filesPerPage, page, fwSearchGroup, fwSearchVal, filterByFwEcu, filterByFwSoftGroup } = yield select(fwSoftSelector);
  yield put(updateFwSoftDataAction({ allListLoaded: false, expectFwSoftAnswer: true }));
  try {
    const { data } = yield call<any>(API, {
      url: '/ecus/get-fw-soft',
      method: 'POST',
      data: {
        count: filesPerPage,
        page,
        group: fwSearchGroup,
        val: fwSearchVal,
        fw_ecu_: filterByFwEcu,
        fw_soft_group_: filterByFwSoftGroup,
      },
    });
    const fwSoftList = data?.response?.soft || null;
    const totalSoftCount = data?.response?.softCount || null;
    yield all([put(updateFwSoftDataAction({ expectFwSoftAnswer: false })), put(updateFwSoftDataAction({ fwSoftList, totalSoftCount }))]);
  } catch (e) {
    yield put(updateFwSoftDataAction({ expectFwSoftAnswer: false }));
  }
}

function* initializeFwSoftSaga() {
  const { filterByFwEcu, filterByFwSoftGroup } = yield select(fwSoftSelector);
  yield put(updateFwSoftDataAction({ expectAnswer: true, expectFwSoftAnswer: true }));
  try {
    const { data: ecusList } = yield call<any>(API, {
      url: '/ecus/get-fw-ecus-list',
      method: 'GET',
    });
    const fwEcuList = ecusList?.response?.ecus || null;
    const { data: softGroup } = yield call<any>(API, {
      url: '/ecus/get-fw-soft-group-list',
      method: 'GET',
    });
    const fwSoftGroup = softGroup?.response?.softGroupList || null;
    const { data } = yield call<any>(API, {
      url: '/ecus/get-fw-soft',
      method: 'POST',
      data: {
        count: DefaultTableOptions.DEFAULT_FILES_COUNT,
        page: DefaultTableOptions.DEFAULT_PAGE,
        fw_ecu_: filterByFwEcu,
        fw_soft_group_: filterByFwSoftGroup,
      },
    });
    const fwSoftList = data?.response?.soft || null;
    const totalSoftCount = data?.response?.softCount || null;
    yield all([
      put(updateFwSoftDataAction({ expectAnswer: false, expectFwSoftAnswer: false })),
      put(updateFwSoftDataAction({ fwSoftList, totalSoftCount, fwEcuList, fwSoftGroup })),
    ]);
  } catch (e) {
    yield put(updateFwSoftDataAction({ expectAnswer: false, expectFwSoftAnswer: false }));
  }
}

function* loadAllFwSoftSaga() {
  const { filterByFwEcu, filterByFwSoftGroup } = yield select(fwSoftSelector);
  yield put(updateFwSoftDataAction({ allListLoaded: true, expectFwSoftAnswer: true }));
  try {
    const { data } = yield call<any>(API, {
      url: '/ecus/get-fw-soft',
      method: 'POST',
      data: {
        fw_ecu_: filterByFwEcu,
        fw_soft_group_: filterByFwSoftGroup,
      },
    });
    const fwSoftList = data?.response?.soft || null;
    const totalSoftCount = data?.response?.softCount || null;
    yield all([put(updateFwSoftDataAction({ expectFwSoftAnswer: false })), put(updateFwSoftDataAction({ fwSoftList, totalSoftCount }))]);
  } catch (e) {
    yield put(updateFwSoftDataAction({ expectFwSoftAnswer: false }));
  }
}

function* approveDeleteFwSoftSaga() {
  const { allListLoaded, fwSoftIdDelete, fwSoftList, totalSoftCount } = yield select(fwSoftSelector);
  yield put(updateFwSoftDataAction({ expectFwSoftAnswer: true }));
  try {
    yield call<any>(API, {
      url: '/ecus/delete-fw-soft',
      method: 'DELETE',
      data: {
        fw_soft_: fwSoftIdDelete,
      },
    });
    let fwSoftListUpdated = fwSoftList;
    let totalSoftCountUpdated = totalSoftCount;
    if (allListLoaded) {
      fwSoftListUpdated = fwSoftListUpdated.filter((listItem: IFwSoft) => listItem.fw_soft_ !== fwSoftIdDelete);
      totalSoftCountUpdated = Number(totalSoftCountUpdated) - 1;
    } else {
      // trig other loading saga
      yield put(loadSoftAction());
    }
    yield all([
      put(updateFwSoftDataAction({ expectFwSoftAnswer: false })),
      put(updateFwSoftDataAction({ fwSoftIdDelete: null, fwSoftList: fwSoftListUpdated, totalSoftCount: totalSoftCountUpdated, fwSoftDeleteSuccess: true })),
    ]);
  } catch (e) {
    yield put(updateFwSoftDataAction({ expectFwSoftAnswer: false, fwSoftDeleteError: true }));
  }
}

function* saveNewFwSoftSaga() {
  const { newFwSoftEcuId, newSoftFull, newSoftIdx, newFwSoftGroupId, newConfig, allListLoaded } = yield select(fwSoftSelector);
  const additionalData =
    newFwSoftGroupId && newConfig
      ? { fw_soft_group_: newFwSoftGroupId, config: newConfig }
      : newFwSoftGroupId
      ? { fw_soft_group_: newFwSoftGroupId }
      : newConfig
      ? { config: newConfig }
      : {};
  yield put(updateFwSoftDataAction({ expectFwSoftAnswer: true }));
  try {
    yield call<any>(API, {
      url: '/ecus/save-fw-soft',
      method: 'POST',
      data: {
        fw_ecu_: newFwSoftEcuId,
        soft_full: newSoftFull,
        soft_idx: newSoftIdx,
        ...additionalData,
      },
    });
    if (allListLoaded) {
      yield put(loadAllSoftAction());
    } else {
      // trig other loading saga
      yield put(loadSoftAction());
    }
    yield all([
      put(updateFwSoftDataAction({ expectFwSoftAnswer: false })),
      put(
        updateFwSoftDataAction({
          fwSoftCreateSuccess: true,
          newFwSoftEcuId: '',
          newSoftFull: '',
          newSoftIdx: '',
          newFwSoftGroupId: '',
          newConfig: '',
          createErrors: null,
        })
      ),
    ]);
  } catch (e) {
    yield put(updateFwSoftDataAction({ expectFwSoftAnswer: false, fwSoftCreateError: true }));
  }
}

function* editFwSoftSaga() {
  const { fwSoftIdEdit, editedFwSoftEcuId, editedSoftFull, editedSoftIdx, editedFwSoftGroupId, editedConfig, allListLoaded } = yield select(fwSoftSelector);
  yield put(updateFwSoftDataAction({ expectFwSoftAnswer: true }));
  try {
    yield call<any>(API, {
      url: '/ecus/update-fw-soft',
      method: 'POST',
      data: {
        fw_soft_: fwSoftIdEdit,
        fw_ecu_: editedFwSoftEcuId,
        soft_full: editedSoftFull,
        soft_idx: editedSoftIdx,
        fw_soft_group_: editedFwSoftGroupId,
        config: editedConfig,
      },
    });
    if (allListLoaded) {
      yield put(loadAllSoftAction());
    } else {
      // trig other loading saga
      yield put(loadSoftAction());
    }
    yield all([
      put(updateFwSoftDataAction({ expectFwSoftAnswer: false })),
      put(
        updateFwSoftDataAction({
          fwSoftEditSuccess: true,
          fwSoftIdEdit: null,
          editedFwSoftEcuId: '',
          editedSoftFull: '',
          editedSoftIdx: '',
          editedFwSoftGroupId: '',
          editedConfig: '',
          editErrors: null,
        })
      ),
    ]);
  } catch (e) {
    yield put(updateFwSoftDataAction({ expectFwSoftAnswer: false, fwSoftEditError: true }));
  }
}

/**
 * connect page sagas
 *
 * @private
 */
function* activityTasks() {
  yield takeLatest(TYPE.INITIALIZE_FILES, initializeFwSoftSaga);
  yield takeLatest(TYPE.LOAD_SOFT, loadSoftSaga);
  yield takeLatest(TYPE.LOAD_ALL_LIST, loadAllFwSoftSaga);
  yield takeLatest(TYPE.APPROVE_DELETE, approveDeleteFwSoftSaga);
  yield takeLatest(TYPE.SAVE_NEW_SOFT, saveNewFwSoftSaga); 
  yield takeLatest(TYPE.EDIT_SOFT, editFwSoftSaga);
}

export function* sagas() {
  yield fork(activityTasks);
}

export default sagas;
