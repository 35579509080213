// outsource dependencies
import { fork } from 'redux-saga/effects';
// local dependencies
import { sagas as loginSagas } from 'public-layout/log-in/saga';

export function* sagas() {
  yield fork(loginSagas);
}

export default sagas;
