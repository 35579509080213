// outsource dependencies
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// UI
import { ToastContainer, toast } from 'react-toastify';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
// local dependencies
// state
import {
  selector as fwSoftSelector,
  // actions
  updateFwSoftDataAction,
  // trigger
  loadSoftAction,
  initializeFwEcusAction,
  clearStateAction,
  loadAllSoftAction,
  approveDeleteAction,
  // interface
  IFwSoft,
  IFwEcu,
  IFwSoftGroup,
} from 'private-layout/fw-soft/reducer';
// UI
import Layout from 'components/layout/Common';
import FwSoftsTable from 'private-layout/fw-soft/components/FwSoftsTable';
import Preloader from 'components/Preloader';
import FwSoftDeleteDialog from 'private-layout/fw-soft/components/FwSoftDeleteDialog';
import FwSoftEditDialog from 'private-layout/fw-soft/components/FwSoftEditDialog';
import FwSoftCreateDialog from 'private-layout/fw-soft/components/FwSoftCreateDialog';
// utils
import { useQuery } from 'utils/hook.utils';

const useStyles = makeStyles({
  container: {
    minHeight: '80vh',
  },
  title: {
    padding: '16px 0 0 16px',
  },
  filters: {
    display: 'flex',
    padding: 16,
  }
});
interface IProps {
  // state
  totalSoftCount?: number;
  fwSoftList?: IFwSoft[] | null;
  fwEcuList?: IFwEcu[] | null;
  fwSoftGroup?: IFwSoftGroup[] | null;

  fwSoftIdDelete?: number | null;
  fwSoftIdEdit?: number | null;

  expectAnswer?: boolean;
  expectFwSoftAnswer?: boolean;

  page?: number;
  filesPerPage?: number;
  fwSearchGroup?: string;
  fwSearchVal?: string; // soft_full, soft_idx and config

  filterByFwEcu?: number | null;
  filterByFwSoftGroup?: number | null;

  allListLoaded?: boolean;
  fwSoftDeleteSuccess?: boolean;
  fwSoftDeleteError?: boolean;
  fwSoftCreateSuccess?: boolean;
  fwSoftCreateError?: boolean;
  fwSoftEditSuccess?: boolean;
  fwSoftEditError?: boolean;
  // action
  updatePage?: (page: number) => void;
  updateFilesPerPage?: (filesPerPage: number) => void;
  updateFwSearchGroup?: (fwSearchGroup: string) => void;
  updateFwSearchVal?: (fwSearchVal: string) => void;
  updateFwSoftIdDelete?: (fwSoftIdDelete: number | null) => void;
  updateFwSoftIdEdit?: (fwSoftIdEdit: number | null) => void;

  updateFilterByFwEcu?: (filterByFwEcu: number | null) => void;
  updateFilterByFwSoftGroup?: (filterByFwSoftGroup: number | null) => void;

  updateFwSoftEdit?: ({
    editedFwSoftEcuId,
    editedSoftFull,
    editedSoftIdx,
    editedFwSoftGroupId,
    editedConfig,
  }: {
    editedFwSoftEcuId: string | number;
    editedSoftFull: string | number;
    editedSoftIdx: string | number;
    editedFwSoftGroupId: string | number;
    editedConfig: string | number | null;
  }) => void;

  clearSuccessDeleteFeedback?: () => void;
  clearErrorDeleteFeedback?: () => void;
  clearSuccessCreateFeedback?: () => void;
  clearErrorCreateFeedback?: () => void;
  clearSuccessEditFeedback?: () => void;
  clearErrorEditFeedback?: () => void;
  // trigger
  loadSoft?: () => void;
  loadAllSoft?: () => void;
  initializeFiles?: () => void;
  clearState?: () => void;
  approveDelete?: () => void;
}

function FwSoftFiles(props: IProps) {
  const {
    totalSoftCount,
    fwSoftList,
    fwSoftIdDelete,
    // fwSoftIdEdit,
    fwEcuList,
    fwSoftGroup,
    // loading
    expectAnswer,
    expectFwSoftAnswer,
    // state
    page,
    filesPerPage,
    fwSearchGroup,
    fwSearchVal,
    filterByFwEcu,
    filterByFwSoftGroup,
    //
    allListLoaded,
    fwSoftDeleteSuccess,
    fwSoftDeleteError,
    fwSoftCreateSuccess,
    fwSoftCreateError,
    fwSoftEditSuccess,
    fwSoftEditError,
    //
    updateFilesPerPage,
    updatePage,
    updateFwSearchGroup,
    updateFwSearchVal,
    updateFwSoftIdDelete,
    updateFwSoftIdEdit,
    updateFwSoftEdit,
    updateFilterByFwEcu,
    updateFilterByFwSoftGroup,
    //
    clearSuccessDeleteFeedback,
    clearErrorDeleteFeedback,
    clearSuccessCreateFeedback,
    clearErrorCreateFeedback,
    clearSuccessEditFeedback,
    clearErrorEditFeedback,
    // trigger
    initializeFiles,
    clearState,
    loadSoft,
    loadAllSoft,
    approveDelete,
  } = props;
  const classes = useStyles();
  const query = useQuery();
  const filterByEcu = query.get('fw_ecu');
  const filterBySoftGroup = query.get('fw_soft_group');

  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

  // const fwSoftEdit = Array.isArray(fwSoftList) ? fwSoftList.find((fwSoft: IFwSoft) => fwSoft.fw_soft_ === fwSoftIdEdit) : null;
  const fwSoftDelete = Array.isArray(fwSoftList) ? fwSoftList.find((fwSoft: IFwSoft) => fwSoft.fw_soft_ === fwSoftIdDelete) : null;

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    updateFwSoftIdEdit && updateFwSoftIdEdit(null);
  };
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    updateFwSoftIdDelete && updateFwSoftIdDelete(null);
  };
  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };
  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };
  const handleDelete = () => {
    approveDelete && approveDelete();
    handleCloseDeleteDialog();
  };
  const handleDeleteFilterByFwEcu = () => {
    updateFilterByFwEcu && updateFilterByFwEcu(null);
    if (allListLoaded) {
      loadAllSoft && loadAllSoft();
    } else {
      loadSoft && loadSoft();
    }
  };
  const handleDeleteFilterByFwSoftGroup = () => {
    updateFilterByFwSoftGroup && updateFilterByFwSoftGroup(null);
    if (allListLoaded) {
      loadAllSoft && loadAllSoft();
    } else {
      loadSoft && loadSoft();
    }
  };

  const handleDeleteSuccessFeedback = () => {
    toast.success('Файл успешно удалён');
  };
  const handleDeleteErrorFeedback = () => {
    toast.error('Не удалось удалить файл');
  };
  const handleSaveSuccessFeedback = () => {
    toast.success('Файл успешно сохранён');
  };
  const handleSaveErrorFeedback = () => {
    toast.error('Не удалось сохранить файл');
  };
  const handleEditSuccessFeedback = () => {
    toast.success('Файл успешно отредактирован');
  };
  const handleEditErrorFeedback = () => {
    toast.error('Не удалось отредактировать файл');
  };

  // edit soft - watcher
  useEffect(() => {
    if (fwSoftEditSuccess) {
      handleEditSuccessFeedback();
      clearSuccessEditFeedback && clearSuccessEditFeedback();
      handleCloseEditDialog();
    }
    if (fwSoftEditError) {
      handleEditErrorFeedback();
      clearErrorEditFeedback && clearErrorEditFeedback();
      handleCloseEditDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fwSoftEditSuccess, fwSoftEditError, clearErrorEditFeedback, clearSuccessEditFeedback]);
  // create soft - watcher
  useEffect(() => {
    if (fwSoftCreateSuccess) {
      handleSaveSuccessFeedback();
      clearSuccessCreateFeedback && clearSuccessCreateFeedback();
      handleCloseCreateDialog();
    }
    if (fwSoftCreateError) {
      handleSaveErrorFeedback();
      clearErrorCreateFeedback && clearErrorCreateFeedback();
      handleCloseCreateDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fwSoftCreateSuccess, fwSoftCreateError, clearErrorCreateFeedback, clearSuccessCreateFeedback]);
  // delete soft - watcher
  useEffect(() => {
    if (fwSoftDeleteSuccess) {
      handleDeleteSuccessFeedback();
      clearSuccessDeleteFeedback && clearSuccessDeleteFeedback();
    }
    if (fwSoftDeleteError) {
      handleDeleteErrorFeedback();
      clearErrorDeleteFeedback && clearErrorDeleteFeedback();
    }
  }, [fwSoftDeleteSuccess, fwSoftDeleteError, clearErrorDeleteFeedback, clearSuccessDeleteFeedback]);
  // initialize
  useEffect(() => {
    if (filterByEcu) {
      updateFilterByFwEcu && updateFilterByFwEcu(Number(filterByEcu));
    }
    if (filterBySoftGroup) {
      updateFilterByFwSoftGroup && updateFilterByFwSoftGroup(Number(filterBySoftGroup));
    }
    initializeFiles && initializeFiles();
    return () => {
      clearState && clearState();
    };
  }, [initializeFiles, clearState, filterByEcu, filterBySoftGroup, updateFilterByFwSoftGroup, updateFilterByFwEcu]);
  return (
    <>
      <Layout noHeightConstraint>
        {expectAnswer && <Preloader pageCentered />}
        {!expectAnswer && (
          <Card variant='outlined' className={classes.container}>
            <Typography className={classes.title} variant='h4'>
              FW soft
            </Typography>
            {(filterByFwEcu || filterByFwSoftGroup) && (
              <div className={classes.filters}>
                {filterByFwEcu && <Chip label={`FW ECU - ${filterByFwEcu}`} onDelete={handleDeleteFilterByFwEcu} color='primary' />}
                {filterByFwSoftGroup && <Chip label={`FW soft group - ${filterByFwSoftGroup}`} onDelete={handleDeleteFilterByFwSoftGroup} color='primary' />}
              </div>
            )}
            <CardContent>
              <FwSoftsTable
                loadSoft={loadSoft}
                updateFilesPerPage={updateFilesPerPage}
                updatePage={updatePage}
                filesPerPage={Number(filesPerPage)}
                page={Number(page)}
                expectFwSoftAnswer={expectFwSoftAnswer}
                fwSoftList={fwSoftList}
                totalSoftCount={Number(totalSoftCount)}
                handleOpenDeleteDialog={handleOpenDeleteDialog}
                handleOpenEditDialog={handleOpenEditDialog}
                updateFwSoftIdDelete={updateFwSoftIdDelete}
                updateFwSoftIdEdit={updateFwSoftIdEdit}
                fwEcuList={fwEcuList}
                fwSearchGroup={fwSearchGroup}
                fwSearchVal={fwSearchVal}
                updateFwSearchGroup={updateFwSearchGroup}
                updateFwSearchVal={updateFwSearchVal}
                loadAllSoft={loadAllSoft}
                allListLoaded={allListLoaded}
                handleOpenCreateDialog={handleOpenCreateDialog}
                updateFwSoftEdit={updateFwSoftEdit}
              />
            </CardContent>
          </Card>
        )}
      </Layout>
      {openEditDialog && <FwSoftEditDialog open={openEditDialog} handleClose={handleCloseEditDialog} fwSoftGroup={fwSoftGroup} fwEcuList={fwEcuList} />}
      {openDeleteDialog && (
        <FwSoftDeleteDialog open={openDeleteDialog} handleClose={handleCloseDeleteDialog} fwSoftDelete={fwSoftDelete} fwEcuList={fwEcuList} handleAction={handleDelete} />
      )}
      {openCreateDialog && <FwSoftCreateDialog open={openCreateDialog} handleClose={handleCloseCreateDialog} fwSoftGroup={fwSoftGroup} fwEcuList={fwEcuList} />}
      <ToastContainer />
    </>
  );
}

export default connect(
  (state) => fwSoftSelector(state),
  (dispatch) => ({
    updatePage: (page: number) => dispatch(updateFwSoftDataAction({ page })),
    updateFilesPerPage: (filesPerPage: number) => dispatch(updateFwSoftDataAction({ filesPerPage })),
    updateFwSearchGroup: (fwSearchGroup: string) => dispatch(updateFwSoftDataAction({ fwSearchGroup })),
    updateFwSearchVal: (fwSearchVal: string) => dispatch(updateFwSoftDataAction({ fwSearchVal })),

    updateFilterByFwEcu: (filterByFwEcu: number | null) => dispatch(updateFwSoftDataAction({ filterByFwEcu })),
    updateFilterByFwSoftGroup: (filterByFwSoftGroup: number | null) => dispatch(updateFwSoftDataAction({ filterByFwSoftGroup })),

    updateFwSoftIdDelete: (fwSoftIdDelete: number | null) => dispatch(updateFwSoftDataAction({ fwSoftIdDelete })),
    updateFwSoftIdEdit: (fwSoftIdEdit: number | null) => dispatch(updateFwSoftDataAction({ fwSoftIdEdit })),
    updateFwSoftEdit: ({
      editedFwSoftEcuId,
      editedSoftFull,
      editedSoftIdx,
      editedFwSoftGroupId,
      editedConfig,
    }: {
      editedFwSoftEcuId: string | number;
      editedSoftFull: string | number;
      editedSoftIdx: string | number;
      editedFwSoftGroupId: string | number;
      editedConfig: string | number | null;
    }) => dispatch(updateFwSoftDataAction({ editedFwSoftEcuId, editedSoftFull, editedSoftIdx, editedFwSoftGroupId, editedConfig })),

    clearSuccessDeleteFeedback: () => dispatch(updateFwSoftDataAction({ fwSoftDeleteSuccess: false })),
    clearErrorDeleteFeedback: () => dispatch(updateFwSoftDataAction({ fwSoftDeleteError: false })),
    clearSuccessCreateFeedback: () => dispatch(updateFwSoftDataAction({ fwSoftCreateSuccess: false })),
    clearErrorCreateFeedback: () => dispatch(updateFwSoftDataAction({ fwSoftCreateError: false })),
    clearSuccessEditFeedback: () => dispatch(updateFwSoftDataAction({ fwSoftEditSuccess: false })),
    clearErrorEditFeedback: () => dispatch(updateFwSoftDataAction({ fwSoftEditError: false })),

    loadSoft: () => dispatch(loadSoftAction()),
    loadAllSoft: () => dispatch(loadAllSoftAction()),
    initializeFiles: () => dispatch(initializeFwEcusAction()),
    clearState: () => dispatch(clearStateAction()),
    approveDelete: () => dispatch(approveDeleteAction()),
  })
)(FwSoftFiles);
