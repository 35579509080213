// types
export const TYPE = ((prefix) => ({
  PREFIX: new RegExp(prefix, 'i'),
  // complex actions
  DATA: `${prefix}LOG_IN`,
  //
  ERROR: `${prefix}ERROR`,
  RESET_ERROR: `${prefix}RESET_ERROR`,
  // trigger
  LOG_IN_SUBMIT: `${prefix}LOG_IN_SUBMIT`,
  CLEAR: `${prefix}CLEAR`,
}))('@log-in/');

// selectors
export const selector = (state: any) => state.login;

export const FIELD_KEY = {
  email: 'email',
  password: 'password',
};
interface IUserState {
  email: string;
  password: string;
  expectAnswer: boolean;
  errors: ILoginError | null;
}
export interface ILoginError {
  email?: string | null;
  password?: string | null;
}

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

// login flow
export const updateEmailAction = (email: string) => typedAction(TYPE.DATA, { email });
export const updatePasswordAction = (password: string) => typedAction(TYPE.DATA, { password });
export const updateExpectAnswerAction = (expectAnswer: boolean) => typedAction(TYPE.DATA, { expectAnswer });
//
export const resetErrorLoginAction = (fieldKey: string) => typedAction(TYPE.RESET_ERROR, { fieldKey });
export const updateErrorLogInAction = (errors: ILoginError) => typedAction(TYPE.ERROR, errors);

export const loginSubmitAction = () => typedAction(TYPE.LOG_IN_SUBMIT);

type UserAction = ReturnType<
  | typeof updateEmailAction
  | typeof updatePasswordAction
  | typeof loginSubmitAction
  | typeof updateExpectAnswerAction
  | typeof updateErrorLogInAction
  | typeof resetErrorLoginAction
>;

const initialState: IUserState = {
  email: '',
  password: '',
  expectAnswer: false,
  errors: null,
};

export function login(state = initialState, action: UserAction): IUserState {
  switch (action.type) {
    default:
      return state;
    case TYPE.DATA:
      return { ...state, ...action.payload };
    case TYPE.ERROR:
      return _setError(state, action.payload);
    case TYPE.RESET_ERROR:
      return _resetError(state, action.payload);
  }
}

const _resetError = (state: IUserState, error: { fieldKey: string }) => {
  const newErrors = state.errors ? { ...state.errors, [error.fieldKey]: null } : { [error.fieldKey]: null };
  return { ...state, errors: newErrors };
};

const _setError = (state: IUserState, errors: ILoginError) => {
  const newErrors = state.errors ? { ...state.errors, ...errors } : errors;
  return { ...state, errors: newErrors };
};
