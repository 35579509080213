import React from 'react';
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    confirmButton: {
        width: "150px",
    }
});

interface IProps {
    children: any;
    disabled?: boolean;
    onClick: (event: any) => void;
    style?: any;
}

function ConfirmButton(props: IProps) {
    const {disabled, onClick, children, style} = props;
    const classes = useStyles();

    return <Button style={style} className={classes.confirmButton} variant={"contained"} color={"primary"}
                   onClick={onClick} disabled={disabled}>{children}</Button>
}

export default ConfirmButton;
