import React, {useCallback, useMemo} from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {Input, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 'auto',
        },
        paper: {
            width: 300,
            height: 330,
            overflow: 'auto',
        },
        paperFilter: {
            width: 280,
            height: 50,
            overflow: 'auto',
            padding: '10px 10px 0 10px'
        },
        paperFilterSpace: {
            width: 80,
        },
        button: {
            margin: theme.spacing(0.5, 0),
        },
    }),
);

function not(a: IListItem[], b: IListItem[]) {
    const bIds = b.map(el => el.id)
    return a.filter((value) => bIds.indexOf(value.id) === -1);
}

function intersection(a: IListItem[], b: IListItem[]) {
    const bIds = b.map(el => el.id)
    return a.filter((value) => bIds.indexOf(value.id) !== -1);
}

export interface IListItem {
    id: number
    name: string
}

export type FilteredTransferListProps = {
    leftSide: IListItem[]
    rightSide: IListItem[]
    setRightSelected(items: IListItem[]): void
    reset: number
}

export default function FilteredTransferList({leftSide, rightSide, setRightSelected, reset}: FilteredTransferListProps) {
    const classes = useStyles();
    const [checked, setChecked] = React.useState<IListItem[]>([]);
    const [left, setLeft] = React.useState<IListItem[]>([]);
    const [right, setRight] = React.useState<IListItem[]>([]);

    const leftChecked = useMemo(() => {
        return intersection(checked, left);
    }, [checked, left]);
    const rightChecked = useMemo(() => {
        return intersection(checked, right);
    }, [checked, right]);

    const [filter, setFilter] = React.useState<string>('');
    const filterListHandler = (event:  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilter(event.target.value)
    }

    const [filteredLeft, setFilteredLeft] = React.useState<IListItem[]>([...leftSide]);

    const fillFilteredLeft = useCallback(
        () => {
            const list = left.filter((el) => (el.name).toLowerCase().includes(filter.toLowerCase()))
            setFilteredLeft(list)
        },
        [filter, left],
    );

    React.useEffect(() => {
        fillFilteredLeft()
    }, [filter, fillFilteredLeft]);

    React.useEffect(() => {
        fillFilteredLeft()
    }, [left, fillFilteredLeft]);

    React.useEffect(() => {
        setLeft([...leftSide])
        setFilter('')
    }, [leftSide]);

    React.useEffect(() => {
        setFilter('')
    }, [reset])

    const handleToggle = (value: IListItem) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    // const handleAllRight = () => {
    //     setRight(right.concat(left));
    //     setLeft([]);
    // };

    const handleCheckedRight = () => {
        const list = right.concat(leftChecked);
        setRight(list);
        setRightSelected([...list]);
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        const list = not(right, rightChecked);
        setRight(list);
        setRightSelected([...list]);
        setChecked(not(checked, rightChecked));
    };

    // const handleAllLeft = () => {
    //     setLeft(left.concat(right));
    //     setRight([]);
    // };

    React.useEffect(() => {
        setRight([...rightSide])
    }, [rightSide]);

    const customList = (items: IListItem[]) => (
        <Paper className={classes.paper}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value.id}-label`;

                    return (
                        <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{'aria-labelledby': labelId}}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.name}/>
                        </ListItem>
                    );
                })}
                <ListItem/>
            </List>
        </Paper>
    );

    return (
        <>
            <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                className={classes.root}
            >
                <Grid item>
                    <Paper className={classes.paperFilter}>
                        <Input value={filter} onChange={filterListHandler}/>
                    </Paper>
                </Grid>
                <Grid item className={classes.paperFilterSpace}/>
                <Grid item>
                    <Paper className={classes.paperFilter}>
                        <Typography variant='h6'>
                            Выбранные блоки
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                className={classes.root}
            >
                <Grid item>{customList(filteredLeft)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        {/*<Button*/}
                        {/*    variant="outlined"*/}
                        {/*    size="small"*/}
                        {/*    className={classes.button}*/}
                        {/*    onClick={handleAllRight}*/}
                        {/*    disabled={left.length === 0}*/}
                        {/*    aria-label="move all right"*/}
                        {/*>*/}
                        {/*    ≫*/}
                        {/*</Button>*/}
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                        {/*<Button*/}
                        {/*    variant="outlined"*/}
                        {/*    size="small"*/}
                        {/*    className={classes.button}*/}
                        {/*    onClick={handleAllLeft}*/}
                        {/*    disabled={right.length === 0}*/}
                        {/*    aria-label="move all left"*/}
                        {/*>*/}
                        {/*    ≪*/}
                        {/*</Button>*/}
                    </Grid>
                </Grid>
                <Grid item>{customList(right)}</Grid>
            </Grid>
        </>
    );
}