import React, {useEffect, useState} from 'react';
import {useDropzone} from "react-dropzone";
import {
  DefaultTableOptions,
} from "../../client-request-files/reducer";
import {Button} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CustomTextareaAutosize from "../../../components/input/Textarea";
import CustomCheckbox from "../../../components/checkbox/Checkbox";
import {makeStyles} from "@material-ui/core/styles";
import {APP_COLOR} from "../../../constants/style";
import {API_FORM_DATA} from "../../../services/request.service";
import {useParams} from "react-router";
import Preloader from "../../../components/Preloader";
import {toast} from "react-toastify";

const useStyles = makeStyles({
  root: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  paperFullWidth: {
    width: 800,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 24px',
    backgroundColor: APP_COLOR.GREY,
    color: APP_COLOR.WHITE,
  },
  close: {
    padding: 6,
    color: APP_COLOR.WHITE,
    cursor: 'pointer',
  },
  titleContent: {
    lineHeight: 2,
  },
  infoTitle: {
    marginBottom: 16
  },
  container: {
    display: 'flex',
    padding: 12,
    width: '100%',
    flexDirection: 'column'
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  infoSection: {
    paddingTop: '20px',
    display: 'flex',
    width: '48%',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  continueAction: {
    backgroundColor: APP_COLOR.LIGHT_GREY,
    color: APP_COLOR.WHITE,
    '&:hover': {
      color: APP_COLOR.BLACK,
    },
    transition: '0.6s',
  },
  slaveEncoded: {
    color: 'red'
  }
});

interface IProps {
  changeCount?: (count: number) => void
}

function Uploading({changeCount}: IProps) {
  const {key} = useParams<{key: string}>();
  const classes = useStyles();

  const { getInputProps, acceptedFiles, open } = useDropzone({
    maxFiles: DefaultTableOptions.MAX_FILES_UPLOAD_COUNT,
    noClick: true,
    noKeyboard: true,
    // onDrop: (files, props) => console.log('onDrop', files, props),
  });

  const [uploading, setUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [notifyClient, setNotifyClient] = useState<boolean>(true);
  const [fileComment, setFileComment] = useState<string>('');

  useEffect(() => {
    if (acceptedFiles.length) {
      setUploadedFile(acceptedFiles[0])
    }
  }, [acceptedFiles])

  const handleNotifyClient = () => {
    setNotifyClient(!notifyClient);
  };

  const handleFileComment = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileComment(e.target.value);
  };

  const uploadFile = () => {
    if (!uploadedFile) {
      return
    }
    setUploading(true)
    const formData = new FormData();
    formData.append('fileKey', key);
    formData.append('uploadedFile', uploadedFile);
    formData.append('notifyClient', notifyClient ? 'true' : 'false');
    formData.append('comment', fileComment);
    formData.append('approvePayment', 'true');

    API_FORM_DATA
      .post('/admin/request-file/upload', formData)
      .catch(() => {
        toast.error('Не удалось обработать файл');
      })
      .then(data => {
        if (data) {
          changeCount && changeCount(data.data?.response?.history_count?.count || 0)
        }
      })
      .then(() => {
        toast.success('Файл успешно загружен')
        setUploadedFile(null)
      })
      .finally(() => setUploading(false))
  }

  const handleFileUpload = () => {
    uploadFile();
  }

  if (uploading) {
    return <Preloader pageCentered={true}/>
  }

  return (
    <>
      <input {...getInputProps({})} />
      <Button onClick={open} variant="outlined">Выберите файл</Button>

      {Boolean(uploadedFile) && (
        <div className={classes.infoSection}>
          <Typography variant='h6' className={classes.infoTitle}>
            <strong>Данные загружаемого файла</strong>
          </Typography>
          <span>
                  <strong>Имя файла</strong> - {uploadedFile?.name}
                </span>
          <br />
          <span>
                  <strong>Размер</strong> - {uploadedFile?.size} bytes
                </span>
          <br />
          <CustomTextareaAutosize name='fileComment' label='Comment' onChange={handleFileComment} />
          <CustomCheckbox label='Уведомить клиента' onChange={handleNotifyClient} checked={notifyClient} />
          <br/>

          <Button onClick={handleFileUpload} color="primary" variant="contained">
            Загрузить
          </Button>
        </div>
      )}
    </>
  )
}

export default Uploading