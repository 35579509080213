// types
export const TYPE = ((prefix) => ({
  PREFIX: new RegExp(prefix, 'i'),
  // complex actions
  DATA: `${prefix}DATA`,
  // state
  ERROR_CREATE: `${prefix}ERROR_CREATE`,
  RESET_CREATE_ERROR: `${prefix}RESET_CREATE_ERROR`,
  ERROR_EDIT: `${prefix}ERROR_EDIT`,
  RESET_EDIT_ERROR: `${prefix}RESET_EDIT_ERROR`,
  // trigger
  LOAD_SOFT: `${prefix}LOAD_SOFT`,
  SAVE_NEW_SOFT: `${prefix}SAVE_NEW_SOFT`,
  EDIT_SOFT: `${prefix}EDIT_SOFT`,
  LOAD_ALL_LIST: `${prefix}LOAD_ALL_LIST`,
  APPROVE_DELETE: `${prefix}APPROVE_DELETE`,
  INITIALIZE_FILES: `${prefix}INITIALIZE_FILES`,
  CLEAR: `${prefix}CLEAR`,
}))('@fw-soft/');

// selector
export const selector = (state: any) => state.fwSoft;
// UI display constants
const DEFAULT_FILES_COUNT = 50;
const DEFAULT_PAGE = 0;
const FILES_COUNT_OPTIONS = [DEFAULT_FILES_COUNT, 150, 250, 500];
export const DefaultTableOptions = {
  DEFAULT_FILES_COUNT,
  DEFAULT_PAGE,
  FILES_COUNT_OPTIONS,
};

export const CREATE_FIELD_KEY = {
  newFwSoftEcuId: 'newFwSoftEcuId',
  newSoftFull: 'newSoftFull',
  newSoftIdx: 'newSoftIdx',
  newFwSoftGroupId: 'newFwSoftGroupId',
  newConfig: 'newConfig',
};
export const EDIT_FIELD_KEY = {
  editedFwSoftEcuId: 'editedFwSoftEcuId',
  editedSoftFull: 'editedSoftFull',
  editedSoftIdx: 'editedSoftIdx',
  editedFwSoftGroupId: 'editedFwSoftGroupId',
  editedConfig: 'editedConfig',
};
export interface ICreateFormError {
  newFwSoftEcuId?: string | null;
  newSoftFull?: string | null;
  newSoftIdx?: string | null;
  newFwSoftGroupId?: string | null;
  newConfig?: string | null;
}
export interface ICreateForm {
  newFwSoftEcuId?: string | null;
  newSoftFull?: string | null;
  newSoftIdx?: string | null;
  newFwSoftGroupId?: string | null;
  newConfig?: string | null;
}
export interface IEditFormError {
  editedFwSoftEcuId?: string | null;
  editedSoftFull?: string | null;
  editedSoftIdx?: string | null;
  editedFwSoftGroupId?: string | null;
  editedConfig?: string | null;
}
export interface IEditForm {
  editedFwSoftEcuId?: string | null;
  editedSoftFull?: string | null;
  editedSoftIdx?: string | null;
  editedFwSoftGroupId?: string | null;
  editedConfig?: string | null;
}
export interface IFwEcu {
  key: string;
  name: string;
}
export interface IFwSoftGroup {
  key: string;
  name: string;
}
export interface IFwSoft {
  fw_soft_: number;
  fw_ecu_: number;
  fw_soft_group_: number;
  soft_full: number;
  soft_idx: number;
  config: string | null;
  fw_key?: string;
}
interface IState {
  fwSoftList?: IFwSoft[] | null;
  totalSoftCount?: number;
  fwEcuList?: IFwEcu[] | null;
  fwSoftGroup?: IFwSoftGroup[] | null;

  expectAnswer?: boolean;
  expectFwSoftAnswer?: boolean;

  page?: number | null;
  filesPerPage?: number | null;
  fwSearchGroup?: string;
  fwSearchVal?: string; // soft_full, soft_idx and config

  fwSoftIdDelete?: number | null;
  fwSoftIdEdit?: number | null;

  filterByFwEcu?: number | null;
  filterByFwSoftGroup?: number | null;

  allListLoaded?: boolean;
  fwSoftDeleteSuccess?: boolean;
  fwSoftDeleteError?: boolean;
  fwSoftCreateSuccess?: boolean;
  fwSoftCreateError?: boolean;
  fwSoftEditSuccess?: boolean;
  fwSoftEditError?: boolean;
  //
  newFwSoftEcuId?: number | string;
  newSoftFull?: string;
  newSoftIdx?: string;
  newFwSoftGroupId?: number | string;
  newConfig?: string;

  editedFwSoftEcuId?: number | string;
  editedSoftFull?: string | number;
  editedSoftIdx?: string | number;
  editedFwSoftGroupId?: number | string;
  editedConfig?: string | number | null;

  createErrors?: ICreateFormError | null;
  editErrors?: IEditFormError | null;
}

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}
// state flow
export const updateFwSoftDataAction = (data: IState) => typedAction(TYPE.DATA, data);

export const resetErrorCreateAction = (fieldKey: string) => typedAction(TYPE.RESET_CREATE_ERROR, { fieldKey });
export const updateErrorCreateAction = (createErrors: ICreateFormError) => typedAction(TYPE.ERROR_CREATE, createErrors);
export const resetErrorEditAction = (fieldKey: string) => typedAction(TYPE.RESET_EDIT_ERROR, { fieldKey });
export const updateErrorEditAction = (editErrors: IEditFormError) => typedAction(TYPE.ERROR_EDIT, editErrors);
// triggers
export const loadSoftAction = () => typedAction(TYPE.LOAD_SOFT);
export const loadAllSoftAction = () => typedAction(TYPE.LOAD_ALL_LIST);
export const approveDeleteAction = () => typedAction(TYPE.APPROVE_DELETE);
export const initializeFwEcusAction = () => typedAction(TYPE.INITIALIZE_FILES);
export const clearStateAction = () => typedAction(TYPE.CLEAR);
export const saveNewSoftAction = () => typedAction(TYPE.SAVE_NEW_SOFT);
export const editSoftAction = () => typedAction(TYPE.EDIT_SOFT);

type UserAction = ReturnType<
  | typeof updateFwSoftDataAction
  //
  | typeof loadSoftAction
  | typeof initializeFwEcusAction
  | typeof clearStateAction
  | typeof loadAllSoftAction
  | typeof approveDeleteAction
>;

const initialState: IState = {
  fwSoftList: null,
  totalSoftCount: 0,

  expectAnswer: false,
  expectFwSoftAnswer: false,

  page: DefaultTableOptions.DEFAULT_PAGE,
  filesPerPage: DefaultTableOptions.DEFAULT_FILES_COUNT,
  fwSearchGroup: '',
  fwSearchVal: '', // soft_full, soft_idx and config

  fwSoftIdDelete: null,
  fwSoftIdEdit: null,

  filterByFwEcu: null,
  filterByFwSoftGroup: null,

  allListLoaded: false,
  fwSoftDeleteSuccess: false,
  fwSoftDeleteError: false,
  fwSoftCreateSuccess: false,
  fwSoftCreateError: false,
  fwSoftEditSuccess: false,
  fwSoftEditError: false,
  //
  newFwSoftEcuId: '',
  newSoftFull: '',
  newSoftIdx: '',
  newFwSoftGroupId: '',
  newConfig: '',

  editedFwSoftEcuId: '',
  editedSoftFull: '',
  editedSoftIdx: '',
  editedFwSoftGroupId: '',
  editedConfig: '',

  createErrors: null,
  editErrors: null,
};

export function fwSoft(state = initialState, action: UserAction): IState {
  switch (action.type) {
    default:
      return state;
    case TYPE.DATA:
      return { ...state, ...action.payload };
    case TYPE.CLEAR:
      return initialState;
    case TYPE.RESET_CREATE_ERROR:
      return _resetCreateError(state, action.payload);
    case TYPE.ERROR_CREATE:
      return _setCreateError(state, action.payload);
    case TYPE.RESET_EDIT_ERROR:
      return _resetEditError(state, action.payload);
    case TYPE.ERROR_EDIT:
      return _setEditError(state, action.payload);
  }
}

const _resetCreateError = (state: IState, error: { fieldKey: string }) => {
  const newErrors = state.createErrors ? { ...state.createErrors, [error.fieldKey]: null } : { [error.fieldKey]: null };
  return { ...state, createErrors: newErrors };
};
const _setCreateError = (state: IState, errors: ICreateFormError) => {
  const newErrors = state.createErrors ? { ...state.createErrors, ...errors } : errors;
  return { ...state, createErrors: newErrors };
};
const _resetEditError = (state: IState, error: { fieldKey: string }) => {
  const newErrors = state.editErrors ? { ...state.editErrors, [error.fieldKey]: null } : { [error.fieldKey]: null };
  return { ...state, editErrors: newErrors };
};
const _setEditError = (state: IState, errors: IEditFormError) => {
  const newErrors = state.editErrors ? { ...state.editErrors, ...errors } : errors;
  return { ...state, editErrors: newErrors };
};
