// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, InjectedFormProps } from 'redux-form';
// UI
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
// local dependencies
// UI
import CustomTextField from 'components/input/TextField';
import Layout from 'components/layout/Common';
// constants
import { APP_COLOR } from 'constants/style';
// state
import {
  selector as loginSelector,
  // actions
  updateEmailAction,
  updatePasswordAction,
  loginSubmitAction,
  updateErrorLogInAction,
  resetErrorLoginAction,
  // constants
  FIELD_KEY,
  // interface
  ILoginError,
} from 'public-layout/log-in/reducer';
// services
import ValidationService from 'services/validation.service';

// configurations
export const FORM_NAME = 'loginForm';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '70%',
  },
  content: {
    width: '50%',
  },
  titleContent: {
    lineHeight: 2,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 6,
    marginTop: 16,
  },
  loginAction: {
    backgroundColor: APP_COLOR.LIGHT_GREY,
    color: APP_COLOR.WHITE,
    '&:hover': {
      color: APP_COLOR.BLACK,
    },
    transition: '0.6s',
  },
});

interface IProps {
  updateEmail?: (email: string) => void;
  updatePassword?: (password: string) => void;
  email?: string | null;
  password?: string | null;
  errors?: ILoginError | null;
  loginSubmit?: () => void;
  expectAnswer?: boolean;
  validate?: (loginFormData: ILoginForm) => ILoginFormError | null;
  resetError?: (fieldKey: string) => void;
  updateErrorLogIn?: (errors: ILoginError) => void;
  handleSubmit?: any; // redux
}

const LogIn: React.FC<IProps & InjectedFormProps<{}, IProps>> = (props: IProps) => {
  const {
    email,
    password,
    expectAnswer,
    errors,
    //
    updateEmail,
    updatePassword,
    loginSubmit,
    resetError,
    updateErrorLogIn,
    //
    validate,
    handleSubmit,
  } = props;
  const classes = useStyles();

  const isEmailError = Boolean(errors?.email);
  const isPasswordError = Boolean(errors?.password);
  const emailError = typeof errors?.email === 'string' ? errors.email : '';
  const passwordError = typeof errors?.password === 'string' ? errors.password : '';

  const isSubmitNotAvailable = errors && Boolean(Object.values(errors).filter((error) => Boolean(error)).length);

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (updateEmail) updateEmail(e.target.value);
  };
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (updatePassword) updatePassword(e.target.value);
  };

  const handleOnFocus = (fieldKey: string) => () => {
    const isErrorInState = errors && Object.keys(errors).some((errorKey: string, index) => Object.values(errors)[index] && fieldKey === errorKey);
    if (isErrorInState && resetError) {
      resetError(fieldKey);
    }
  };

  const handleFormLogin = (e: React.FormEvent<HTMLInputElement>) => {
    // prevent reload on 'ENTER' press
    e.preventDefault();
    e.persist();
    handleLogin();
  };

  const handleLogin = () => {
    const localErrors =
      validate &&
      validate({
        email,
        password,
      });
    if (!localErrors && !isSubmitNotAvailable) {
      loginSubmit && loginSubmit();
    }
    if (localErrors && updateErrorLogIn) {
      updateErrorLogIn(localErrors);
    }
  };

  return (
    <Layout>
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography variant='h6' className={classes.titleContent}>
            Логин
          </Typography>
          <Form onSubmit={handleSubmit(handleFormLogin)} className={classes.form}>
            <Field
              type='text'
              component={CustomTextField}
              name='email'
              placeholder='Enter your email'
              label='Email: '
              onChange={handleEmail}
              error={isEmailError}
              onFocus={handleOnFocus(FIELD_KEY.email)}
              helperText={emailError}
            />
            <Field
              type='password'
              component={CustomTextField}
              name='password'
              placeholder='Enter your password'
              label='Password: '
              style={{ marginTop: 16 }}
              onChange={handlePassword}
              onFocus={handleOnFocus(FIELD_KEY.password)}
              error={isPasswordError}
              helperText={passwordError}
            />
          </Form>
          <div className={classes.actions}>
            {expectAnswer && <CircularProgress />}
            {!expectAnswer && (
              <Button onClick={handleLogin} className={classes.loginAction} disabled={Boolean(isSubmitNotAvailable)}>
                Continue
              </Button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

interface ILoginForm {
  email?: string | null;
  password?: string | null;
}
interface ILoginFormError {
  email?: string | null;
  password?: string | null;
}

export default connect(
  (state) => ({
    ...loginSelector(state),
    initialValues: {
      email: loginSelector(state).email,
      password: loginSelector(state).password,
    },
  }),
  (dispatch) => ({
    updateEmail: (email: string) => dispatch(updateEmailAction(email)),
    updatePassword: (password: string) => dispatch(updatePasswordAction(password)),
    loginSubmit: () => dispatch(loginSubmitAction()),
    resetError: (fieldKey: string) => dispatch(resetErrorLoginAction(fieldKey)),
    updateErrorLogIn: (errors: ILoginError) => dispatch(updateErrorLogInAction(errors)),
  })
)(
  reduxForm<{}, IProps>({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values: ILoginForm): any => {
      const errors: ILoginFormError = {
        email: null,
        password: null,
      };
      const emailInvalid = typeof values.email === 'string' && ValidationService.getEmailInvalidReason(values.email, null);
      const passwordInvalid = typeof values.password === 'string' && ValidationService.getPasswordInvalidReason(values.password, null);
      if (emailInvalid) {
        errors.email = emailInvalid;
      }
      if (passwordInvalid) {
        errors.password = passwordInvalid;
      }
      // if at least one error were found
      if (Object.values(errors).filter((error) => Boolean(error)).length) return errors;
      return null;
    },
  })(LogIn)
);
