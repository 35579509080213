import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from '@material-ui/core/TableBody';
import TableContainer from "@material-ui/core/TableContainer";
import {TableCell, TablePagination, ThemeProvider} from '@material-ui/core'
import {createTheme} from '@material-ui/core/styles';
import FwDtcConfigTableRow from "./FwDtcConfigTableRow";

const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '16px 6px 16px 8px',
                fontSize: "0.82rem",
                letterSpacing: "0.01061em"
            },
        },
    },
});


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    row: {
        maxWidth: 250,
    },
    rowCenter: {
        textAlign: 'center',
    },
    status_1: {
        color: 'black',
        fontWeight: 'bold'
    },
    status_2: {
        color: 'red',
        fontWeight: 'bold'
    },
    status_3: {
        color: 'blue',
        fontWeight: 'bold'
    },
    status_4: {
        color: 'gray',
        fontWeight: 'bold'
    },
    archivedByDdmin: {
        opacity: 0.2
    },
    slaveEncoded: {
        color: 'red'
    },
    checkCircleIcon: {
        marginLeft: '5px',
        width: '16px',
        height: '16px',
        color: '#4caf50',
        marginBlock: '-3px'
    },
    telegramIcon: {
        marginLeft: '5px',
        width: '25px',
        height: '25px',
        color: '#115293',
        marginBlock: '-6px'
    },
    editIcon: {
        marginRight: '10px',
        "&:hover": {
            cursor: "pointer"
        }
    },
    blockUnblockIcon: {
        "&:hover": {
            cursor: "pointer"
        }
    }
});

interface IProps {
    configs: any
    changeTableContent: () => void;
}

function FwDtcConfigTable(props: IProps) {
    const {configs, changeTableContent} = props;

    const classes = useStyles();

    const onChangePageHandle = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        let url = new URL(window.location.href);
        url.searchParams.set("page", (page + 1).toString());
        window.history.replaceState(null, "", url.href);
        changeTableContent()
    }

    return (
        <div>
            {configs ?
                <Paper className={classes.root}>
                    <TableContainer>
                        <ThemeProvider theme={theme}>
                            <Table stickyHeader={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="30px">
                                            #
                                        </TableCell>
                                        <TableCell width="100px">
                                            ecu name
                                        </TableCell>
                                        <TableCell width="150px">
                                            action name
                                        </TableCell>
                                        <TableCell>
                                            dtc
                                        </TableCell>
                                        <TableCell width="90px">
                                            -
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {configs.configs.map((config: any) => {
                                        return (
                                            <FwDtcConfigTableRow key={config.fw_dtc_config_} changeTableContent={changeTableContent} config={config}/>
                                        )
                                    })}

                                </TableBody>
                            </Table>
                        </ThemeProvider>
                    </TableContainer>

                    <TablePagination
                        component={"div"}
                        rowsPerPageOptions={[]}
                        count={configs ? configs.count : 0}
                        onPageChange={onChangePageHandle}
                        page={new URL(window.location.href).searchParams.get("page")
                            ? Number(new URL(window.location.href).searchParams.get("page")) - 1 : 0}
                        rowsPerPage={20}/>

                </Paper> : null}
        </div>
    )
}


export default FwDtcConfigTable;
