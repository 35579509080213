import React, {useEffect, useState} from 'react';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography
} from "@material-ui/core";
import {Link} from "react-router-dom";
import {OneFileStatus} from "../../constants/file"
import {makeStyles} from "@material-ui/core/styles";
import CloseDialog from "./CloseDialog";
import FileInfoDefItem from "./FileInfoDefItem";
import {currentConf} from "../../services/request.service";
import currentConfig from "../../utils/config.utils";

const useStyles = makeStyles({
  title: {
    color: 'black',
    paddingBottom: 10
  },
  tabContainer: {
    padding: "20px"
  },
  status_1: {
    color: 'black',
  },
  status_2: {
    color: 'red',
  },
  status_3: {
    color: 'blue',
  },
  status_4: {
    color: 'gray',
  },
  status_99: {
    color: 'red',
    fontWeight: 'bold'
  },
  closeLink: {
    fontSize: '10px',
    margin: '0 10px',
  },
  root: {
    width: '80%',
    padding: '16px 0 0 16px',
  },
  deleteBtn: {
    marginLeft: 10,
  }
});

interface IProps {
  fileKey: string
  onTaskClose: () => void
}

export default function FileInfo({fileKey, onTaskClose}: IProps) {
  const classes = useStyles();

  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [downloadLinkBin, setDownloadLinkBin] = useState('');

  const closeTask = () => {
    setShowCloseDialog(true)
  }

  const onCloseHandler = (saved: boolean) => {
    setShowCloseDialog(false)
    saved && loadFileData(fileKey) && onTaskClose()
  }

  const loadFileData = (key: string | null) => {
    const config = currentConfig();
    fetch(config.apiUrl + 'admin/client-request-file-info', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify({
        fileKey: key
      })
    }).then(response => response.json())
      .then(data => data.response.data)
      .then(data => {
        setFile(data)

        setDownloadLinkBin(currentConf.apiUrl + "file/download/client/" + data.client_request_file_ + "/no/")
      });
      return true
  }

  useEffect(() => {
    loadFileData(fileKey)
  }, [fileKey])

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <List
            subheader={
              <ListSubheader component="div">
                <Typography className={classes.title} variant='h4'>
                  Данные файла
                </Typography>
              </ListSubheader>
            }
            className={classes.root}
            dense={true}
          >

            <FileInfoDefItem def="Ключ" val={
              <Link to={`/client-request-files?search=${file?.key}&status=0`}>{file?.key}</Link>
            }/>
            <FileInfoDefItem def="Название" val={file?.name}/>
            <FileInfoDefItem def="ECU" val={`${file?.brand_name} - ${file?.ecu_name}`}/>
            <FileInfoDefItem def="Действия" val={file?.actions}/>
            {file?.comment && (<FileInfoDefItem def="Комментарий" val={file?.comment}/>)}
            {file?.mms_file_type && (<FileInfoDefItem def="MMS Type" val={file?.mms_file_type}/>)}
            {file && (<FileInfoDefItem
              def="Статус"
              val={
                <>
                  <span
                    // eslint-disable-next-line
                    className={eval('classes.status_' + file.status)}>
                            {OneFileStatus[Number(file.status)]}
                  </span>
                  {file.status < 99 && (<Button variant="contained" size="small" color="secondary" className={classes.deleteBtn} onClick={closeTask}>
                    Закрыть
                  </Button>)}
                </>
              }
              // button={!file?.archived_by_admin &&
              //   <ListItemSecondaryAction>
              //       <Button variant="contained" size="small" color="secondary" onClick={closeTask}>
              //           Закрыть
              //       </Button>
              //   </ListItemSecondaryAction>
              // }
            />)}
            {file?.close_reason && (<FileInfoDefItem
              def="Причина закрытия"
              val={file?.close_reason}
            />)}
            {file?.closed_in_file_key && (<FileInfoDefItem
              def="Обработан в задаче"
              val={<Link to={`/file/${file?.closed_in_file_key}`}>{file?.closed_in_file_key}</Link>}
            />)}
            {file?.dtc && <FileInfoDefItem def="DTC" val={file?.dtc}/>}

            <ListItem>
              <ListItemText>
                <a href={`${downloadLinkBin}`} target="_blank" rel="noopener noreferrer">Скачать оригинал bin</a>
              </ListItemText>
            </ListItem>

          </List>
        </Grid>

        <Grid item xs={6}>

          <List
            subheader={
              <ListSubheader component="div">
                <Typography className={classes.title} variant='h4'>
                  Данные клиента
                </Typography>
              </ListSubheader>
            }
            className={classes.root}
            dense={true}
          >

            <FileInfoDefItem def="ID" val={file?.client_}/>
            <FileInfoDefItem def="Username" val={`${file?.username} ${file?.first_name && file?.last_name ? `${file?.first_name} ${file?.last_name}` : ''}`}/>
            {file?.mobile_phone && <FileInfoDefItem def="Телефон" val={file?.mobile_phone}/>}
            <FileInfoDefItem def="Email" val={file?.email}/>
            {file?.company && <FileInfoDefItem def="Компания" val={file?.company}/>}
            {file?.mms_key && <FileInfoDefItem def="MMS Key" val={file?.mms_key}/>}
          </List>

        </Grid>
      </Grid>

      <CloseDialog fileKey={file?.key} show={showCloseDialog} onClose={onCloseHandler}/>
    </>
  )
}