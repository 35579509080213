import React, {useEffect, useState} from 'react';
import Preloader from "../../components/Preloader";
import Layout from "../../components/layout/Common";
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import currentConfig from "../../utils/config.utils";
import StActionBrandEcuTable from "./components/StActionBrandEcuTable";
import StActionBrandEcuHeader from "./components/StActionBrandEcuHeader";
import {IActionBrandResponse, ISettings} from "./types";

const useStyles = makeStyles({
    container: {
        minHeight: '80vh',
    },
    title: {
        padding: '16px 0 0 16px'
    }
});

const rowsPerPage = 20;

function StActionBrandEcu() {
    const classes = useStyles();
    const [expectAnswer, setExpectAnswer] = useState<boolean>(false);
    const [actions, setActions] = useState<IActionBrandResponse>({
        actions: [],
        count: '0',
    });

    const changeTableContent = () => {
        setExpectAnswer(true);
        const config = currentConfig();
        const initUrl = new URL(window.location.href);

        fetch(config.apiUrl + 'st_action_brand_ecu_list', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                count: rowsPerPage,
                page: initUrl.searchParams.get("page") ? Number(initUrl.searchParams.get("page")) : 1,
                status: {
                    st_action_: initUrl.searchParams.get("st_action_") === null ? "" : initUrl.searchParams.get("st_action_"),
                    st_brand_: initUrl.searchParams.get("st_brand_") === null ? "" : initUrl.searchParams.get("st_brand_"),
                    st_ecu_: initUrl.searchParams.get("st_ecu_") === null ? "" : initUrl.searchParams.get("st_ecu_"),
                    search: initUrl.searchParams.get("search") === null ? "" : initUrl.searchParams.get("search"),
                }
            })
        })
            .then(response => response.json())
            .then(data => {
                setActions(data.response);
                setExpectAnswer(false);
            })
            .catch(err => {
                console.log('error:', err)
            })

    }

    const [actionSettings, setActionSettings] = useState<ISettings | null>(null);
    const fetchConfigSettings = () => {
        const config = currentConfig();
        fetch(config.apiUrl + 'st_action_brand_ecu_settings', {
            method: 'get',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
        })
            .then(response => response.json())
            .then((response) => {
                setActionSettings(response.response.settings);
            })
            .catch(err => {
                console.log('error:', err)
            })
    }

    useEffect(() => {
        changeTableContent();
        fetchConfigSettings();
    }, []);


    return (
        <>
            <Layout noHeightConstraint>
                <Card variant='outlined' className={classes.container}>
                    <Typography className={classes.title} variant='h4'>
                        Связь Блока, Бренда и Действий
                    </Typography>
                    <StActionBrandEcuHeader changeTableContent={changeTableContent} actionSettings={actionSettings}/>
                    <CardContent>
                        {expectAnswer && <Preloader pageCentered/>}
                        {!expectAnswer &&
                            <StActionBrandEcuTable changeTableContent={changeTableContent} actions={actions}/>}
                    </CardContent>
                </Card>
            </Layout>
        </>);

}

export default StActionBrandEcu;
