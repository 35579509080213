// outsource dependencies
import React from 'react';
// UI
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
  },
  pageCentered: {
    height: '75%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface IProps {
  pageCentered?: boolean;
  centered?: boolean;
  size?: number;
}

function Preloader(props: IProps) {
  const { centered, size, pageCentered } = props;
  const classes = useStyles();
  if (centered)
    return (
      <div className={classes.centered}>
        <CircularProgress size={100} />
      </div>
    );
  if (pageCentered)
    return (
      <div className={classes.pageCentered}>
        <CircularProgress size={100} />
      </div>
    );
  return <CircularProgress size={size} />;
}

Preloader.defaultProps = {
  pageCentered: false,
  centered: false,
  size: 40,
};

export default Preloader;
