import React, {useEffect, useState} from "react";
import ConfirmButton from "../../../components/button/ConfirmButton";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography
} from "@material-ui/core";
import CancelButton from "../../../components/button/CancelButton";
import currentConfig from "../../../utils/config.utils";
import {makeStyles} from "@material-ui/core/styles";
import {IBaseStEcu} from "../types";
import {toast} from "react-toastify";

interface IProps {
    changeTableContent: () => void;
}

const useStyles = makeStyles({
    headerActions: {
        marginRight: "20px",
        display: "flex",
        justifyContent: "flex-end",
        columnGap: "20px"
    }
});

const defaultStEcu = {
    action_page_source: "",
    config: "",
    name: "",
    original_name: ""
}

const StEcuHeader = (props: IProps) => {
    const {changeTableContent} = props;
    const classes = useStyles();

    const [openNewStEcu, setOpenNewStEcu] = useState<boolean>(false);
    const [newStEcu, setNewStEcu] = useState<IBaseStEcu>(defaultStEcu);
    const [search, setSearch] = useState('');

    useEffect(() => {
        const initUrl = new URL(window.location.href);
        const search = initUrl.searchParams.get("search") || ''
        setSearch(search)
    }, []);


    const isBtnDisabled = () => {
        return newStEcu.name.trim().length === 0
            || newStEcu.original_name.trim().length === 0;
    }

    const changeNewStEcuHandle = (event: any, field: keyof IBaseStEcu) => {
        setNewStEcu({
            ...newStEcu,
            [field]: event.target.value
        })
    }

    const openNewStEcuHandle = () => {
        setOpenNewStEcu(true)
    }

    const closeNewFwEcuConfigDialogHandle = () => {
        setNewStEcu(defaultStEcu)

        setOpenNewStEcu(false);
    }

    const addNewItemHandle = () => {
        const config = currentConfig();
        fetch(config.apiUrl + 'st-ecu', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify(newStEcu)
        })
            .then(response => response.json())
            .then((res) => {
                if (res.result === 'error') {
                    toast.error(res.response.detail);
                    return;
                }
                toast.success('Блок добавлен');
                setOpenNewStEcu(false);
                setNewStEcu(defaultStEcu)

                handleOnClickCancelFilter();
            })
            .catch(err => {
                console.log('error:', err)
            })
    }

    const handleOnClickFilter = () => {
        let url = new URL(window.location.href);
        url.searchParams.set("page", "1");
        url.searchParams.set("search", search);

        window.history.replaceState(null, "", url.href);
        changeTableContent();
    }

    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            handleOnClickFilter();
        }
    }

    const handleOnClickCancelFilter = () => {
        let url = new URL(window.location.href);
        url.searchParams.set("page", "1");
        url.searchParams.set("search", "");

        window.history.replaceState(null, "", url.href);

        setSearch('')

        changeTableContent();
    }

    return (
        <div>
            <div className={classes.headerActions}>
                <TextField onKeyDown={handleKeyDown}
                           onChange={(event) => setSearch(event.target.value)}
                           value={search} label="Search"/>

                <ConfirmButton onClick={handleOnClickFilter}>Фильтовать</ConfirmButton>
                <CancelButton onClick={handleOnClickCancelFilter}>Сбросить</CancelButton>
                <ConfirmButton onClick={openNewStEcuHandle}>New</ConfirmButton>
            </div>

            <Dialog open={openNewStEcu}>
                <DialogTitle>Новый St Ecu</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Заполните поля:
                    </DialogContentText>
                    <div>
                        <Typography>Name</Typography>
                        <TextField onChange={(event) => changeNewStEcuHandle(event, 'name')}
                                   value={newStEcu.name} fullWidth={true} multiline={false}/>

                        <Typography>Original name</Typography>
                        <TextField onChange={(event) => changeNewStEcuHandle(event, 'original_name')}
                                   value={newStEcu.original_name} fullWidth={true} multiline={true}/>

                        <Typography>Action page source</Typography>
                        <TextField onChange={(event) => changeNewStEcuHandle(event, 'action_page_source')}
                                   value={newStEcu.action_page_source} fullWidth={true} multiline={true}/>

                        <Typography>Config</Typography>
                        <TextField onChange={(event) => changeNewStEcuHandle(event, 'config')}
                                   value={newStEcu.config} fullWidth={true} multiline={false}/>
                    </div>
                </DialogContent>
                <DialogActions disableSpacing={true}>
                    <ConfirmButton disabled={isBtnDisabled()} onClick={addNewItemHandle}>Создать</ConfirmButton>
                    <CancelButton style={{marginLeft: "20px"}}
                                  onClick={closeNewFwEcuConfigDialogHandle}>Отменить</CancelButton>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default StEcuHeader;


