import React, {useEffect, useState} from 'react';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Layout from "../../components/layout/Common";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router-dom";
import ClientEditForm from "./components/ClientEditForm";
import {Dialog, DialogActions, DialogContent, DialogContentText} from "@material-ui/core";
import ConfirmButton from "../../components/button/ConfirmButton";
import currentConfig from "../../utils/config.utils";

const useStyles = makeStyles({
    container: {
        minHeight: '80vh',
    },
    title: {
        textAlign: "center"
    },
    dialogActions: {
        justifyContent: "center"
    },
});


function ClientEdit() {
    const {id} = useParams<{id: string}>();
    const [expectAnswer, setExpectAnswer] = useState<boolean>(true);
    const [client, setClient] = useState<any | null>(null);
    const [dialog, setDialog] = useState<any>({
        open: false,
        msg: null
    });

    const loadClientInfo = (clientID: string) => {
        setExpectAnswer(true);
        const config = currentConfig();
        fetch(config.apiUrl + 'client/get/' + clientID, {
            method: 'get',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
        })
            .then(response => response.json())
            .then(data => {
                setClient(data.response.client);
                setExpectAnswer(false);
            })
            .catch(err => {
                console.log(err)
            })
    }

    function handleOnClickSave() {
        setExpectAnswer(true);
        const config = currentConfig();
        fetch(config.apiUrl + "client/edit", {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify(client)
            }
        ).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error();
            }
        }).then(data => {
            setClient(data.response.client);
            setDialog({
                open: true,
                msg: "Пользователь успешно обновлен"
            });
            setExpectAnswer(false);
        }).catch(() => {
            loadClientInfo(id);
            setDialog({
                open: true,
                msg: "Ошибка сервера"
            });
            setExpectAnswer(false);
        })
    }

    function handleOnClickCancel() {
        loadClientInfo(id);
    }

    function handleCloseDialog() {
        setDialog({
            open: false,
            msg: null,
        });
    }

    useEffect(() => {
        loadClientInfo(id);
    }, [id])

    const classes = useStyles();

    return (
        <>
            <Layout noHeightConstraint>
                <Card variant='outlined' className={classes.container}>
                    <Typography className={classes.title} variant='h4'>
                        Форма редактирования клиента
                    </Typography>
                    <CardContent>
                        <ClientEditForm expectAnswer={expectAnswer} setClient={setClient} client={client}
                                        handleOnClickSave={handleOnClickSave}
                                        handleOnClickCancel={handleOnClickCancel}/>

                        <Dialog open={dialog.open}>
                            <DialogContent>
                                <DialogContentText>
                                    {dialog.msg}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <ConfirmButton onClick={handleCloseDialog}>Закрыть</ConfirmButton>
                            </DialogActions>
                        </Dialog>
                    </CardContent>
                </Card>
            </Layout>
        </>
    )
}

export default ClientEdit;