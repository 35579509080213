import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {MenuItem, TextField} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import ConfirmButton from "../../../components/button/ConfirmButton";
import CancelButton from "../../../components/button/CancelButton";
import CustomCheckbox from "../../../components/checkbox/Checkbox";
import Preloader from "../../../components/Preloader";

interface IProps {
    client: any;
    setClient: (client: any) => void;
    handleOnClickSave: (event: any) => void;
    handleOnClickCancel: (event: any) => void;
    expectAnswer: boolean
}

const useStyles = makeStyles({
    container: {
        display: "grid",
        gridTemplateColumns: "max-content auto",
        rowGap: "8px",
        columnGap: "40px",
        justifyContent: "center"

    },
    buttonContainer: {
        justifySelf: "end",
        marginTop: "10px"
    },
    input: {
        width: "400px",
        justifySelf: "start"
    }
});

function emptyIfNull(field: string | number | null | undefined): any {
    if (field === null || field === undefined) {
        return "";
    } else {
        return field;
    }
}


function ClientEditTable(props: IProps) {
    const {client, setClient, handleOnClickCancel, handleOnClickSave, expectAnswer} = props;

    const [validationErrors, setValidationErrors] = useState<any>({});


    const classes = useStyles();

    function changeClientState(field: string, newValue: any) {
        const changedClient = {...client};
        changedClient[field] = newValue;
        setClient(changedClient);
    }

    function handleOnChangeStringField(event: any, field: string, length: number) {
        const newValue = event.target.value
        changeClientState(field, newValue);

        const newValidationErrors = {...validationErrors};
        newValidationErrors[field] = newValue.length > length;
        setValidationErrors(newValidationErrors);
    }

    function handleOnChangeDate(event: any, field: string, nullable: boolean = true) {
        const newValue = event.target.value
        changeClientState(field, newValue);

        const newValidationErrors = {...validationErrors};
        if (nullable && (newValue === null || newValue.trim() === "")) {
            newValidationErrors[field] = false;
        } else {
            newValidationErrors[field] = !validateDate(newValue);
        }
        setValidationErrors(newValidationErrors);
    }

    function handleOnChangeEmail(event: any, field: string, length: number) {
        const newValue = event.target.value
        changeClientState(field, newValue);

        const newValidationErrors = {...validationErrors};
        if (newValue.length > length) {
            newValidationErrors[field] = true;
        } else {
            newValidationErrors[field] = !validateEmail(newValue);
        }
        setValidationErrors(newValidationErrors);
    }

    function handleOnChangeNumber(event: any, field: string, entLength: number, fractLength: number, nullable: boolean, allowNegative: boolean = false) {
        const newValue = event.target.value
        changeClientState(field, newValue);

        const newValidationErrors = {...validationErrors};

        if (nullable && (newValue === null || newValue.trim() === "")) {
            newValidationErrors[field] = false;
        } else {
            newValidationErrors[field] = !isValidNumber(newValue, entLength, fractLength, allowNegative);
        }
        setValidationErrors(newValidationErrors);
    }

    function handleOnChangeHash(event: any, field: string) {
        const newValue = event.target.checked;
        if (newValue === true) {
            changeClientState(field, "confirmed");
        } else {
            changeClientState(field, null);
        }
    }

    function handleOnChangeCheckbox(event: any, field:string) {
        const newValue = event.target.checked;
        changeClientState(field, newValue);
    }

    function handleOnChange(event: any, field: string) {
        const newValue = event.target.value
        changeClientState(field, newValue);
    }

    function isErrorData() {
        let isError = false;
        Object.keys(validationErrors).forEach((errorField) => {
            if (validationErrors[errorField] === true) {
                isError = true;
            }
        })
        return isError;
    }

    function getHelperText(field: string, length: number) {
        if (validationErrors[field] === true) {
            return `Длинна должна быть меньше ${length} символов`
        }
        return null;
    }

    function getHelperTextDate(field: string) {
        if (validationErrors[field] === true) {
            return "Неверный формат даты (гггг-мм-дд)";
        }
        return null;
    }

    function getHelperTextNumber(field: string) {
        if (validationErrors[field] === true) {
            return "Неверный формат числа";
        }
        return null;
    }

    function isValidNumber(str: string, entLength: number, fractLength: number, allowNegative: boolean = false) {
        const numericStr = Number(str);
        if (!allowNegative && numericStr < 0) {
            return false;
        }
        const isNan = isNaN(numericStr) || str.trim() === "";
        if (!isNan) {
            const ent = parseInt(str);
            const fract = str.indexOf(".") === -1 ? "" : str.substring(str.indexOf(".") + 1);
            return ent.toString().length <= entLength && fract.length <= fractLength;
        }
        return false;
    }

    function validateEmail(email: string) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function validateDate(date: string) {
        const re = /^20\d{2}-[0-1]\d-[0-3]\d$/;
        return re.test(String(date).toLowerCase());
    }

    return (
        <div>
            {client ?
                <div>
                    <div className={classes.container}>
                        <Typography variant="h6">ID: </Typography>
                        <TextField className={classes.input} disabled={true}
                                   defaultValue={emptyIfNull(client.client_)}/>

                        <Typography variant="h6">Имя пользователя: </Typography>
                        <TextField className={classes.input} helperText={getHelperText("username", 20)}
                                   error={validationErrors["username"]}
                                   onChange={(event) => handleOnChangeStringField(event, "username", 20)}
                                   value={emptyIfNull(client.username)}/>

                        <Typography variant="h6">Статус: </Typography>
                        <TextField className={classes.input} fullWidth={true}
                                   id="status" value={client.status} select
                                   onChange={(event => handleOnChange(event, "status"))}>
                            <MenuItem value="1">New</MenuItem>
                            <MenuItem value="2">Confirmed</MenuItem>
                            <MenuItem value="3">Blocked</MenuItem>
                        </TextField>

                        <Typography variant="h6">Компания: </Typography>
                        <TextField className={classes.input} helperText={getHelperText("company", 200)}
                                   error={validationErrors["company"]}
                                   onChange={(event) => handleOnChangeStringField(event, "company", 200)}
                                   value={emptyIfNull(client.company)}/>

                        <Typography variant="h6">Email: </Typography>
                        <TextField className={classes.input}
                                   helperText={validationErrors["email"] === true ? "Неверный Email формат" : null}
                                   error={validationErrors["email"]}
                                   onChange={(event) => handleOnChangeEmail(event, "email", 200)}
                                   value={emptyIfNull(client.email)}/>

                        <Typography variant="h6">Имя: </Typography>
                        <TextField className={classes.input} helperText={getHelperText("first_name", 100)}
                                   error={validationErrors["first_name"]}
                                   onChange={(event) => handleOnChangeStringField(event, "first_name", 100)}
                                   value={emptyIfNull(client.first_name)}/>

                        <Typography variant="h6">Фамилия: </Typography>
                        <TextField className={classes.input} helperText={getHelperText("last_name", 100)}
                                   error={validationErrors["last_name"]}
                                   onChange={(event) => handleOnChangeStringField(event, "last_name", 100)}
                                   value={emptyIfNull(client.last_name)}/>

                        <Typography variant="h6">Телефон: </Typography>
                        <TextField className={classes.input} helperText={getHelperText("mobile_phone", 20)}
                                   error={validationErrors["mobile_phone"]}
                                   onChange={(event) => handleOnChangeStringField(event, "mobile_phone", 20)}
                                   value={emptyIfNull(client.mobile_phone)}/>

                        <Typography variant="h6">Confirmation hash: </Typography>
                        <CustomCheckbox style={{marginBlock: "-4px"}}
                                        onChange={(event) => handleOnChangeHash(event, "confirmation_hash")}
                                        checked={client.confirmation_hash === "confirmed"}/>

                        <Typography variant="h6">План: </Typography>
                        <TextField className={classes.input} fullWidth={true}
                                   id="status" value={client.plan} select
                                   onChange={(event => handleOnChange(event, "plan"))}>
                            <MenuItem value="1">0%</MenuItem>
                            <MenuItem value="2">5%</MenuItem>
                            <MenuItem value="3">10%</MenuItem>
                            <MenuItem value="4">15%</MenuItem>
                            <MenuItem value="20">20%</MenuItem>
                            <MenuItem value="25">25%</MenuItem>
                            <MenuItem value="30">30%</MenuItem>
                            <MenuItem value="35">35%</MenuItem>
                            <MenuItem value="40">40%</MenuItem>
                            <MenuItem value="45">45%</MenuItem>
                            <MenuItem value="50">50%</MenuItem>
                            <MenuItem value="99">99%</MenuItem>
                        </TextField>

                        <Typography variant="h6">Телеграм: </Typography>
                        <TextField className={classes.input} helperText={getHelperTextNumber("telegram_")}
                                   error={validationErrors["telegram_"]}
                                   onChange={(event) => handleOnChangeNumber(event, "telegram_", 20, 0, true)}
                                   value={emptyIfNull(client.telegram_)}/>

                        <Typography variant="h6">MMS ключ: </Typography>
                        <TextField className={classes.input} helperText={getHelperText("mms_key", 20)}
                                   error={validationErrors["mms_key"]}
                                   onChange={(event) => handleOnChangeStringField(event, "mms_key", 20)}
                                   value={emptyIfNull(client.mms_key)}/>

                        <Typography variant="h6">Бонус: </Typography>
                        <TextField className={classes.input} helperText={getHelperTextNumber("bonus")}
                                   error={validationErrors["bonus"]}
                                   onChange={(event) => handleOnChangeNumber(event, "bonus", 8, 2, false, true)}
                                   value={emptyIfNull(client.bonus)}/>
                        <Typography variant="h6">Allow Ed: </Typography>
                        <CustomCheckbox style={{marginBlock: "-4px"}}
                                        onChange={(event) => handleOnChangeCheckbox(event, "allow_ed")}
                                        checked={client.allow_ed}/>
                        <Typography variant="h6">Ed Main Price: </Typography>
                        <TextField className={classes.input} helperText={getHelperTextNumber("ed_main_price")}
                                   error={validationErrors["ed_main_price"]}
                                   onChange={(event) => handleOnChangeNumber(event, "ed_main_price", 5, 2, false)}
                                   value={emptyIfNull(client.ed_main_price)}/>
                        <Typography variant="h6">Ed Second Price: </Typography>
                        <TextField className={classes.input} helperText={getHelperTextNumber("ed_second_price")}
                                   error={validationErrors["ed_second_price"]}
                                   onChange={(event) => handleOnChangeNumber(event, "ed_second_price", 5, 2, false)}
                                   value={emptyIfNull(client.ed_second_price)}/>

                        <Typography variant="h6">ED Subscribe Start: </Typography>
                        <TextField className={classes.input} helperText={getHelperTextDate("ed_subscribe_start")}
                                   error={validationErrors["ed_subscribe_start"]}
                                   onChange={(event) => handleOnChangeDate(event, "ed_subscribe_start")}
                                   value={emptyIfNull(client.ed_subscribe_start)}/>
                        <Typography variant="h6">ED Subscribe End: </Typography>
                        <TextField className={classes.input} helperText={getHelperTextDate("ed_subscribe_end")}
                                   error={validationErrors["ed_subscribe_end"]}
                                   onChange={(event) => handleOnChangeDate(event, "ed_subscribe_end")}
                                   value={emptyIfNull(client.ed_subscribe_end)}/>
                        <Typography variant="h6">ED Subscribe Limit: </Typography>
                        <TextField className={classes.input} helperText={getHelperTextNumber("ed_subscribe_limit")}
                                   error={validationErrors["ed_subscribe_limit"]}
                                   onChange={(event) => handleOnChangeNumber(event, "ed_subscribe_limit", 5, 0, true)}
                                   value={emptyIfNull(client.ed_subscribe_limit)}/>

                        <Typography variant="h6">Дата регистрации: </Typography>
                        <TextField className={classes.input} disabled={true}
                                   defaultValue={emptyIfNull(client.create_date)}/>
                        <div/>
                        <div className={classes.buttonContainer}>
                            <div style={{display: "inline"}}>
                                {expectAnswer ? <Preloader size={30}/> : null }
                            </div>

                            <ConfirmButton style={{marginLeft: "15px", marginBottom: "20px"}}
                                           disabled={isErrorData()}
                                           onClick={handleOnClickSave}>Сохранить</ConfirmButton>
                            <CancelButton style={{marginLeft: "10px", marginBottom: "20px"}}
                                          onClick={handleOnClickCancel}>Отменить</CancelButton>
                        </div>
                    </div>
                </div>

                : null}
        </div>
    )

}


export default ClientEditTable;