import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Card, CardContent, FormControl, MenuItem, Select} from "@material-ui/core";
import Layout from "../../components/layout/Common";
import {useParams} from "react-router-dom";
import BoschAlgForm from "./components/BoschAlgForm";
import InputLabel from '@material-ui/core/InputLabel';
import FileInfo from 'components/fileInfo/FileInfo';
import {ToastContainer} from "react-toastify";

const useStyles = makeStyles({
    container: {
        minHeight: '80vh',
    },
    title: {
        padding: '16px 0 0 16px'
    },
    compareContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column"
    },
    preloaderContainer: {
        marginTop: "20px"
    },
    fileBox: {
        fontFamily: 'monospace',
        overflowX: 'scroll',
        whiteSpace: 'nowrap'
    },
    fileBoxTable: {
        marginTop: 10
    },
    fileBoxAddress: {
        color: 'black',
        fontWeight: 'bold',
        paddingLeft: '2px'
    },
    fileBoxCO: {
        color: '#c6c3c3',
        backgroundColor: '#fbf1d0'
    },
    fileBoxCP: {
        color: '#c6c3c3',
        backgroundColor: '#fbf1d0'
    },
    fileBoxPS: {
        color: '#c6c3c3',
    },
    fileBoxPE: {
        color: '#c6c3c3',
    },
    leftContainer: {
    },
    rightContainer: {
    },
    centerContainer: {
    },
    dtcRemoveAlgorithm: {
        width: "300px",
        marginLeft: "20px",
        marginBlock: "7px"
    }
});


export default function RemoveDTC() {
    const classes = useStyles();
    let {key} = useParams<{key: string}>();
    
    const dtcRemoveAlgorithm = [
        'BOSCH',
        'SIEMENS'
    ]
    const [dtcRemoveAlgorithmValue, setDtcRemoveAlgorithmValue] = useState<string>('BOSCH');

    function handleOnChange(e:React.ChangeEvent<{ value: unknown }>) {
        console.log(e.target.value)
        setDtcRemoveAlgorithmValue(e.target.value as string)
    }
        
    return (
        <>
            <Layout noHeightConstraint>
                <Card variant='outlined' className={classes.container}>
                    <FileInfo fileKey={key} onTaskClose={() => {}}/>
                    <CardContent >
                        <Typography variant='h6'>
                            Внимание! DTC будут удалены на последнем, загруженном админом файле или на оригинале.
                        </Typography>
                        <FormControl className={classes.dtcRemoveAlgorithm}>
                            <InputLabel id="demo-simple-select-standard-label">Выберите алгоритм</InputLabel>
                            <Select 
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Выберите алгоритм"
                                onChange={handleOnChange} 
                                value={dtcRemoveAlgorithmValue}
                                >
                            {Array.isArray(dtcRemoveAlgorithm) &&
                                dtcRemoveAlgorithm.map((val) => (
                                    <MenuItem key={val} value={val}>
                                        {val}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <br/>
                        {dtcRemoveAlgorithmValue && dtcRemoveAlgorithmValue === 'BOSCH' && (
                            <BoschAlgForm fileKey={key}/>
                        )}
                    </CardContent>
                </Card>
            </Layout>
            <ToastContainer />
        </>
    )
}
