import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import ConfirmButton from "../../../components/button/ConfirmButton";
import CancelButton from "../../../components/button/CancelButton";
import Preloader from "../../../components/Preloader";
import MDEditor from '@uiw/react-md-editor';


interface IProps {
  content: string;
  setContent: (content: string) => void;
  handleOnClickSave: (event: any) => void;
  handleOnClickCancel: (event: any) => void;
  expectAnswer: boolean
}

const useStyles = makeStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "max-content auto",
    rowGap: "8px",
    columnGap: "40px",
    justifyContent: "center",
    minHeight: '80vh',
  },
  buttonContainer: {
    justifySelf: "end",
    marginTop: "10px"
  },
  input: {
    width: "1000px",
    justifySelf: "start"
  }
});

document.documentElement.setAttribute('data-color-mode', 'light');

function ContentEditForm(props: IProps) {
  const {content = '', setContent, handleOnClickCancel, handleOnClickSave, expectAnswer} = props;

  const classes = useStyles();

  const handleOnChange = (val: any) => {
    setContent(val);
  }

  return (
    <div>
      <div>
        <div className={classes.container}>

          <MDEditor
            preview={"edit"}
            height={600}
            className={classes.input}
            value={content}
            onChange={handleOnChange}
          />


          <div/>
          <div className={classes.buttonContainer}>
            <div style={{display: "inline"}}>
              {expectAnswer ? <Preloader size={30}/> : null}
            </div>

            <ConfirmButton style={{marginLeft: "15px", marginBottom: "20px"}}
                           onClick={handleOnClickSave}>Сохранить</ConfirmButton>
            <CancelButton style={{marginLeft: "10px", marginBottom: "20px"}}
                          onClick={handleOnClickCancel}>Отменить</CancelButton>
          </div>
        </div>
      </div>

    </div>
  )

}


export default ContentEditForm;