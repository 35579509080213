import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import {Button, Grid, TextField} from "@material-ui/core";
import FilteredTransferList from "../../../../components/filtered-transfer-list/FilteredTransferList";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import currentConfig from "../../../../utils/config.utils";
import {IPriceGroup, IStEcuSetting} from "../../types";
import {toast} from "react-toastify";

const useStyles = makeStyles({
    container: {
        minHeight: '80vh',
        margin: '30px',
    },
    title: {
        padding: '16px 0 0 16px'
    },
    root: {
        '& .MuiTextField-root': {
            // margin: theme.spacing(1),
            width: '25ch',
            marginBottom: '20px'
        },
    },
    coefInput: {
        width: '90%!important'
    }
});

const defaultPriceGroup: IPriceGroup = {
    name: '',
    coef_first: 1,
    coef_second: 1,
    coef_adblue: 1,
    coef_dtc: 1,
    st_ecus: [],
    vehicle_: 0,
}

interface PriceGroupEditorProps {
    stEcuSettings: IStEcuSetting[]
    vehicleId: number

    saved(): void
    cansel(): void

    editedRow?: IPriceGroup | null
}

function PriceGroupEditor({stEcuSettings, vehicleId, saved, cansel, editedRow}: PriceGroupEditorProps) {
    const classes = useStyles();

    const [priceGroup, setPriceGroup] = useState<IPriceGroup>({
        ...defaultPriceGroup,
        vehicle_: vehicleId,
    });
    const [left, setLeft] = useState<IStEcuSetting[]>([]);
    const [right, setRight] = useState<IStEcuSetting[]>([]);
    const [stEcus, setStEcus] = useState<number[]>([]);

    useEffect(() => {
        if (!editedRow) {
            setPriceGroup({
                ...defaultPriceGroup,
                vehicle_: vehicleId,
            })
            setStEcus([])
            setLeft([...stEcuSettings])
            setRight([])
            return
        }
        setPriceGroup({...editedRow})
        setStEcus([...editedRow.st_ecus])

        const listLeft = stEcuSettings.filter(el => !editedRow.st_ecus.includes(el.id))
        setLeft([...listLeft])

        const listRight = stEcuSettings.filter(el => editedRow.st_ecus.includes(el.id))
        setRight([...listRight])
    }, [editedRow, stEcuSettings, vehicleId]);


    const setStEcuSelected = (items: IStEcuSetting[]): void => {
        setStEcus(items.map(item => item.id))
    }

    const changeHandle = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: keyof IPriceGroup) => {
        setPriceGroup({
            ...priceGroup,
            [key]: event.target.value
        })
    }

    const [resetFilter, setResetFilter] = useState(0);
    const cancelHandler = () => {
        setPriceGroup({
            ...defaultPriceGroup,
            vehicle_: vehicleId,
        })
        setLeft([...stEcuSettings])
        setRight([])
        setStEcus([])
        setResetFilter(resetFilter + 1)
        cansel()
    }

    const add = () => {
        const config = currentConfig();
        fetch(config.apiUrl + 'add_price_group', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                ...priceGroup,
                st_ecus: stEcus,
            })
        })
            .then(response => response.json())
            .then(res => res.response)
            .then((response) => {
                if (response.result === 'error') {
                    const fixedEcus = stEcus.filter(ecu => !response.data.blockIds.includes(ecu))
                    const fixedLeft = stEcuSettings.filter(el => !fixedEcus.includes(el.id))
                    const fixedRight = stEcuSettings.filter(el => fixedEcus.includes(el.id))

                    setStEcus([...fixedEcus])
                    setLeft([...fixedLeft])
                    setRight([...fixedRight])

                    toast.error(response.data.message)
                    return
                }
                toast.success('Группа успешно добавлена')
                cancelHandler()
                saved()
            })
            .catch(err => {
                console.log('error:', err)
            })
    }

    const update = () => {
        const config = currentConfig();
        const {price_group_, ...data} = priceGroup
        fetch(config.apiUrl + `update_price_group/${price_group_}`, {
            method: 'put',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                ...data,
                st_ecus: stEcus,
            })
        })
            .then(response => response.json())
            .then(res => res.response)
            .then((response) => {
                if (response.result === 'error') {
                    const fixedEcus = stEcus.filter(ecu => !response.data.blockIds.includes(ecu))
                    const fixedLeft = stEcuSettings.filter(el => !fixedEcus.includes(el.id))
                    const fixedRight = stEcuSettings.filter(el => fixedEcus.includes(el.id))

                    setStEcus([...fixedEcus])
                    setLeft([...fixedLeft])
                    setRight([...fixedRight])

                    toast.error(response.data.message)
                    return
                }
                toast.success('Группа успешно сохранена')
                cancelHandler()
                saved()
            })
            .catch(err => {
                console.log('error:', err)
            })
    }


    const [error, setError] = useState('');
    const saveHandler = () => {
        setError('')
        if (!priceGroup.vehicle_) {
            setError('Vehicle is required')
            return
        }
        if (!priceGroup.name) {
            setError('Name is required')
            return
        }
        if (stEcus.length === 0) {
            setError('Ecus not selected')
            return;
        }

        if (priceGroup.price_group_) {
            update()
        } else {
            add()
        }
    }

    return (
        <Card variant='outlined' className={classes.container}>
            <Typography className={classes.title} variant='h4'>
                {priceGroup.price_group_ ? `Update ${priceGroup.name}` : 'New'}
            </Typography>
            <CardContent>
                <form className={classes.root} noValidate autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <TextField required label="Name" value={priceGroup.name} onChange={(e) => {
                                changeHandle(e, 'name')
                            }}/>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField required label="Первое" type="number" inputProps={{min: "0", step: "0.01"}} className={classes.coefInput}
                                       value={priceGroup.coef_first} onChange={(e) => {
                                changeHandle(e, 'coef_first')
                            }}/>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField required label="Второе+" type="number" inputProps={{min: "0", step: "0.01"}} className={classes.coefInput}
                                       value={priceGroup.coef_second} onChange={(e) => {
                                changeHandle(e, 'coef_second')
                            }}/>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField required label="Adblue" type="number" inputProps={{min: "0", step: "0.01"}} className={classes.coefInput}
                                       value={priceGroup.coef_adblue} onChange={(e) => {
                                changeHandle(e, 'coef_adblue')
                            }}/>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField required label="DTC" type="number" inputProps={{min: "0", step: "0.01"}} className={classes.coefInput}
                                       value={priceGroup.coef_dtc} onChange={(e) => {
                                changeHandle(e, 'coef_dtc')
                            }}/>
                        </Grid>
                    </Grid>
                </form>

                <FilteredTransferList
                    leftSide={left}
                    rightSide={right}
                    setRightSelected={setStEcuSelected}
                    reset={resetFilter}
                    />

                {!!error && <p style={{color: 'red'}}>{error}</p>}

                <Button variant="contained" color="primary" style={{marginRight: '10px'}} onClick={saveHandler}>
                    Save
                </Button>
                <Button variant="outlined" color="secondary" onClick={cancelHandler}>
                    Cancel
                </Button>
            </CardContent>
        </Card>
    );
}

export default PriceGroupEditor
