import React, {useEffect, useState} from 'react';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Layout from "../../components/layout/Common";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router-dom";
import {Dialog, DialogActions, DialogContent, DialogContentText} from "@material-ui/core";
import ConfirmButton from "../../components/button/ConfirmButton";
import currentConfig from "../../utils/config.utils";
import ContentEditForm from "./components/ContentEditForm";

const useStyles = makeStyles({
  container: {
    minHeight: '80vh',
  },
  title: {
    textAlign: "center"
  },
  dialogActions: {
    justifyContent: "center"
  },
});


function Contacts() {
  const {id} = useParams<{id: string}>();
  const [expectAnswer, setExpectAnswer] = useState<boolean>(true);
  const [content, setContent] = useState<string>('');
  const [dialog, setDialog] = useState<any>({
    open: false,
    msg: null
  });

  const loadContent = () => {
    setExpectAnswer(true);
    const config = currentConfig();
    fetch(config.apiUrl + 'page/contacts', {
      method: 'get',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        setContent(data.response.page.content);
        setExpectAnswer(false);
      })
      .catch(err => {
        console.log(err)
      })
  }

  function handleSetContent(content: string) {
    setContent(content);
  }

  function handleOnClickSave() {
    setExpectAnswer(true);
    const config = currentConfig();
    fetch(config.apiUrl + "page/contacts", {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({content})
      }
    ).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error();
      }
    }).then(() => {
      setDialog({
        open: true,
        msg: "Contacts успешно обновлены"
      });
      setExpectAnswer(false);
    }).catch(() => {
      loadContent();
      setDialog({
        open: true,
        msg: "Ошибка сервера"
      });
      setExpectAnswer(false);
    })
  }

  function handleOnClickCancel() {
    loadContent();
  }

  function handleCloseDialog() {
    setDialog({
      open: false,
      msg: null,
    });
  }

  useEffect(() => {
    loadContent();
  }, [id])

  const classes = useStyles();

  return (
    <>
      <Layout noHeightConstraint>
        <Card variant='outlined' className={classes.container}>
          <Typography className={classes.title} variant='h4'>
            Contacts
          </Typography>
          <CardContent>
            <ContentEditForm expectAnswer={expectAnswer}
                             content={content}
                             setContent={handleSetContent}
                             handleOnClickSave={handleOnClickSave}
                             handleOnClickCancel={handleOnClickCancel}/>

            <Dialog open={dialog.open}>
              <DialogContent>
                <DialogContentText>
                  {dialog.msg}
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <ConfirmButton onClick={handleCloseDialog}>Закрыть</ConfirmButton>
              </DialogActions>
            </Dialog>
          </CardContent>
        </Card>
      </Layout>
    </>
  )
}

export default Contacts;